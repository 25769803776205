import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
// baseUrl: "https://www.partner.casamed.org/api/"
export const api = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: "https://partners.casamed.org/api/"
    }),
    reducerPath: "adminApi",
    tagTypes: ["User","Products","Customers", "Transactions"],
    endpoints: (build) => ({
        createPrescription: build.mutation({
            query: (prescription) => ({
                url:"client/services/prescriptions",
                method: "POST",
                body: prescription
            }),
            providesTags:["Prescription"]
        }),
        updatePrescription: build.mutation({
            query: (prescription) => ({
                url:`client/services/prescriptions/${prescription._id}`,
                method: "PUT",
                body: prescription
            }),
            providesTags:["UserCasesUpdate"]
        }),
        createUserCase: build.mutation({
            query: (userCase) => ({
                url:"client/services/user-cases",
                method: "POST",
                body: userCase
            }),
            providesTags:["UserCases"]
        }),
        updateUserCase: build.mutation({
            query: (userCase) => ({
                url:`client/services/user-cases/${userCase._id}`,
                method: "PUT",
                body: userCase
            }),
            providesTags:["UserCasesUpdate"]
        }),
        createUserCaseInvite: build.mutation({
            query: ({account,userCase,name, link }) => ({
                url:"client/services/user-cases/invite",
                method: "POST",
                body: {account:account, userCase:userCase, name:name, link:link}
            }),
            providesTags:["UserCases"]
        }),
        verifyTierTransaction: build.mutation({
            query: (tier) => ({
                url:"client/services/tier-transactions/verify",
                method: "POST",
                body: tier
            }),
            providesTags:["TierVerification"]
        }),
        createTierSubscription: build.mutation({
            query: (tier) => ({
                url:"client/services/tier-subscriptions",
                method: "POST",
                body: tier
            }),
            providesTags:["TierSubscription"]
        }),

        createAppointment: build.mutation({
            query: (appoointment) => ({
                url:"client/services/book-appointment",
                method: "POST",
                body: appoointment
            }),
            providesTags:["Appointment"]
        }),
        createTeamMember: build.mutation({
            query: (teamMember) => ({
                url: "management/team-members",
                method: "POST",
                body: teamMember
            }),
            providesTags:["TeamMember"]
        }),
        createAccount: build.mutation({
            query: (accountData) => ({
                url: "client/services/accounts",
                method: "POST",
                body: accountData
            }),
            providesTags:["Accounts"]
        }),

        createProfile: build.mutation({
            query: (partnerData) => ({
                url: "client/services/partners",
                method: "POST",
                body: partnerData
            }),
            providesTags:["Partners"]
        }),
        createSubscription: build.mutation({
            query: (subscription) => ({
                url: "client/services/subscriptions",
                method: "POST",
                body: subscription
            }),
            providesTags:["Subscription"]
        }),
        createPlan: build.mutation({
            query: (planData) => ({
                url: "client/services/plans",
                method: "POST",
                body: planData
            }),
            providesTags:["CreatePlan"]
        }),
        createSendLink: build.mutation({
            query: (body) => ({
                url: "client/services/send-sms",
                method: "POST",
                body: body
            }),
            providesTags:["CreateSms"]
        }),
        updateAccount: build.mutation({
            query: (accountData) => ({
                url: `client/services/partners/${accountData.id}`,
                method: "PUT",
                body: accountData
            }),
            providesTags:["AccountsUpdate"]
        }),
        updateAccountName: build.mutation({
            query: (accountData) => ({
                url: `client/services/accounts/${accountData.id}`,
                method: "PUT",
                body: accountData
            }),
            providesTags:["AccountsUpdateName"]
        }),
        updateTeamMember: build.mutation({
            query: (accountData) => ({
                url: `management/team-members/${accountData.id}`,
                method: "PUT",
                body: accountData
            }),
            providesTags:["TeamMemberUpdateName"]
        }),
        updateFeedback: build.mutation({
            query: (feedbackData) => ({
                url: `client/services/physio-session/${feedbackData.id}`,
                method: "PUT",
                body: feedbackData
            }),
            providesTags:["Partners"]
        }),
        addPhyioSessionRecord: build.mutation({
            query: (feedbackData) => ({
                url: `client/services/physio-session`,
                method: "POST",
                body: feedbackData
            }),
            providesTags:["AddPhysioSession"]
        }),
        updateCustomPlan: build.mutation({
            query: (planData) => ({
                url: `client/services/plans/${planData.id}`,
                method: "PUT",
                body: planData
            }),
            providesTags:["PlanUpdate"]
        }),
        updateUserProfile: build.mutation({
            query: (userData) => ({
                url: `client/services/users/${userData.id}`,
                method: "PUT",
                body: userData
            }),
            providesTags:["UserProfileUpdate"]
        }),
        updatePhysioSession: build.mutation({
            query: (physioSession) => ({
                url: `client/services/physio-session/${physioSession.id}`,
                method: "PUT",
                body: physioSession
            }),
            providesTags:["PhysioSessionUpdate"]
        }),
        updateTierSubscription: build.mutation({
            query: (subscription) => ({
                url: `client/services/tier-subscriptions/${subscription.id}`,
                method: "PUT",
                body: subscription
            }),
            providesTags:["UpdateTierSubscription"]
        }),
        updateAppointment: build.mutation({
            query: (appointment) => ({
                url: `client/services/appointments/${appointment.id}`,
                method: "PUT",
                body: appointment
            }),
            providesTags:["AppointmentUpdate"]
        }),
        createUserProfile: build.mutation({
            query: (userData) => ({
                url: `client/services/users`,
                method: "POST",
                body: userData
            }),
            providesTags:["PlanUpdate"]
        }),
        sendJoinRequest: build.mutation({
            query: (message) => ({
                url: `client/services/request-patient-join`,
                method: "POST",
                body: message
            }),
            providesTags:["PlanUpdate"]
        }),
        addMedicalRecordMutation: build.mutation({
            query: (record) => ({
                url: `client/services/medical-records`,
                method: "POST",
                body: record
            }),
            providesTags:["MedicalRecord"]
        }),
        getUser: build.query({
            query: (id) => `client/services/partners?account=${id}`,
            providesTags: ["User"]
        }),
        getProducts: build.query({
            query: (days) => `client/services/products?days=${days}`,
            providesTags: ["Products"],
        }),
        getCustomers: build.query({
            query: (referralCode) => `client/services/accounts?referralCode=${referralCode._id}&role=Client`,
            providesTags: ["Customers"],
        }),
        getTransactions: build.query({
            query: ({ page, pageSize, sort, search, referralCode }) =>({
                url: `client/services/orders`,
                method: "GET",
                params: { page, pageSize, sort, search, referralCode: referralCode._id },
            }),
            providesTags: ["Transactions"]
        }),
        getAppointments: build.query({
            query: (referralCode) =>`client/services/appointments?referralCode=${referralCode._id}`,
            providesTags: ["Appointments"]
        }),
        getVideoCallToken: build.query({
            query: (appointmentId, isPublisher) =>
            ({
                url: "client/services/appointments-generate-token",
                method: "POST",
                body: { appointmentId, isPublisher },
            }),
            providesTags: ["VideoCallToken"]
        }),
        getUserFeedbacks: build.query({
            query: (user) => `client/services/feedbacks?user=${user}`,
            providesTags: ["Feedbacks"]
        }),
        getAccount: build.query({
            query: (user) => `client/services/accounts?mobile=${user}`,
            providesTags: ["Accounts"]
        }),
        getUserProfile: build.query({
            query: (accountId) => `client/services/users?accountId=${accountId}`,
            providesTags: ["UserProfile"]
        }),
        getUserSubscriptions: build.query({
            query: (query) => `client/services/subscriptions?${query}`,
            providesTags: ["Subscription"]
        }),
        getPlans: build.query({
            query: (custom) => `client/services/plans?custom=${custom}`,
            providesTags: ["Plans"]
        }),
        getExercises: build.query({
            query: () => `client/services/exercises`,
            providesTags: ["Exercises"]
        }),
        getPlanDetails: build.query({
            query: (id) => `client/services/plans/${id}`,
            providesTags: ["Plan Details"]
        }),
        getDashboardStatus: build.query({
            query:(referralCode) =>`client/services/dashboard-stats?referralCode=${referralCode._id}`,
            providesTags:["DashboardStats"]
        }),
        getPartnerUserSubscriptions: build.query({
            query:(referralCode) =>`client/services/referral-user-subscriptions?referralCode=${referralCode._id}&role=Client`,
            providesTags:["PartnerUserSubscriptions"]
        }),
        getMedicalRecords: build.query({
            query:(user) =>`client/services/medical-records?user=${user}`,
            providesTags:["MedicalRecords"]
        }),
        
        getMedicalRecord: build.query({
            query:(id) =>`client/services/medical-records/${id}`,
            providesTags:["MedicalRecord"]
        }),
        getAppShareLink: build.query({
            query:() =>`client/services/share-app-link`,
            providesTags:["ShareLink"]
        }),
        getUserPhysioSessions: build.query({
            query:(user) =>`client/services/physio-session?user=${user}`,
            providesTags:["Physio Session"]
        }),
        getTeamMembers:  build.query({
            query:(partner) =>`management/team-members?partner=${partner}`,
            providesTags:["TeamMembers"]
        }),
        getTeamMemberByPhone:  build.query({
            query:(mobile) =>`management/find-by-account?mobile=${mobile}`,
            providesTags:["TeamMemberByPhone"]
        }),
        getTiers:  build.query({
            query:(mobile) =>`client/services/tiers`,
            providesTags:["Tiers"]
        }),
        getActiveSubscription:  build.query({
            query:(user) =>`client/services/tier-subscriptions?user=${user}&status=active`,
            providesTags:["Tiers"]
        }),
        getActiveSubscriptionInvoice:  build.query({
            query:(id) =>`client/services/tier-subscriptions-invoice/${id}`,
            providesTags:["TierInvoice"]
        }),
        getUserTransferedCases: build.query({
            query:(id) =>`client/services/user-cases?primaryPartner=${id}`,
            providesTags:["User Cases"]
        }),
        getUserRetainedCases: build.query({
            query:(id) =>`client/services/user-cases?currentPartner=${id}`,
            providesTags:["User Cases Retained"]
        }),
        getPartners: build.query({
            query:() =>`/general/partners`,
            providesTags:["Get Partners"]
        }),
        getPrescriptions: build.query({
            query:(partner) =>`client/services/prescriptions?partner=${partner}`,
            providesTags:["Prescriptions"]
        }),
    }), 
});


export const { useCreatePrescriptionMutation, useUpdatePrescriptionMutation, useGetPrescriptionsQuery, useUpdateUserCaseMutation,useCreateUserCaseInviteMutation,useCreateUserCaseMutation,useGetPartnersQuery,useGetUserRetainedCasesQuery,useGetUserTransferedCasesQuery,useUpdateTeamMemberMutation, useUpdateAccountNameMutation,useUpdateAccountMutation,useGetUserQuery, useGetProductsQuery, useGetCustomersQuery, useGetTransactionsQuery, useGetAppointmentsQuery, useGetVideoCallTokenQuery, useGetUserFeedbacksQuery, useCreateAccountMutation, useGetAccountQuery, useGetUserProfileQuery, useCreateProfileMutation, useUpdateFeedbackMutation, useCreateSubscriptionMutation, useGetUserSubscriptionsQuery, useGetPlansQuery, useGetExercisesQuery, useCreatePlanMutation, useGetPlanDetailsQuery, useUpdateCustomPlanMutation, useGetDashboardStatusQuery, useGetPartnerUserSubscriptionsQuery, useCreateUserProfileMutation, useUpdateUserProfileMutation, useGetMedicalRecordsQuery, useGetMedicalRecordQuery, useAddMedicalRecordMutationMutation, useSendJoinRequestMutation, useGetAppShareLinkQuery, useCreateSendLinkMutation, useGetUserPhysioSessionsQuery, useAddPhyioSessionRecordMutation, useUpdatePhysioSessionMutation, useUpdateAppointmentMutation, useCreateTeamMemberMutation, useGetTeamMembersQuery, useGetTeamMemberByPhoneQuery, useCreateAppointmentMutation, useGetTiersQuery, useCreateTierSubscriptionMutation, useVerifyTierTransactionMutation, useGetActiveSubscriptionQuery, useGetActiveSubscriptionInvoiceQuery, useUpdateTierSubscriptionMutation } = api;