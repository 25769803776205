import React, { useState } from "react";
import {
  Box,
  Button,
  useTheme,
  Tooltip,
  Modal,
  Paper,
  Typography,
  IconButton,
  TextField,
} from "@mui/material";
import { useGetActiveSubscriptionQuery, useGetCustomersQuery } from "state/api";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";
import rows from "utils/customers";
import { useNavigate, useOutletContext } from "react-router-dom";
import PatientTable from "components/PatientTable";
import { CloudUploadRounded } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { CloseOutlined } from "@mui/icons-material";
import FlexBetween from "components/FlexBetween";

const Customers = () => {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const theme = useTheme();
  const navigate = useNavigate();
  const [userData] = useOutletContext();
  const [showAddonDialog, setShowAddonDialog] = useState(false);
  const { data, isLoading } = useGetCustomersQuery(
    userData.userData[0].userReferralCode
  );
  // console.log("dataCustomers", data)
  const activeSubs = useGetActiveSubscriptionQuery(userData.userData[0]._id);
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.name.endsWith(".csv")) {
      setUploadedFile(file);
    } else {
      // Show an error message or perform other validation
      console.log("Invalid file format. Only CSV files are accepted.");
    }
  };

  const rows = [];
  if (data) {
    data.map((d, index) => {
      const row = {
        id: d._id,
        system_id: index + 1,
        countryName: "India",
        mobile: d.mobile,
        name: d.name,
        role: d.role,
        email: d.email,
      };
      rows.push(row);
    });
  }
  const columns = [
    {
      field: "system_id",
      headerName: "Index",
      flex: 0.5,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "mobile",
      headerName: "Mobile No.",
      flex: 0.5,
    },

    {
      field: "countryName",
      headerName: "Country",
      flex: 0.4,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 0.5,
    },
  ];

  const handleAddPatient = () => {
    if (activeSubs.data) {
      const subs =
        activeSubs.data.data.length > 0 ? activeSubs.data.data[0] : null;

      if (subs && data && data.length >= subs.tier.patients) {
        setShowAddonDialog(true);
      } else {
        navigate(`/patient/add`);
      }
    }
  };

  return (
    <Box m="1.5rem 2.5rem" >
      <Header
        title="PATIENTS"
        subtitle="Find all your patients/referals here!"
      />

      {showAddonDialog && (
        <div className=" z-20 fixed inset-0 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="text-center">
              <h2 className="text-xl font-semibold mb-2 text-gray-700 ">
                Limit Exceeded
              </h2>
              <p className="text-gray-700 mb-1">
                You have exceeded the limit to add more patients with your
                current subscription.
              </p>
              {activeSubs.data.data[0].tier.name !== "Freemium" && (
                <p className="text-gray-700 mb-4">
                  To add a patient you will be now charged extra Rs.{" "}
                  {activeSubs.data.data[0].tier.extra / 100}.
                </p>
              )}

              <div className="flex justify-between">
                <button
                  onClick={() => {
                    setShowAddonDialog(false);
                  }}
                  className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded"
                >
                  Cancel
                </button>
                {activeSubs.data.data[0].tier.name !== "Freemium" && (
                  <button
                    onClick={() => {
                      navigate(`/patient/add`, {
                        state: {
                          extraPatient: true,
                          tierSubscription: activeSubs.data.data[0],
                        },
                      });
                    }}
                    className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded"
                  >
                    Add
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex gap-3 md:items-center flex-col md:flex-row">
      {!userData.team && userData.userData[0].role !== "Client" && (
        <Button
          sx={{ marginTop: "18px" }}
          variant="contained"
          size="medium"
          onClick={() => {
            handleAddPatient();
          }}
        >
          Add New Patient
        </Button>
      )}
      {userData.team && userData.team.role !== "viewer" && (
        <Button
         
          variant="contained"
          size="medium"
          onClick={() => {
            navigate(`/patient/add`);
          }}
        >
          Add New Patient
        </Button>
      )}
   
   {/* <FlexBetween>
   <Button
        sx={{ marginTop: "18px", flex:1 }}
        variant="contained"
        size="medium"
        onClick={() => setShowUploadModal(true)}
        startIcon={<CloudUploadRounded />}
        
      >
        Bulk Upload Patients
      </Button>
      <Tooltip title="Only CSV format files will be accepted">
        <span>
          <IconButton
            onClick={() => setShowUploadModal(true)}
            sx={{ marginLeft: "0px", fontSize: "15px" }}
          >
            <InfoOutlinedIcon />
          </IconButton>
        </span>
      </Tooltip>
   </FlexBetween> */}
      </div>
      <Modal
        open={showUploadModal}
        onClose={() => setShowUploadModal(false)}
        aria-labelledby="upload-modal-title"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper sx={{ padding: "2rem", width: "400px", maxWidth: "90vw" }}>
          <Typography variant="h6" id="upload-modal-title">
            Upload Patients Data
          </Typography>
          <IconButton
            sx={{ position: "absolute", top: "10px", right: "10px" }}
            onClick={() => setShowUploadModal(false)}
          >
            <CloseOutlined />
          </IconButton>
          <input type="file" accept=".csv" onChange={handleFileUpload} />
          <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: "1rem" }}
          >
            Upload
          </Button>
        </Paper>
      </Modal>

      <Box
        mt="20px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        {data && <PatientTable patients={data} />}
      </Box>
    </Box>
  );
};

export default Customers;
