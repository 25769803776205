import React, { useState, useRef, useEffect } from "react";
import moment, { duration } from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  ButtonGroup,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
} from "@mui/material";
import {
  Search,
  GetApp,
  Print,
  ViewColumn,
  FilterList,
  Clear,
  UpdateRounded,
  Schedule,
  TransferWithinAStation,
} from "@mui/icons-material";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  useCreateAppointmentMutation,
  useGetPartnersQuery,
  useGetVideoCallTokenQuery,
  useUpdateUserCaseMutation,
} from "state/api";
import { useTheme } from "@mui/material/styles";
import { tokensDark, tokensLight } from "theme";
import {
  Assignment,
  Dashboard,
  Edit,
  Share,
  Update,
} from "@mui/icons-material";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { ArrowUpward } from "@mui/icons-material";
import { ArrowDownward } from "@mui/icons-material";
const PatientRetainTable = ({ patients }) => {
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [createTransferOwnership] = useUpdateUserCaseMutation();
  const tableRef = useRef(null);

  const [filterCriteria, setFilterCriteria] = useState({
    name: "",
    email: "",
    mobile: "",
    country: "",
    role: "",
  });
  const [filteredPatients, setFilteredPatients] = useState(patients); // New state for filtered results
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState(""); // New state for search query
  const [sortOrder, setSortOrder] = useState("asc");
  const handleRowClick = (patient) => {
    if (selectedPatient === patient) {
      setSelectedPatient(null);
    } else {
      setSelectedPatient(patient);
    }
  };

  const handleFilterDialogOpen = () => {
    setOpenFilterDialog(true);
  };

  const handleFilterDialogClose = () => {
    setOpenFilterDialog(false);
  };

  const handleSortOrderChange = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
  };
  const sortTableData = () => {
    const sortedData = [...patients].sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
    });
    console.log(sortedData);
    setFilteredPatients(sortedData);
  };

  useEffect(() => {
    sortTableData();
  }, [sortOrder]);

  const handleFilterCriteriaChange = (event, field) => {
    const { value } = event.target;
    setFilterCriteria((prevFilterCriteria) => ({
      ...prevFilterCriteria,
      [field]: value,
    }));
  };

  useEffect(() => {
    const filteredData = patients.filter((patient) => {
      const { name, email, mobile, country, role } = patient;
      const {
        name: nameFilter,
        email: emailFilter,
        mobile: mobileFilter,
        country: countryFilter,
        role: roleFilter,
      } = filterCriteria;

      const isNameMatch =
        !nameFilter || name.toLowerCase().includes(nameFilter.toLowerCase());
      const isEmailMatch =
        !emailFilter || email.toLowerCase().includes(emailFilter.toLowerCase());
      const isMobileMatch = !mobileFilter || mobile.includes(mobileFilter);
      const isCountryMatch =
        !countryFilter ||
        country.toLowerCase().includes(countryFilter.toLowerCase());
      const isRoleMatch =
        !roleFilter || role.toLowerCase().includes(roleFilter.toLowerCase());

      return (
        isNameMatch &&
        isEmailMatch &&
        isMobileMatch &&
        isCountryMatch &&
        isRoleMatch
      );
    });

    setFilteredPatients(filteredData);
  }, [filterCriteria, patients]);

  const handleResetFilterCriteria = () => {
    setFilterCriteria({
      name: "",
      email: "",
      mobile: "",
      country: "",
      role: "",
    });
  };

  const handlePrint = () => {
    const content = tableRef.current.innerHTML;
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
        </head>
        <body>
          ${content}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  const handleDownload = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," + convertToCSV(filteredPatients);

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "patients.csv");
    document.body.appendChild(link); // Required for Firefox
    link.click();
  };

  const convertToCSV = (data) => {
    const header = ["Name", "Email", "Phone Number", "Country", "Role"];
    const rows = data.map((patient) => [
      patient.name,
      patient.email,
      `"${patient.mobile}"`, // Treat phone number as a string
      patient.country ? patient.country : "India",
      patient.role,
    ]);

    const csvData = [header, ...rows]
      .map((row) => row.map((value) => `"${value}"`).join(","))
      .join("\n");
    return csvData;
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (query.trim() === "") {
      setFilteredPatients(patients);
    } else {
      const searchedData = patients.filter((patient) =>
        Object.values(patient)
          .join(" ")
          .toLowerCase()
          .includes(query.toLowerCase())
      );

      setFilteredPatients(searchedData);
    }
  };

  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  const renderRow = (patient, idx) => {
    const { user, primaryPartner, subscription } = patient;
    const backgroundColor = theme.palette.primary.light;

    return (
      <TableRow
        className="cursor-pointer"
        sx={{
          backgroundColor: backgroundColor,
          color: isDarkMode
            ? tokensDark.secondary[800]
            : tokensDark.secondary[700],
          ":hover": {
            backgroundColor: isDarkMode
              ? tokensDark.primary[300]
              : tokensDark.grey[200],
          },
        }}
        key={patient._id}
        onClick={() => handleRowClick(patient)}
      >
        <TableCell>{user.name}</TableCell>
        <TableCell>{primaryPartner.name}</TableCell>
        <TableCell>{user.email}</TableCell>
        <TableCell>{user.mobile}</TableCell>
      </TableRow>
    );
  };

  const HandleDashboard = (patient) => {
    navigate(`/patient?patient=${patient._id}&mobile=${patient.mobile}`);
  };

  const HandleAssignPlan = (patient) => {
    navigate(
      `/patient/assign-plan?patient=${patient._id}&mobile=${patient.mobile}`
    );
  };
  const [userData] = useOutletContext();
  const [showSchedule, setShowSchedule] = useState(false);
  const [showSelectPartner, setShowSelectPartner] = useState(false);
  const renderExpandedRow = (patient, idx, userData) => {
    const backgroundColor = theme.palette.background.alt;

    return (
      <TableRow
        sx={{
          cursor: "pointer",
          backgroundColor: backgroundColor,
          color: isDarkMode
            ? tokensDark.secondary[800]
            : tokensDark.primary[700],
        }}
        key={`${patient.id}-expanded`}
      >
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, cursor: "pointer" }}
          colSpan={5}
        >
          <div className="expanded-row">
            <Button
              startIcon={<Dashboard />}
              variant="outlined"
              style={{
                margin: "8px",
                color: isDarkMode ? "white" : "black",
                borderColor: isDarkMode ? "white" : "black",
              }}
              onClick={() => {
                HandleDashboard(patient);
              }}
            >
              View Dashboard
            </Button>
            {!userData.team && userData.userData[0].role !== "Client" && (
              <Button
                startIcon={<Assignment />}
                variant="outlined"
                style={{
                  margin: "8px",
                  color: isDarkMode ? "white" : "black",
                  borderColor: isDarkMode ? "white" : "black",
                }}
                onClick={() => {
                  HandleAssignPlan(patient);
                }}
              >
                Assign Plan
              </Button>
            )}
            {userData.team && userData.team.role !== "viewer" && (
              <Button
                startIcon={<Assignment />}
                variant="outlined"
                style={{
                  margin: "8px",
                  color: isDarkMode ? "white" : "black",
                  borderColor: isDarkMode ? "white" : "black",
                }}
                onClick={() => {
                  HandleAssignPlan(patient);
                }}
              >
                Assign Plan
              </Button>
            )}

            {!userData.team &&
              userData.userData[0].role !== "Client" &&
              patient.currentFullAccess === true && (
                <Button
                  startIcon={<Edit />}
                  variant="outlined"
                  style={{
                    margin: "8px",
                    color: isDarkMode ? "white" : "black",
                    borderColor: isDarkMode ? "white" : "black",
                  }}
                  onClick={() => {
                    navigate(
                      `/patient/profile?patient=${patient._id}&mobile=${patient.mobile}&name=${patient.name}`
                    );
                  }}
                >
                  Edit Patient Profile
                </Button>
              )}
            {userData.team &&
              userData.team.role !== "viewer" &&
              patient.currentFullAccess === true && (
                <Button
                  startIcon={<Edit />}
                  variant="outlined"
                  style={{
                    margin: "8px",
                    color: isDarkMode ? "white" : "black",
                    borderColor: isDarkMode ? "white" : "black",
                  }}
                  onClick={() => {
                    navigate(
                      `/patient/profile?patient=${patient._id}&mobile=${patient.mobile}&name=${patient.name}`
                    );
                  }}
                >
                  Edit Patient Profile
                </Button>
              )}

            {!userData.team && userData.userData[0].role !== "Client" && (
              <Button
                startIcon={<Update />}
                variant="outlined"
                style={{
                  margin: "8px",
                  color: isDarkMode ? "white" : "black",
                  borderColor: isDarkMode ? "white" : "black",
                }}
                onClick={() => {
                  navigate(
                    `/patient/update-stats?patient=${patient._id}&mobile=${patient.mobile}&name=${patient.name}`
                  );
                }}
              >
                Add Session Records
              </Button>
            )}
            {userData.team && userData.team.role !== "viewer" && (
              <Button
                startIcon={<Update />}
                variant="outlined"
                style={{
                  margin: "8px",
                  color: isDarkMode ? "white" : "black",
                  borderColor: isDarkMode ? "white" : "black",
                }}
                onClick={() => {
                  navigate(
                    `/patient/update-stats?patient=${patient._id}&mobile=${patient.mobile}&name=${patient.name}`
                  );
                }}
              >
                Add Session Records
              </Button>
            )}
            {!userData.team && userData.userData[0].role !== "Client" && (
              <Button
                startIcon={<Schedule />}
                variant="outlined"
                style={{
                  margin: "8px",
                  color: isDarkMode ? "white" : "black",
                  borderColor: isDarkMode ? "white" : "black",
                }}
                onClick={() => {
                  setShowSchedule(true);
                }}
              >
                Schedule Appointment
              </Button>
            )}
            {userData.team && userData.team.role !== "viewer" && (
              <Button
                startIcon={<Schedule />}
                variant="outlined"
                style={{
                  margin: "8px",
                  color: isDarkMode ? "white" : "black",
                  borderColor: isDarkMode ? "white" : "black",
                }}
                onClick={() => {
                  setShowSchedule(true);
                }}
              >
                Schedule Appointment
              </Button>
            )}
            {!userData.team && userData.userData[0].role !== "Client" && (
              <Button
                startIcon={<TransferWithinAStation />}
                variant="outlined"
                style={{
                  margin: "8px",
                  color: isDarkMode ? "white" : "black",
                  borderColor: isDarkMode ? "white" : "black",
                }}
                onClick={() => {
                  setShowSelectPartner(true);
                }}
              >
                Transfer Case
              </Button>
            )}
            {userData.team && userData.team.role !== "viewer" && (
              <Button
                startIcon={<TransferWithinAStation />}
                variant="outlined"
                style={{
                  margin: "8px",
                  color: isDarkMode ? "white" : "black",
                  borderColor: isDarkMode ? "white" : "black",
                }}
                onClick={() => {
                  setShowSelectPartner(true);
                }}
              >
                Transfer Case
              </Button>
            )}
          </div>
        </TableCell>
      </TableRow>
    );
  };
  const SelectPartner = () => {
    const [selectedPartner, setSelectedPartnerDetail] = useState();
    const [editAccess, setEditAccess] = useState(true);
    const [fullAccess, setFullAccess] = useState(true);

    const [role, setRole] = useState("Doctor");
    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");

    const [searchTerm, setSearchTerm] = useState("");
    const { data } = useGetPartnersQuery();
    const handlePartnerSearch = (e) => {
      const ptr = data.filter((option) =>
        option.account
          ? option.account.name
              .trim()
              .toLowerCase()
              .includes(e.target.value.trim().toLowerCase())
          : option.name
              .trim()
              .toLowerCase()
              .includes(e.target.value.trim().toLowerCase())
      );

      setSelectedPartnerDetail(ptr[0]);
      setSearchTerm(e.target.value);
    };
    const handleCreateUserCase = () => {
      const pId = selectedPartner.account
        ? selectedPartner.account._id
        : selectedPartner.user._id;
      if (
        pId === selectedPatient.currentPartner._id ||
        pId === selectedPatient.secondaryPartner._id
      ) {
        alert("Cannot transfer case to the same Partner!");
      } else {
        const userCase = {
          _id: selectedPatient._id,
          partnerRecords: [
            ...selectedPatient.partnerRecords,
            selectedPartner.account
              ? selectedPartner.account._id
              : selectedPartner.user._id,
          ],
          primaryPartner: userData.userData[0]._id,
          currentPartner: selectedPartner.account
            ? selectedPartner.account._id
            : selectedPartner.user._id,
          secondaryFullAccess: fullAccess,
          secondaryEditAccess: editAccess,
          currentEditAccess: editAccess,
          currentFullAccess: fullAccess,
        };
        createTransferOwnership(userCase)
          .unwrap()
          .then((d) => {
            alert("Transferred Case Successfully!");
            setShowSelectPartner(false);
            window.location.reload();
          })
          .catch((e) => {
            alert("Something went wrong while transferring the case");
          });
      }
    };

    return (
      <div className="fixed  inset-0 z-50 flex items-center mt-16 md:mt-0 justify-center bg-black bg-opacity-50">
        <div className="bg-white overflow-y-auto overflow-auto  rounded-t-lg md:rounded-lg p-6">
          <h2 className="text-lg text-gray-700 font-semibold mb-4">
            Select the Partner to Transfer
          </h2>
          <input
            type="text"
            placeholder="Search"
            className="w-full border rounded-lg px-3 py-2 text-gray-700 mt-2 mb-2"
            onChange={handlePartnerSearch}
          />
          <select
            defaultValue="choose"
            onChange={(e) => {
              // console.log(JSON.parse(e.target.value))
              setSelectedPartnerDetail(JSON.parse(e.target.value));
            }}
            className="w-full border rounded-lg px-3 py-2 text-gray-700"
          >
            {data &&
              data
                .filter((option) =>
                  option.account
                    ? option.account.name
                        .trim()
                        .toLowerCase()
                        .includes(searchTerm.trim().toLowerCase())
                    : option.name
                        .trim()
                        .toLowerCase()
                        .includes(searchTerm.trim().toLowerCase())
                )
                .map((option) => (
                  <option key={option._id} value={JSON.stringify(option)}>
                    {option.account
                      ? option.account.name
                      : option.user
                      ? option.user.name
                      : option.name}
                  </option>
                ))}
          </select>
          {selectedPartner && (
            <div className="mt-2">
              {(selectedPartner.account || selectedPartner.user) && (
                <div>
                  {" "}
                  <h2 className="text-sm text-gray-500 ">Role</h2>
                  <h2 className="text-sm text-gray-700 font-semibold mb-4">
                    {selectedPartner.account
                      ? selectedPartner.account.role
                      : selectedPartner.user.role}
                  </h2>
                </div>
              )}
              <h2 className="text-sm text-gray-500 ">Degrees</h2>
              <h2 className="text-sm text-gray-700 font-semibold mb-4">
                {selectedPartner.degree && selectedPartner.degree.join(", ")}
              </h2>
              <h2 className="text-sm text-gray-500  ">Specialisations</h2>
              <h2 className="text-sm text-gray-700 font-semibold mb-4">
                {selectedPartner.specialization &&
                  selectedPartner.specialization.join(", ")}
              </h2>
              <h2 className="text-sm text-gray-500 ">Expertise</h2>
              <h2 className="text-sm text-gray-700 font-semibold mb-4">
                {selectedPartner.areaOfExpertise &&
                  selectedPartner.areaOfExpertise.join(", ")}
              </h2>
            </div>
          )}
          <h2 className="text-sm text-gray-500 mt-2">Has Edit Access?</h2>
          <select
            className="w-full text-gray-700 border rounded-lg px-3 py-2"
            onChange={(e) => setEditAccess(e.target.value)}
          >
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </select>
          <h2 className="text-sm text-gray-500 mt-2">Has Full Access?</h2>
          <select
            className="w-full text-gray-700 border rounded-lg px-3 py-2"
            onChange={(e) => setFullAccess(e.target.value)}
          >
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </select>

          <div className="flex gap-2 justify-end mt-4">
            <button
              onClick={() => {
                setShowSelectPartner(false);
              }}
              className="bg-red-500 hover:bg-red-600 text-white rounded-lg px-4 py-2"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                handleCreateUserCase();
              }}
              className="bg-blue-500 hover:bg-blue-600 text-white rounded-lg px-4 py-2"
            >
              Transfer
            </button>
          </div>
        </div>
      </div>
    );
  };
  const DateTimePickerModal = ({ patient }) => {
    const [selectedDateTime, setSelectedDateTime] = useState("");
    const [appointmentType, setAppointmentType] = useState("Physio Evaluation");
    const [department, setDepartment] = useState("orthopedic");
    const [bookAppointment] = useCreateAppointmentMutation();
    const handleDateTimeChange = (e) => {
      setSelectedDateTime(e.target.value);
    };

    const handleSave = (patient) => {
      if (selectedDateTime.trim().length === 0) {
        alert("Please enter the date and time for the appointment");
      } else {
        const appointment = {
          user: patient._id,
          partner: !userData.team
            ? userData.userData[0]._id
            : userData.team.partner,
          schedule: moment(selectedDateTime)
            .utc(true)
            .format("YYYY-MM-DD HH:mm"),
          appointmentType: appointmentType,
          department: department,
        };
        bookAppointment(appointment)
          .unwrap()
          .then((d) => {
            alert(d.message);
          })
          .catch((e) => {
            console.log(e);
            alert(e.data.message);
          });
      }
    };

    return (
      <div className="fixed inset-0 z-20 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white rounded-lg p-6">
          <h2 className="text-lg text-gray-700 font-semibold mb-4">
            Select a date and time
          </h2>
          <input
            type="datetime-local"
            value={selectedDateTime}
            onChange={handleDateTimeChange}
            className="border rounded-lg px-3 py-2 mb-4 text-gray-700"
          />
          <h2 className="text-md text-gray-700 font-semibold mt-4">
            Choose Appointment For
          </h2>
          <select
            onChange={(e) => {
              setAppointmentType(e.target.value);
            }}
            className="w-full text-gray-700"
          >
            <option value={"Physio Evaluation"}>Physio Evaluation</option>
            <option value={"Physio Session"}>Physio Session</option>
            <option value={"Doctor Consultation"}>Doctor Consultation</option>
            {/* <option>Diet</option>
            <option>Dietician</option> */}
            <option value={"Home Visit"}>Home Visit</option>
          </select>
          <h2 className="text-md text-gray-700 font-semibold mt-4">
            Choose Department
          </h2>
          <select
            onChange={(e) => {
              setDepartment(e.target.value);
            }}
            className="w-full text-gray-700"
          >
            <option value={"orthopedic"}>Orthopedic</option>
            <option value={"sports_injury"}>Sports</option>
            <option value={"gynaecology"}>Gynae</option>
            {/* <option>Diet</option>
            <option>Dietician</option> */}
            <option value={"pcod"}>PCOD</option>
          </select>

          <div className="flex gap-2 justify-end mt-4">
            <button
              onClick={() => {
                setShowSchedule(false);
              }}
              className="bg-red-500 hover:bg-red-600 text-white rounded-lg px-4 py-2"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                handleSave(patient);
              }}
              className="bg-blue-500 hover:bg-blue-600 text-white rounded-lg px-4 py-2"
            >
              Schedule
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="flex justify-between mb-4">
        {showSchedule && <DateTimePickerModal patient={selectedPatient} />}
        {showSelectPartner && <SelectPartner />}
        <ToastContainer
          containerId="toast"
          autoClose={2000}
          position="top-center"
          hideProgressBar={true}
        />

        <ButtonGroup>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={handleSearch}
          />

          <IconButton onClick={handleDownload}>
            <GetApp />
          </IconButton>
          <IconButton onClick={handlePrint}>
            <Print />
          </IconButton>
          <IconButton>
            <ViewColumn />
          </IconButton>
          <IconButton onClick={handleFilterDialogOpen}>
            <FilterList />
          </IconButton>
        </ButtonGroup>
      </div>
      <TableContainer
        ref={tableRef}
        style={{ marginTop: "16px" }}
        component={Paper}
      >
        <Table>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: theme.palette.background.alt,
                color: isDarkMode
                  ? tokensDark.secondary[800]
                  : tokensDark.secondary[700],
                fontWeight: "bold",
              }}
            >
              <TableCell>Patient</TableCell>
              <TableCell>Transfered By</TableCell>
              <TableCell>Email</TableCell>
              <TableCell onClick={handleSortOrderChange}>
                Mobile{" "}
                {sortOrder === "asc" ? <ArrowDownward /> : <ArrowUpward />}{" "}
              </TableCell>
              {/* <TableCell>Role</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredPatients.map((patient, idx) => (
              <>
                {renderRow(patient, idx)}
                {selectedPatient === patient &&
                  renderExpandedRow(patient, idx, userData)}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={openFilterDialog} onClose={handleFilterDialogClose}>
        <DialogTitle>Filter Table Data</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            value={filterCriteria.name}
            onChange={(e) => handleFilterCriteriaChange(e, "name")}
          />
          <TextField
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            variant="outlined"
            value={filterCriteria.email}
            onChange={(e) => handleFilterCriteriaChange(e, "email")}
          />
          <TextField
            margin="dense"
            label="Phone Number"
            type="tel"
            fullWidth
            variant="outlined"
            value={filterCriteria.mobile}
            onChange={(e) => handleFilterCriteriaChange(e, "mobile")}
          />
          <TextField
            margin="dense"
            label="Country"
            type="text"
            fullWidth
            variant="outlined"
            value={filterCriteria.country}
            onChange={(e) => handleFilterCriteriaChange(e, "country")}
          />
          <TextField
            margin="dense"
            label="Role"
            type="text"
            fullWidth
            variant="outlined"
            value={filterCriteria.role}
            onChange={(e) => handleFilterCriteriaChange(e, "role")}
          />

          <Button variant="contained" onClick={handleFilterDialogClose}>
            Apply Filter
          </Button>

          <Button
            variant="contained"
            style={{ marginLeft: "8px" }}
            onClick={handleResetFilterCriteria}
          >
            Reset Filters
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PatientRetainTable;
