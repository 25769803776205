import { Check, Clear, Edit, ArrowBack } from '@mui/icons-material';
import { Box } from '@mui/system';
import AssessPainScaleModal from 'components/AssessPainScaleModal';
import OverviewChart from 'components/OverviewChart';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { useAddPhyioSessionRecordMutation, useGetMedicalRecordsQuery, useGetUserFeedbacksQuery, useGetUserPhysioSessionsQuery, useGetUserProfileQuery, useGetUserSubscriptionsQuery, useUpdateFeedbackMutation } from 'state/api';
import {
    LineChart,
    ResponsiveContainer,
    Legend, Tooltip,
    Line,
    XAxis,
    YAxis,
    CartesianGrid
} from 'recharts';
import Chart from 'react-google-charts'
import { ResponsiveLine } from '@nivo/line';
import { useTheme } from '@mui/material/styles';
import { tokensDark, tokensLight } from 'theme';
import Navbar from 'components/Navbar';
import { Button, IconButton, useMediaQuery , Fab} from '@mui/material';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function UpdateStat() {
    const location = useLocation()
    const theme = useTheme()
    const bergBalance = [];
    for (let index = 0; index < 57; index++) {
        bergBalance.push(index)


    }

    const [userData] = useOutletContext()
    const params = new URLSearchParams(location.search);
    const mobile = params.get("mobile")
    const patient = params.get("patient")
    const { data, loading } = useGetUserProfileQuery(patient)
    const feedbacks = useGetUserPhysioSessionsQuery(patient)
    const subscriptions = useGetUserSubscriptionsQuery(`user=${patient}`)


    const [assessedPainScale, setAssessedPainScale] = useState()
    const [showAssessScale, setShowAccessScale] = useState(true)

    const [muscleTone, setMuscleTone] = useState()
    const [showMuscleTone, setShowMuscleTone] = useState(true)

    const [muscleStrength, setMuscleStrength] = useState()
    const [showMuscleStrength, setShowMuscleStrength] = useState(true)


    const [relativeIsoMov, setRelativeIsoMov] = useState('Strong and Painfree')
    const [showRIM, setShowRIM] = useState(true)

    const [muscleLengthTest, setMscleLengthTest] = useState()
    const [showMLT, setShowMLT] = useState(true)

    const [limbGirthMeasure, setLimbGirthMeasure] = useState('Girth Equal (on both the sides)')
    const [showLGM, setShowLGM] = useState(true)

    const [specificGeriatric, setSpecificGeriatric] = useState(0)
    const [showSG, setShowSG] = useState(true)

    const [neuro, setNeuro] = useState(0)
    const [showNeuro, setShowNeuro] = useState(true)

    const [chestExBefore, setChestExBefore] = useState(0)
    const [showChest, setShowChest] = useState(true)

    const [chestExAfter, setChestExAfter] = useState(0)
    const [showChestAfter, setShowChestAfter] = useState(true)

    const [fa, setFA] = useState('Functionally Independent')
    const [showFA, setShowFA] = useState(true)

    const [touch, setTouch] = useState('Intact')
    const [showTouch, setShowTouch] = useState(true)

    const [pain, setPain] = useState('Intact')
    const [showPain, setShowPain] = useState(true)

    const [temparature, setTemp] = useState('Intact')
    const [showTemp, setShowTemp] = useState(true)

    const [pressure, setPressure] = useState('Intact')
    const [showPressure, setShowPressure] = useState(true)

    const [plan, setPlan] = useState();
    const [userFeedbacks, setUserFeedbacks] = useState([])
    const [selectedFeedback, setSelectedFeedback] = useState()
    const [phyisoTest, setPhysioTest] = useState([])

    const [romDeg, setRomDeg] = useState(0)
    const [comment, setComment] = useState()
    const [addPhysioSession] = useAddPhyioSessionRecordMutation()

    const medicalRecords = useGetMedicalRecordsQuery(patient)

   

    //   const newData = [moment(d.createdAt).format("DD MMMM YYYY"), d.difficulty]
    //   const newPainPercData = [moment(d.createdAt).format("DD MMMM YYYY"), d.painPerception]
    //   LineData.push(newData)
    //   PainPerData.push(newPainPercData)


    // const RomChartOptions = {
    //     hAxis: {
    //         title: 'Date',
    //     },
    //     vAxis: {
    //         title: 'Range Of Movement(ROM)',
    //     },
    //     series: {
    //         1: { curveType: 'function' },
    //     },
    // }
    useEffect(() => {
        if (subscriptions.data) {
            // setSelectedFeedback(userFeedbacks[0])
            // setPhysioTest(userFeedbacks[0].physioTest)
         
            setPlan(subscriptions.data[0])
            if (feedbacks.data) {
               
               
                // console.log(feedbacks.data)
                let tempUserFeedbacks = [];
                let element = subscriptions.data[0];
                if (feedbacks.data.filter((i) => i.subscription === element._id).length > 0) {
                    tempUserFeedbacks = feedbacks.data.filter((i) => i.subscription == element._id);
                }
                // console.log(tempUserFeedbacks)
                setUserFeedbacks(tempUserFeedbacks)

            }
        } else {
            if (subscriptions.error) {
                setTimeout(()=>{
                   navigate('/patients', {replace:true})
                },2000)
              }
        }

    }, [subscriptions.data, feedbacks.data])
    

    const [rom, painPerception, difficulty, assessedPain, muscleT, muscleST, rimData, mltData, limbData, sgGraphData, neuroGraphData, chestGraph, functionalGraph, touchGraph, painGraph, tempGraph, pressureGraph] = useMemo(() => {


        //    let romData = []
        //    for (let index = 0; index < userFeedbacks.length; index++) {
        //     const element = userFeedbacks[index];
        //     const romArray =  element.rom.split('-')
        //     const newRomData = [{month: moment(element.createdAt).format("DD MMMM YYYY"), upper:Number.parseFloat(romArray[0]), lower:Number.parseFloat(romArray[0])}]
        //      romData.push(newRomData)
        //    } 
        const lowerRom = {
            id: "ROM",
            color: theme.palette.secondary.main,
            data: [],
        };
        const painPerception1 = {
            id: "Pain Perception",
            color: theme.palette.secondary.main,
            data: [],
        };
        const difficulty1 = {
            id: "Difficulty",
            color: theme.palette.secondary.main,
            data: [],
        };

        const upperRom = {
            id: "Upper",
            color: theme.palette.primary.main,
            data: [],
        }
        const assessedPain1 = {
            id: "Assessed Pain Scale",
            color: theme.palette.primary.main,
            data: [],
        }

        const muscleT1 = {
            id: "Muscle Tone",
            color: theme.palette.primary.main,
            data: [],
        }
        const muscleST1 = {
            id: "Muscle Strength Testing",
            color: theme.palette.primary.main,
            data: [],
        }
        const rimData1 = {
            id: "Relative Isometric Movement",
            color: theme.palette.primary.main,
            data: [],
        }
        const mltData1 = {
            id: "Muscle Length Testing",
            color: theme.palette.primary.main,
            data: [],
        }
        const limbData1 = {
            id: "Limb Girth Measurement",
            color: theme.palette.primary.main,
            data: [],
        }

        const sgGraphData1 = {
            id: "Specific Geriatric",
            color: theme.palette.primary.main,
            data: [],
        }
        const neuroGraphData1 = {
            id: "Neuro",
            color: theme.palette.primary.main,
            data: [],
        }
        const chestGraph1 = {
            id: "Chest Expansion Before",
            color: theme.palette.primary.main,
            data: [],
        }
        const chestGraph2 = {
            id: "Chest Expansion After",
            color: theme.palette.primary.main,
            data: [],
        }
        const functionalGraph1 = {
            id: "Functional Assessment",
            color: theme.palette.primary.main,
            data: [],
        }
        const touchGraph1 = {
            id: "Touch",
            color: theme.palette.primary.main,
            data: [],
        }
        const painGraph1 = {
            id: "Pain",
            color: theme.palette.primary.main,
            data: [],
        }
        const tempGraph1 = {
            id: "Temperature",
            color: theme.palette.primary.main,
            data: [],
        }
        const pressureGraph1 = {
            id: "Pressure",
            color: theme.palette.primary.main,
            data: [],
        }



        // Object.values(userFeedbacks).reduce(
        //     (acc, { createdAt, rom, painPerception, difficulty, physioTest }) => {

        //         const romArray = rom.split('-')
        //         lowerRom.data = [
        //             ...lowerRom.data,
        //             { x: moment(createdAt).format("DD MMMM YYYY"), y: Number.parseFloat(romArray[2]) },
        //         ];
        //         // if (physioTest.filter((i) => i.test === "Assessed Pain Scale")[0]) {
        //         //     assessedPain1.data = [
        //         //         ...assessedPain1.data,
        //         //         { x: moment(createdAt).format("DD MMMM YYYY"), y: physioTest.filter((i) => i.test === "Assessed Pain Scale")[0].score }
        //         //     ]
        //         // }

        //         // if (physioTest.filter((i) => i.test === "Muscle Tone")[0]) {
        //         //     muscleT1.data = [
        //         //         ...muscleT1.data,
        //         //         { x: moment(createdAt).format("DD MMMM YYYY"), y: physioTest.filter((i) => i.test === "Muscle Tone")[0].score }
        //         //     ]
        //         // }

        //         if (physioTest.filter((i) => i.test === "Muscle Strength Testing")[0]) {
        //             muscleST1.data = [
        //                 ...muscleST1.data,
        //                 { x: moment(createdAt).format("DD MMMM YYYY"), y: physioTest.filter((i) => i.test === "Muscle Strength Testing")[0].score }
        //             ]
        //         }

        //         if (physioTest.filter((i) => i.test === "Relative Isometric Movement")[0]) {
        //             rimData1.data = [
        //                 ...rimData1.data,
        //                 { x: moment(createdAt).format("DD MMMM YYYY"), y: physioTest.filter((i) => i.test === "Relative Isometric Movement")[0].score }
        //             ]
        //         }

        //         if (physioTest.filter((i) => i.test === "Muscle Length Testing")[0]) {
        //             mltData1.data = [
        //                 ...mltData1.data,
        //                 { x: moment(createdAt).format("DD MMMM YYYY"), y: physioTest.filter((i) => i.test === "Muscle Length Testing")[0].score === "Yes" ? "T.P" : "N.P" }
        //             ]
        //         }

        //         if (physioTest.filter((i) => i.test === "Limb Girth Measurement")[0]) {
        //             limbData1.data = [
        //                 ...limbData1.data,
        //                 { x: moment(createdAt).format("DD MMMM YYYY"), y: physioTest.filter((i) => i.test === "Limb Girth Measurement")[0].score }
        //             ]
        //         }
        //         if (physioTest.filter((i) => i.test === "Specific Geriatric")[0]) {
        //             sgGraphData1.data = [
        //                 ...sgGraphData1.data,
        //                 { x: moment(createdAt).format("DD MMMM YYYY"), y: physioTest.filter((i) => i.test === "Specific Geriatric")[0].score }
        //             ]
        //         }
        //         if (physioTest.filter((i) => i.test === "Neuro")[0]) {
        //             neuroGraphData1.data = [
        //                 ...neuroGraphData1.data,
        //                 { x: moment(createdAt).format("DD MMMM YYYY"), y: physioTest.filter((i) => i.test === "Neuro")[0].score }
        //             ]
        //         }
        //         if (physioTest.filter((i) => i.test === "Chest Expansion Before")[0]) {
        //             chestGraph1.data = [
        //                 ...chestGraph1.data,
        //                 { x: moment(createdAt).format("DD MMMM YYYY"), y: physioTest.filter((i) => i.test === "Chest Expansion Before")[0].score }
        //             ]
        //         }
        //         if (physioTest.filter((i) => i.test === "Chest Expansion After")[0]) {
        //             chestGraph2.data = [
        //                 ...chestGraph2.data,
        //                 { x: moment(createdAt).format("DD MMMM YYYY"), y: physioTest.filter((i) => i.test === "Chest Expansion After")[0].score }
        //             ]
        //         }

        //         if (physioTest.filter((i) => i.test === "Functional Assessment")[0]) {
        //             functionalGraph1.data = [
        //                 ...functionalGraph1.data,
        //                 { x: moment(createdAt).format("DD MMMM YYYY"), y: physioTest.filter((i) => i.test === "Functional Assessment")[0].score }
        //             ]
        //         }
        //         if (physioTest.filter((i) => i.test === "Touch")[0]) {
        //             touchGraph1.data = [
        //                 ...touchGraph1.data,
        //                 { x: moment(createdAt).format("DD MMMM YYYY"), y: physioTest.filter((i) => i.test === "Touch")[0].score }
        //             ]
        //         }
        //         if (physioTest.filter((i) => i.test === "Pain")[0]) {
        //             painGraph1.data = [
        //                 ...painGraph1.data,
        //                 { x: moment(createdAt).format("DD MMMM YYYY"), y: physioTest.filter((i) => i.test === "Pain")[0].score }
        //             ]
        //         }
        //         if (physioTest.filter((i) => i.test === "Pressure")[0]) {
        //             pressureGraph1.data = [
        //                 ...pressureGraph1.data,
        //                 { x: moment(createdAt).format("DD MMMM YYYY"), y: physioTest.filter((i) => i.test === "Pressure")[0].score }
        //             ]
        //         }
        //         if (physioTest.filter((i) => i.test === "Temperature")[0]) {
        //             tempGraph1.data = [
        //                 ...tempGraph1.data,
        //                 { x: moment(createdAt).format("DD MMMM YYYY"), y: physioTest.filter((i) => i.test === "Temperature")[0].score }
        //             ]
        //         }
        //         upperRom.data = [
        //             ...upperRom.data,
        //             { x: moment(createdAt).format("DD MMMM YYYY"), y: Number.parseFloat(romArray[0]) },
        //         ];
        //         painPerception1.data = [
        //             ...painPerception1.data,
        //             { x: moment(createdAt).format("DD MMMM YYYY"), y: Number.parseFloat(painPerception) },
        //         ];
        //         difficulty1.data = [
        //             ...difficulty1.data,
        //             { x: moment(createdAt).format("DD MMMM YYYY"), y: Number.parseFloat(difficulty) },
        //         ];


        //         return { createdAt: moment(createdAt).format("DD MMMM YYYY") };
        //     },
        //     { createdAt: moment(new Date()).format("DD MMMM YYYY") }
        // )
        const rom = [lowerRom]
        const chestGraphFinal = [chestGraph2]
        return [rom, [painPerception1], [difficulty1], [assessedPain1], [muscleT1], [muscleST1], [rimData1], [mltData1], [limbData1], [sgGraphData1], [neuroGraphData1], chestGraphFinal, [functionalGraph1], [touchGraph1], [painGraph1], [tempGraph1], [pressureGraph1]];
    }, [userFeedbacks]);

    const [selectedPainScaled, setSelectedPainScaled] = useState(assessedPain);
    const [selectedStrengthTest, setSelectedStrengthTest] = useState();
    const [selectedBergBalance, setSelectedBergBalance] = useState()

    const [selectedSensory, setSelectedSensory] = useState()
   
    const [updateFeedback, response] = useUpdateFeedbackMutation()
 

    const navigate = useNavigate()
    const handleAddFeedback = () =>{
      
        const physioSessionData = {subscription: plan._id, plan: plan.plan._id, user:patient, partner: userData.userData[0]._id, 
            muscleTone:muscleTone,
            assessedPainScale:assessedPainScale,
            muscleStrengthTesting:muscleStrength,
            relativeIsometricMovement: relativeIsoMov,
            muscleLengthTest: muscleLengthTest,
            limbGirthMeasurement: limbGirthMeasure,
            rom:romDeg,
           
            specificGeriatric:specificGeriatric,
            neuro:neuro,
            chestExpansion:chestExAfter,
            functionalAssessment:fa,
            touch:touch,
            pain:pain,
            temperature:temparature,
            pressure: pressure
        }
        addPhysioSession(physioSessionData).unwrap().then((d)=>{
            toast.success("Session data added successfully")
           setTimeout(()=>{
            navigate('/patients')
           },2000)
        }).catch((error)=>{
            if (error) {
                toast.error(error.message)
            }
        })
    }

    const handlePlanChange = (e) => {
        const planData = JSON.parse(e.target.value)
        setPlan(planData)
        setUserFeedbacks(feedbacks.data.filter((i) => i.subscription === planData._id))
        setSelectedFeedback(feedbacks.data.filter((i) => i.subscription === planData._id)[0])
    
        // console.log(JSON.parse(e.target.value))
        // {phyisoTest.filter((i)=>i.test == "Assessed Pain Scale")[0].score}
    }
    const handleFeedbackChange = (e) => {
        const feedbackData = JSON.parse(e.target.value)
        setSelectedFeedback(feedbackData)
        setPhysioTest(feedbackData.physioTest)
        // console.log(feedbackData)
    }
    



    const updatePain = (pain) =>{
        updateFeedback({ painPerception: pain, id: selectedFeedback._id, user:patient }).unwrap().then((data) => {
            setSelectedFeedback(data)
            const updatedArray = [...userFeedbacks];
            for (let index = 0; index < updatedArray.length; index++) {
                const element = updatedArray[index];
                if (updatedArray[index]._id === data._id) {
                    updatedArray[index] = data
                }
            }
            setUserFeedbacks(updatedArray)

        }).catch((error) => {
            alert("Something went wrong")
        })
    }
    const updateDiff = (diff) =>{
        updateFeedback({ difficulty: diff, id: selectedFeedback._id, user: patient }).unwrap().then((data) => {
            setSelectedFeedback(data)
            const updatedArray = [...userFeedbacks];
            for (let index = 0; index < updatedArray.length; index++) {
                const element = updatedArray[index];
                if (updatedArray[index]._id === data._id) {
                    updatedArray[index] = data
                }
            }
            setUserFeedbacks(updatedArray)

        }).catch((error) => {
            alert("Something went wrong")
        })
    }
    const isNonMobile = useMediaQuery("(min-width: 600px)");
    
    const isDarkMode = theme.palette.mode === 'dark';
    const Boxstyle = {
        backgroundColor: isDarkMode ? "#21295c": tokensDark.secondary[300],
        color: isDarkMode ? "#ffe3a3" : tokensDark.secondary[700],
      };
    const InfoStyle={backgroundColor: isDarkMode ? "#21295c": "#bab8b1",
    color: isDarkMode ? "#ffe3a3" : tokensDark.secondary[700],}

    const InfoFront={backgroundColor: isDarkMode ? "#3d53e5": "#e8e8e8",
    color: isDarkMode ? "#ffe3a3" : tokensDark.secondary[700],};

    const [isSidebarOpen, setIsSidebarOpen] = useState(false)
    const muscleToneArray = ["0","1","1+","2","3","4"]
    return (

        <div className="flex flex-col md:flex-row w-full">
         <div className='flex flex-col md:flex-row'>
                    <Fab
                        color="primary"
                        aria-label="back"
                        size="small"
                        sx={{
                            backgroundColor: isDarkMode ? theme.palette.primary.light : theme.palette.secondary.light,
                            marginLeft: "8px"
                        }}
                        onClick={() => { navigate(-1); }} // Navigate back to the previous screen
                    >
                        <ArrowBack />
                    </Fab>
                </div>
            <ToastContainer containerId="toast" autoClose={2000} position="top-center" hideProgressBar={true} />
            <div className="w-full md:min-w-3/4 p-4 overflow-y-auto">

                <h1 className='text-center text-2xl font-semibold py-2 '>Add Patient Session Stats</h1>
                {subscriptions.error && <h1 className='text-center text-3xl mt-8 font-semibold py-2 '>No Subscriptions Found</h1>}
                
                <div className="grid text-black text-center grid-cols-1 md:grid-cols-3 grid-flow-row gap-4 ">
                    <div className=" w-full p-4 rounded-lg align-center " style={Boxstyle}>
                        <select onChange={handlePlanChange} className='bg-transparent text-lg font-medium w-full'>
                            {
                                subscriptions.data && subscriptions.data.map((s) => {
                                    return s && <option value={JSON.stringify(s)} key={s._id}>
                                        {s.plan ? s.plan.name : ''}
                                    </option>
                                })
                            }
                        </select>
                        <h4 className="text-md opacity-80 mt-2">Plan Name</h4>
                    </div>
                    {
                        subscriptions.data && <div className=" p-4 rounded-lg" style={Boxstyle}>
                            <h2 className="text-lg font-medium mb-2">{plan && moment(plan.startsAt).utc().format("DD MMMM YYYY")}</h2>
                            <h4 className="text-md opacity-80">Plan Start Date</h4>
                        </div>
                    }
                    {
                        subscriptions.data && <div className="p-4 rounded-lg" style={Boxstyle}>
                            <h2 className="text-xl font-medium mb-2">{plan && plan.days} Days</h2>
                            <h4 className="text-lg opacity-80">Plan Duration</h4>
                        </div>
                    }


                </div>
                <div className="w-full flex align-middle mt-14 mb-4">
                    <h1 className='text-2xl'>Adding Record For Session: </h1>
                    <div className="rounded-lg ml-5  w-1/4">
                    <h2 className='text-xl'>{userFeedbacks.length+1} </h2>
                        {/* <select onChange={handleFeedbackChange} className='p-2 rounded-lg bg-gray-500 w-full'>
                            {
                                userFeedbacks && userFeedbacks.map((feed, i) => {
                                    return <option value={JSON.stringify(feed)} key={i}>
                                        {i + 1}
                                    </option>
                                })
                            }
                        </select> */}
                    </div>
                </div>
                <h2 className='mt-14 text-2xl'>Pain & Muscle Stats</h2>
                        <div className='flex flex-col md:flex-row w-full justify-between p-6 rounded-md mt-4' style={InfoStyle}>
                         
                            <div className="grid text-black text-center grid-cols-1 md:grid-cols-2  gap-2 mt-4 w-full">
                                <div onClick={() => { setSelectedPainScaled(assessedPain) }} className={` bg-opacity-${selectedPainScaled === assessedPain ? 100 : 40} p-4 rounded-lg cursor-pointer`} style={InfoFront}>
                                    {/* <h2 className="text-xl font-medium mb-2">{  + "/10"}</h2> */}
                                    <h4 className="text-lg opacity-80">Assessed pain scale</h4>
                                    {showAssessScale && <div>
                                        <label for="number" class="block mb-2 text-sm font-medium">Enter score between 0-10</label>
                                        <div className='grid grid-cols-6 gap-2 md:grid-cols-10 text-center w-full items-center justify-center align-middle'>
                                     {
                                             Array.from({ length: 11 }, (_, index) => (
                                                <button  onClick={() => { 
                                                   
                                                    setAssessedPainScale(`${index}`) }} type="text" contentEditable={false} value={index} min={0} key={index} class={`bg-gray-700 text-center bg-opacity-${ assessedPainScale == index  ? 100 : 40} border cursor-pointer border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   placeholder-gray-400`} required >{index}</button>
                                                        
                                            ))
                                        }
                                        </div>
                                        {/* <select onChange={(e) => { setAssessedPainScale(e.target.value) }} type="number" max={10} min={0} name="number" id="email" class="bg-gray-500 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-gray-500 border-gray-500 placeholder-gray-400 " placeholder="7" required>
                                            <option>0</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                            <option>6</option>
                                            <option>7</option>
                                            <option>8</option>
                                            <option>9</option>
                                            <option>10</option>
                                        </select>
                                        <div className='flex gap-2 align-middle justify-center'>
                                            <Check mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { addPainScale() }} />
                                            <Clear mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { setShowAccessScale(false) }} />
                                        </div> */}
                                    </div>
                                    }
                                    {/* {!showAssessScale && <Edit sx={{ cursor: 'pointer' }} onClick={() => { handleSetAssessedPain() }} />} */}

                                </div>
                                <div onClick={() => { setSelectedPainScaled(muscleT) }} className={` bg-opacity-${selectedPainScaled === muscleT ? 100 : 40} p-4 rounded-lg  cursor-pointer`} style={InfoFront}>
                                    {/* <h2 className="text-xl font-medium mb-2">{phyisoTest.length > 0 && phyisoTest.filter((i) => i.test === "Muscle Tone")[0] && phyisoTest.filter((i) => i.test == "Muscle Tone")[0].score}</h2> */}
                                    <h4 className="text-lg opacity-80">Muscle tone</h4>
                                    {/* {!showMuscleTone && <Edit sx={{ cursor: 'pointer' }} onClick={() => { handleSetMuscleTone() }} />} */}
                                    {showMuscleTone && <div>
                                        <label for="number" class="block mb-2 text-sm font-medium ">Enter score between 0-4</label>
                                        <div className='grid grid-cols-3 gap-2 md:grid-cols-6 w-full items-center justify-center align-middle '>
                                     {
                                             muscleToneArray.map((v, index) => (
                                                <button  onClick={() => { 
                                                   
                                                    setMuscleTone(v) }} type="text" contentEditable={false} value={index} min={0} key={index} class={`bg-gray-700 text-center bg-opacity-${ muscleTone == v  ? 100 : 40} border cursor-pointer border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   placeholder-gray-400`} required >{v}</button>
                                                        
                                            ))
                                        }
                                        </div>
                                        {/* <select onChange={(e) => { addMuscleTone(e.target.value) }} type="number" max={10} min={0} name="number" id="email" class="bg-gray-500 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-gray-500 border-gray-500 placeholder-gray-400 " placeholder="7" required>
                                            <option>0</option>
                                            <option>1</option>
                                            <option>1+</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>

                                        </select> */}
                                        {/* <Check mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { addMuscleTone() }} />
                                        <Clear mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { setShowMuscleTone(false) }} /> */}
                                    </div>
                                    }

                                </div>

                                <div onClick={() => { setSelectedPainScaled(muscleST) }} className={`bg-opacity-${selectedPainScaled === muscleST ? 100 : 40}   col-span-1 md:col-span-2 p-4  cursor-pointer rounded-lg`} style={InfoFront} >
                                    {/* <h2 className="text-xl font-medium mb-2">{phyisoTest.length > 0 && phyisoTest.filter((i) => i.test === "Muscle Strength Testing")[0] && phyisoTest.filter((i) => i.test == "Muscle Strength Testing")[0].score + "/5"}</h2> */}
                                    <h4 className="text-lg opacity-80">Muscle strength testing</h4>
                                    {showMuscleStrength && <div>
                                        <label for="number" class="block mb-2 text-sm font-medium">Enter score between 0-5</label>
                                        <div className='grid grid-cols-3 gap-2 md:grid-cols-6 w-full'>
                                     {
                                             Array.from({ length: 6 }, (_, index) => (
                                                <button  onClick={() => {setMuscleStrength(`${index}`) }} type="text" contentEditable={false} value={index} min={0} key={index} class={`bg-gray-700 text-center bg-opacity-${ muscleStrength == index ? 100 : 40} border cursor-pointer border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   placeholder-gray-400`} required >{index}</button>
                                                        
                                            ))
                                        }
                                        </div>
                                        {/* <select onChange={(e) => { setMuscleStrength(e.target.value) }} type="number" max={10} min={0} name="number" id="email" class="bg-gray-500 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="7" required>
                                            <option>0</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>

                                        </select>
                                        <Check mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { addMuscleStrength() }} />
                                        <Clear mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { setShowMuscleStrength(false) }} /> */}

                                    </div>
                                    }
                                    {/* {!showMuscleStrength && <Edit sx={{ cursor: 'pointer' }} onClick={() => { handleSetMuscleStrength() }} />} */}

                                </div>
                            </div>


                        </div>
                        {/*Strength Testing*/}
                        <h2 className='mt-14 text-2xl'>Strength Testing</h2>
                        <div className='flex justify-between mt-4 p-6 rounded-md flex-col md:flex-row w-full' style={InfoStyle}>
                           
                            <div className="grid w-full  text-black text-center grid-cols-2 gap-4 mt-4">
                                <div onClick={() => { setSelectedStrengthTest(rimData) }} className={`bg-opacity-${selectedStrengthTest === rimData ? 100 : 40} p-4 rounded-lg align-center`} style={InfoFront}>

                                    <h2 className="text-xl font-medium mb-2">{phyisoTest.length > 0 && phyisoTest.filter((i) => i.test === "Relative Isometric Movement")[0] && phyisoTest.filter((i) => i.test === "Relative Isometric Movement")[0].score}</h2>
                                    <h4 className="text-lg opacity-80">Relative Isometric Movement</h4>

                                    {showRIM && <div>
                                        <label for="number" class="block mb-2 text-sm font-medium ">Enter your inputs</label>
                                        <select onChange={(e) => { setRelativeIsoMov(e.target.value) }} className='p-2 w-full md:w-3/4 rounded-lg bg-gray-500'>
                                            <option>Strong and Painfree</option>
                                            <option>Weak and Painful</option>
                                            <option>Strong and Painful</option>
                                            <option>Weak and Painfree</option>
                                        </select>


                                        {/* <Check mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { addIsoMov() }} />
                                        <Clear mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { setShowRIM(false) }} /> */}

                                    </div>
                                    }
                                    {/* {!showRIM && <Edit sx={{ cursor: 'pointer' }} onClick={() => { handleRIM() }} />} */}

                                </div>
                                <div onClick={() => { setSelectedStrengthTest(mltData) }} className={`bg-opacity-${selectedStrengthTest === mltData ? 100 : 40} p-4 rounded-lg`} style={InfoFront}>
                                    <h2 className="text-xl font-medium mb-2">{phyisoTest.length > 0 && phyisoTest.filter((i) => i.test === "Muscle Length Testing")[0] && phyisoTest.filter((i) => i.test === "Muscle Length Testing")[0].score === "Yes" ? "Tightness Present" : phyisoTest.length > 0 && phyisoTest.filter((i) => i.test === "Muscle Length Testing")[0] && phyisoTest.filter((i) => i.test === "Muscle Length Testing")[0].score === "No" ? "Tightness Not Present" : ""}</h2>
                                    <h4 className="text-lg opacity-80">Muscle Length Testing</h4>
                                    {showMLT && <div>
                                        <label for="number" class="block mb-2 text-sm font-medium">Tightness present?</label>
                                        <select onChange={(e) => { setMscleLengthTest(e.target.value) }} className='p-2 rounded-lg bg-gray-500'>
                                            <option>Yes</option>
                                            <option>No</option>

                                        </select>


                                        {/* <Check mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { addMuscleLength() }} />
                                        <Clear mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { setShowMLT(false) }} /> */}

                                    </div>
                                    }
                                    {/* {!showMLT && <Edit sx={{ cursor: 'pointer' }} onClick={() => { handleMLT() }} />} */}

                                </div>
                                <div onClick={() => { setSelectedStrengthTest(limbData) }} className={`bg-opacity-${selectedStrengthTest === limbData ? 100 : 40} p-4 col-span-2 rounded-lg`} style={InfoFront}>
                                    <h2 className="text-xl font-medium mb-2">{phyisoTest.length > 0 && phyisoTest.filter((i) => i.test === "Limb Girth Measurement")[0] && phyisoTest.filter((i) => i.test === "Limb Girth Measurement")[0].score}</h2>
                                    <h4 className="text-lg opacity-80">Limb Girth Measurement</h4>
                                    {showLGM && <div>
                                        <label for="number" class="block mb-2 text-sm font-medium">Enter limb girth measurement</label>
                                        <select onChange={(e) => { setLimbGirthMeasure(e.target.value) }} className='p-2 rounded-lg bg-gray-500'>
                                            <option>Girth Equal (on both the sides)</option>
                                            <option>Girth Unequal (on both the sides)</option>

                                        </select>
                                        {/* <Check mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { addLimbMeasurement() }} />
                                        <Clear mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { setShowLGM(false) }} /> */}

                                    </div>
                                    }
                                    {/* {!showLGM && <Edit sx={{ cursor: 'pointer' }} onClick={() => { handleLGM() }} />} */}

                                </div>

                            </div>

                        </div>
                        <div className=' text-center mb-8 p-8 rounded-lg mt-14' style={InfoStyle}>
                            <h4 className='text-xl font-semibold'>Range Of Movement (ROM) </h4>

                            <div className=' flex flex-col-reverse align-center md:flex-row justify-center '>
                                <div className='w-full md:w-1/4 align-center text-center'>
                                    <h4 className="text-lg mt-4 font-medium mb-4">{selectedFeedback && selectedFeedback.rom.split("-")[2]} Degrees</h4>
                                    {/* <h4 className="text-md mt-4 font-medium mb-4">Upper</h4>
                                    <br />
                                    <h4 className="text-lg mt-4 font-medium mb-4">130</h4>
                                    <h4 className="text-md mt-4 font-medium mb-4">Lower</h4> */}
                                    <input onChange={(e)=>{setRomDeg(Number.parseInt(e.target.value))}} type="number" min={0}  className='w-full px-6 py-3 text-gray-600 text-md rounded-lg' placeholder='ROM in degrees'/>
                                    {/* <button onClick={handleCommentClick} class="bg-blue-500 mt-4 hover:bg-blue-700  font-bold py-2 px-4 rounded text-white">
                                        Add ROM
                                    </button> */}
                                </div>
                              
                            </div>
                        </div>
                        {/**Balance and coordination */}
                        <div className=' mb-8 p-4 bg-transparent mt-14'>
                            <h1 className='mt-4 text-2xl font-bold'>Balance And Coordination Scale</h1>

                            <div className='w-full'>
                                <div className="w-full text-[#ffe3a3]">
                                    <div className='p-4 mt-4 w-full'>
                                        <h4 className='text-xl font-medium text-[#ffe3a3]'>Berg Balance Scale</h4>
                                        <div className='flex  rounded-md p-4 mt-4 flex-col md:flex-row' style={InfoStyle}>
                                           
                                            <div className='text-center w-full '>
                                                <div onClick={() => { setSelectedBergBalance(sgGraphData) }} className={` bg-opacity-${selectedBergBalance === sgGraphData ? 100 : 40} p-4 mt-2 rounded-lg`} style={InfoFront}>
                                                    <h4 className='text-lg'>{phyisoTest.length > 0 && phyisoTest.filter((i) => i.test === "Specific Geriatric")[0] && phyisoTest.filter((i) => i.test == "Specific Geriatric")[0].score + "/56"}</h4>
                                                    <h4 className='text-md'>Specific Geriatric</h4>
                                                    {
                                                        showSG && <div>
                                                            <select onChange={(e) => { setSpecificGeriatric(e.target.value) }} className='p-2 rounded-lg bg-gray-500'>
                                                                {
                                                                    bergBalance.map((v, i) => {
                                                                        return (
                                                                            <option key={i}>{i}</option>
                                                                        )
                                                                    })
                                                                }

                                                            </select>
                                                            {/* <Check mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={(e) => { addSG() }} />
                                                            <Clear mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { setShowSG(false) }} /> */}

                                                        </div>
                                                    }
                                                    {!showSG && <Edit sx={{ cursor: 'pointer' }} onClick={() => { setShowSG(true) }} />}
                                                </div>
                                                <div onClick={() => { setSelectedBergBalance(neuroGraphData) }} className={`bg-opacity-${selectedBergBalance === neuroGraphData ? 100 : 40} p-4 mt-2 rounded-lg`} style={InfoFront}>
                                                    <h4 className='text-lg'>{phyisoTest.length > 0 && phyisoTest.filter((i) => i.test === "Neuro")[0] && phyisoTest.filter((i) => i.test == "Neuro")[0].score + "/56"}</h4>
                                                    <h4 className='text-md'>Neuro</h4>
                                                    {
                                                        showNeuro && <div>
                                                            <select onChange={(e) => { setNeuro(e.target.value) }} className='p-2 rounded-lg bg-gray-500'>
                                                                {
                                                                    bergBalance.map((v, i) => {
                                                                        return (
                                                                            <option key={i}>{i}</option>
                                                                        )
                                                                    })
                                                                }

                                                            </select>
                                                            {/* <Check mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={(e) => { addNeuro() }} />
                                                            <Clear mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { setShowNeuro(false) }} /> */}

                                                        </div>
                                                    }
                                                    {!showNeuro && <Edit sx={{ cursor: 'pointer' }} onClick={() => { setShowNeuro(true)() }} />}
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                    <div className='p-4 mt-4 '>
                                        <h4 className='text-xl font-medium text-[#ffe3a3]'>Chest Expansion Measurement</h4>
                                        <div className='flex flex-col md:flex-row text-center align-middle justify-between w-full rounded-md mt-4 p-4' style={InfoStyle}>
                                         
                                            <div className='flex w-full align-end justify-end p-2 mt-2 text-center '>
                                                <div className={` mr-2 p-4 mt-2 w-2/4  rounded-lg`} style={InfoFront}>
                                              { userFeedbacks.length > 0 &&    <h4 className='text-lg m-4'>{userFeedbacks.length > 1 ? userFeedbacks.at(userFeedbacks.length-2).chestExpansion : userFeedbacks.at(0).chestExpansion}cm<br />Before</h4>}
                                         
                                                    {/* {!showChest && <Edit sx={{ cursor: 'pointer' }} onClick={() => { setShowChest(true) }} />} */}
                                                </div>
                                                <div className={`ml-2 w-2/4  p-4 mt-2 rounded-lg`} style={InfoFront}>
                                                    <h4 className='text-lg text-[#ffe3a3] m-4'>{phyisoTest.length > 0 && phyisoTest.filter((i) => i.test === "Chest Expansion After")[0] && phyisoTest.filter((i) => i.test == "Chest Expansion After")[0].score}cm<br />After</h4>
                                                    {
                                                        showChestAfter &&
                                                        <div>
                                                            <label for="number" class="block mb-2 text-sm font-medium  ">Enter in centimeters</label>
                                                            <input onChange={(e) => { setChestExAfter(e.target.value) }} type="number" min={0} name="number" id="email" class="bg-gray-500 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-gray-500 border-gray-500 placeholder-gray-400 " placeholder="7 (cm)" required />
                                                            {/* <Check mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={(e) => { addChestAfter() }} /> */}
                                                            {/* <Clear mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { setShowChestAfter(false) }} /> */}

                                                        </div>
                                                    }
                                                    {!showChestAfter && <Edit sx={{ cursor: 'pointer' }} onClick={() => { setShowChestAfter(true) }} />}

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div className='p-2 mt-4'>
                                        <h4 className='text-xl font-medium text-[#ffe3a3]'>Functional Assessment<p className='text-lg'>{phyisoTest.length > 0 && phyisoTest.filter((i) => i.test === "Functional Assessment")[0] && phyisoTest.filter((i) => i.test == "Functional Assessment")[0].score}</p></h4>
                                        <div className=' p-2 mt-2'>
                                            {showFA && <div className='w-full md:w-1/4'>

                                                <select onChange={(e) => { setFA(e.target.value) }} type="number" max={10} min={0} name="number" id="email" class="bg-gray-500 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-gray-500 border-gray-500 placeholder-gray-400 " placeholder="7" required>
                                                    <option>Functionally Independent</option>
                                                    <option>Functionally Dependent</option>
                                                </select>
                                                <div className='flex gap-2 align-middle justify-center '>
                                                    {/* <Check mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { addFunctional() }} />
                                                    <Clear mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { setShowFA(false) }} /> */}
                                                </div>
                                            </div>
                                            }
                                            {!showFA && <Edit sx={{ cursor: 'pointer' }} onClick={() => { setShowFA(true) }} />}

                                        </div>
                                       
                                    </div>
                                </div>
                                <div className="w-full text-[#ffe3a3]">
                                    <div className='p-6'>
                                        <h4 className='text-xl font-medium text-[#ffe3a3]'>Sensory Examination:</h4>
                                        <div className='flex flex-col md:flex-row p-4 rounded-md  mt-4' style={InfoStyle}>
                                         
                                            <div className='grid grid-cols-1 md:grid-cols-2 gap-2 w-full'>
                                                <div onClick={() => { setSelectedSensory(touchGraph) }} className={` ml-2 bg-opacity-${selectedSensory === touchGraph ? 100 : 40} p-4 mt-2 rounded-lg`} style={InfoFront}>
                                                    <h4 className='text-lg'>{phyisoTest.length > 0 && phyisoTest.filter((i) => i.test === "Touch")[0] && phyisoTest.filter((i) => i.test == "Touch")[0].score}</h4>
                                                    <h4 className='text-md'>Touch</h4>
                                                    {showTouch && <div>

                                                        <select onChange={(e) => { setTouch(e.target.value) }} type="number" max={10} min={0} name="number" id="email" class="bg-gray-500 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-gray-500 border-gray-500 placeholder-gray-400 " placeholder="7" required>
                                                            <option>Intact</option>
                                                            <option>Affected</option>
                                                        </select>
                                                        <div className='flex gap-2 align-middle justify-center'>
                                                            {/* <Check mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { addTouch() }} />
                                                            <Clear mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { setShowTouch(false) }} /> */}
                                                        </div>
                                                    </div>
                                                    }
                                                    {!showTouch && <Edit sx={{ cursor: 'pointer' }} onClick={() => { setShowTouch(true) }} />}

                                                </div>
                                                <div onClick={() => { setSelectedSensory(painGraph) }} className={` ml-2 bg-opacity-${selectedSensory === painGraph ? 100 : 40} p-4 mt-2 rounded-lg`} style={InfoFront}>
                                                    <h4 className='text-lg'>{phyisoTest.length > 0 && phyisoTest.filter((i) => i.test === "Pain")[0] && phyisoTest.filter((i) => i.test == "Pain")[0].score}</h4>
                                                    <h4 className='text-md'>Pain</h4>
                                                    {showPain && <div>

                                                        <select onChange={(e) => { setPain(e.target.value) }} type="number" max={10} min={0} name="number" id="email" class="bg-gray-500 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-gray-500 border-gray-500 placeholder-gray-400 " placeholder="7" required>
                                                            <option>Intact</option>
                                                            <option>Affected</option>
                                                        </select>
                                                        <div className='flex gap-2 align-middle justify-center'>
                                                            {/* <Check mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { addPain() }} />
                                                            <Clear mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { setShowPain(false) }} /> */}
                                                        </div>
                                                    </div>
                                                    }
                                                    {!showPain && <Edit sx={{ cursor: 'pointer' }} onClick={() => { setShowPain(true) }} />}

                                                </div>

                                                <div onClick={() => { setSelectedSensory(tempGraph) }} className={` ml-2 bg-opacity-${selectedSensory === tempGraph ? 100 : 40} p-4 mt-2 rounded-lg`} style={InfoFront}>
                                                    <h4 className='text-lg'>{phyisoTest.length > 0 && phyisoTest.filter((i) => i.test === "Temperature")[0] && phyisoTest.filter((i) => i.test == "Temperature")[0].score}</h4>
                                                    <h4 className='text-md'>Temperature</h4>
                                                    {showTemp && <div>

                                                        <select onChange={(e) => { setTemp(e.target.value) }} type="number" max={10} min={0} name="number" id="email" class="bg-gray-500 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-gray-500 border-gray-500 placeholder-gray-400 " placeholder="7" required>
                                                            <option>Intact</option>
                                                            <option>Affected</option>
                                                        </select>
                                                        <div className='flex gap-2 align-middle justify-center'>
                                                            {/* <Check mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { addTemperature() }} />
                                                            <Clear mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { setShowTemp(false) }} /> */}
                                                        </div>
                                                    </div>
                                                    }
                                                    {!showTemp && <Edit sx={{ cursor: 'pointer' }} onClick={() => { setShowTemp(true) }} />}

                                                </div>

                                                <div onClick={() => { setSelectedSensory(pressureGraph) }} className={` ml-2 bg-opacity-${selectedSensory === pressureGraph ? 100 : 40} p-4 mt-2 rounded-lg`} style={InfoFront}>
                                                    <h4 className='text-lg'>{phyisoTest.length > 0 && phyisoTest.filter((i) => i.test === "Pressure")[0] && phyisoTest.filter((i) => i.test == "Pressure")[0].score}</h4>
                                                    <h4 className='text-md'>Pressure</h4>
                                                    {showPressure && <div>

                                                        <select onChange={(e) => { setPressure(e.target.value) }} type="number" max={10} min={0} name="number" id="email" class="bg-gray-500 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-gray-500 border-gray-500 placeholder-gray-400 " placeholder="7" required>
                                                            <option>Intact</option>
                                                            <option>Affected</option>
                                                        </select>
                                                        <div className='flex gap-2 align-middle justify-center'>
                                                            {/* <Check mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { addPressure() }} />
                                                            <Clear mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { setShowPressure(false) }} /> */}
                                                        </div>
                                                    </div>
                                                    }
                                                    {!showPressure && <Edit sx={{ cursor: 'pointer' }} onClick={() => { setShowPressure(true) }} />}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                {
                    userFeedbacks.length > 0 && <>
                       {/* <h2 className='mt-14 text-2xl'>User Feedback</h2>
                        <div className='flex justify-between mt-4 p-6 rounded-md flex-col md:flex-row w-full' style={InfoStyle}>
                           
                           {
                            selectedFeedback &&  <div className="grid w-full  text-black text-center grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                            <div  className={`p-4 rounded-lg align-center`} style={InfoFront}>

                                <h2 className="text-xl font-medium mb-2">{selectedFeedback.painPerception === 100 ? "Severe" : selectedFeedback.painPerception === 80 ? "Moderate" : selectedFeedback.painPerception === 60 ? "Mild" : selectedFeedback.painPerception === 40 ? "Slight Pain" : "No Pain"}</h2>
                                <h4 className="text-lg opacity-80">Pain Perception</h4>

                                <div className='grid grid-cols-2 md:grid-cols-3 gap-2 mt-4'>
                                    
                                <button onClick={()=>{updatePain(20)}} class={`${selectedFeedback.painPerception === 20 ? "bg-gray-700" : "bg-gray-400"}  text-center border cursor-pointer border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   placeholder-gray-400`} required >No Pain</button>
                                <button onClick={()=>{updatePain(40)}} class={`${selectedFeedback.painPerception === 40 ? "bg-gray-700" : "bg-gray-400"}  text-center  border cursor-pointer border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   placeholder-gray-400`} required >Slight Pain</button>
                                <button onClick={()=>{updatePain(60)}} class={`${selectedFeedback.painPerception === 60 ? "bg-gray-700" : "bg-gray-400"}  text-center  border cursor-pointer border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   placeholder-gray-400`} required >Mild</button>
                                <button onClick={()=>{updatePain(80)}} class={`${selectedFeedback.painPerception === 80 ? "bg-gray-700" : "bg-gray-400"}  text-center  border cursor-pointer border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   placeholder-gray-400`} required >Moderate</button>
                                <button onClick={()=>{updatePain(100)}} class={`${selectedFeedback.painPerception === 100 ? "bg-gray-700" : "bg-gray-400"}  text-center col-span-2   border cursor-pointer border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   placeholder-gray-400`} required >Severe</button>
                                                   
                                    
                                    <Check mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { addMuscleLength() }} />
                                    <Clear mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { setShowMLT(false) }} />

                                </div>
                                
                                
                             
                            </div>
                            <div className={`p-4 rounded-lg`} style={InfoFront}>
                                <h2 className="text-xl font-medium mb-2">{selectedFeedback.difficulty === 100 ? "Hard" : selectedFeedback.difficulty === 60 ? "Moderate" : "Easy"}</h2>
                                <h4 className="text-lg opacity-80">Difficulty</h4>
                                <div className='grid grid-cols-2 md:grid-cols-3 gap-2 mt-4'>
                                <button onClick={()=>{updateDiff(30)}} class={`${selectedFeedback.difficulty === 30 ? "bg-gray-700" : "bg-gray-400"}  text-center border cursor-pointer border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   placeholder-gray-400`} required >Easy</button>
                                <button onClick={()=>{updateDiff(60)}}  class={`${selectedFeedback.difficulty === 60 ? "bg-gray-700" : "bg-gray-400"}  text-center  border cursor-pointer border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   placeholder-gray-400`} required >Moderate</button>
                                <button onClick={()=>{updateDiff(100)}} class={`${selectedFeedback.difficulty === 100 ? "bg-gray-700" : "bg-gray-400"}  text-center  col-span-2 md:col-span-1 border cursor-pointer border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   placeholder-gray-400`} required >Hard</button>
                                                   
                                    
                                    <Check mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { addMuscleLength() }} />
                                    <Clear mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { setShowMLT(false) }} />

                                </div>
                                
                                
                            </div>
                            

                        </div>
                           }

                        </div> */}
                        
                    </>
                }
                {/* {
                    userFeedbacks.length === 0 && <div >
                        <h4 className='text-md mt-12'>No Session Attended</h4>
                    </div>
                } */}
            <div className='flex align-middle items-center justify-center w-full'>
            <Button onClick={()=>{handleAddFeedback()}} variant='contained'>Add Record</Button>
            </div>
            </div>
            <div className="w-full md:w-1/4 sticky r-0" style={InfoFront}>

                {
                    data && <div className="p-4 flex justify-center flex-col mx-4">
                        <div className=''><img className='w-full rounded-md' src={data.photo ? data.photo : ''}></img></div>
                        <div className='block mt-4'>
                            <h1 className="text-xl font-bold mb-4">{data.name}</h1>
                            <a href={`tel:${mobile}`}><h4 className="text-sm font-medium mb-4 cursor-pointer underline">{mobile}</h4></a>
                        </div>
                        <p className="mb-2 text-[18px] font-medium pt-6 opacity-50">Details</p>
                        <hr />
                        <div className="grid grid-cols-2 gap-4 center mt-4">
                            <h5 className="mb-2 text-md">Age</h5>
                            <h5 className="mb-2 text-md font-bold text-right">{data.age}</h5>
                            <h5 className="mb-2 text-md">Height({data.heightMeasure})</h5>
                            <h5 className="mb-2 text-md font-bold text-right">{data.height}</h5>
                            <h5 className="mb-2 text-md">Weight({data.weightMeasure})</h5>
                            <h5 className="mb-2 text-md font-bold text-right">{data.weight}</h5>
                            <h5 className="mb-2 text-md">BMI</h5>
                            <h5 className="mb-2 text-md font-bold text-right">{data.weightMeasure === "kg" ? data.heightMeasure === "cm" ? (data.weight / ((data.height / 100) * (data.height / 100))).toFixed(2) : (data.weight / (data.height * 0.3048)).toFixed(2) : data.heightMeasure === "cm" ? ((data.weight * 0.45359237) / ((data.height / 100) * (data.height / 100))).toFixed(2) : ((data.weight * 0.45359237) / (data.height * 0.3048)).toFixed(2)}</h5>
                            <h5 className="mb-2 text-md">Lifestyle</h5>
                            <h5 className="mb-2 text-md font-bold text-right">{data.natureOfWork.replace(/_/g, " ").replace(/\b\w/g, function (txt) {
                                return txt.toUpperCase()
                            })}</h5>
                            <h5 className="mb-2 text-md capitalize">Gender</h5>
                            <h5 className="mb-2 text-md capitalize font-bold text-right">{data.gender}</h5>
                        </div>

                        <h4 className="text-lg mt-8 font-medium opacity-40">Medical History</h4>
                        <hr/>
                        <div className="grid grid-cols-2 gap-4 center mt-8">
                            <h5 className="mb-2 text-md col-span">Comorbidities</h5>
                            <div className=' col-span-1'>    <h5 className="mb-2 text-md font-bold text-right capitalize">{data.comorbidities && data.comorbidities.join(",")}</h5></div>
                            <h5 className="mb-2 text-md">SPO2</h5>
                            <h5 className="mb-2 text-md font-bold text-right">{data.spo2}</h5>
                            <h5 className="mb-2 text-md">B.P</h5>
                            <h5 className="mb-2 text-md font-bold text-right">{data.bp}</h5>
                            <h5 className="mb-2 text-md">Heart Rate</h5>
                            <h5 className="mb-2 text-md font-bold text-right">{data.heartRate}</h5>
                            <h5 className="mb-2 text-md">Pulse</h5>
                            <h5 className="mb-2 text-md font-bold text-right">{data.pulse}</h5>
                            
                            </div>
                            <h4 className="text-lg mt-4 font-medium opacity-40">Medical Records</h4>
                        <hr/>
                        {
                                medicalRecords.data && medicalRecords.data.length > 0 && medicalRecords.data.map((m)=>{
                                   return  <div className='p-2 mt-2' onClick={()=>{navigate(`/patient/medical-record?record=${m._id}`)}}>
                                   <img src={m.document}></img>
                                   <h6 className='mt-2'>{m.name}</h6>
                               </div>
                                })
                            }
                        <h4 className="text-lg mt-8 font-medium opacity-40">Referred Partner Details</h4>
                        <hr />
                        {
                            userData && <ul className='mt-4'>
                                <li><h4 className="text-md font-medium mb-2"><strong>{userData.userData[0].name}</strong></h4></li>
                                <li><h4 className="text-md font-medium mb-4">{userData.userData[0].mobile}</h4></li>
                            </ul>
                        }
                        <button onClick={() =>  { navigate(`/patient/profile?patient=${patient}&mobile=${mobile}&name=${data.name}`)}} className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition-colors duration-300 ease-in-out">Edit Profile</button>
                    </div>
                }
            </div>
        </div>

    )
}