import { Check, Clear, Download, Edit, Info } from '@mui/icons-material';
import { ArrowBack } from '@mui/icons-material';
import { Box } from '@mui/system';
import AssessPainScaleModal from 'components/AssessPainScaleModal';
import OverviewChart from 'components/OverviewChart';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { useGetMedicalRecordsQuery, useGetUserFeedbacksQuery, useGetUserPhysioSessionsQuery, useGetUserProfileQuery, useGetUserSubscriptionsQuery, useUpdateFeedbackMutation } from 'state/api';
import {
    LineChart,
    ResponsiveContainer,
    Legend, Tooltip,
    Line,
    XAxis,
    YAxis,
    CartesianGrid
} from 'recharts';
import Chart from 'react-google-charts'
import { ResponsiveLine } from '@nivo/line';
import Navbar from 'components/Navbar';
import { Button, useMediaQuery, Fab } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { tokensDark, tokensLight } from 'theme';
import { ToastContainer, toast } from 'react-toastify';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';


export default function Patient() {
    const location = useLocation()
    const theme = useTheme()
    const bergBalance = [];
    for (let index = 0; index < 57; index++) {
        bergBalance.push(index)


    }

    const [userData] = useOutletContext()
    const params = new URLSearchParams(location.search);
    const mobile = params.get("mobile")
    const patient = params.get("patient")
    const { data, loading } = useGetUserProfileQuery(patient)
    const feedbacks = useGetUserPhysioSessionsQuery(patient)
    const physioSession = useGetUserPhysioSessionsQuery(patient)
    const subscriptions = useGetUserSubscriptionsQuery(`user=${patient}`)
    const sessionFeedbacks = useGetUserFeedbacksQuery(patient);
    const isSmallScreen = useMediaQuery("(max-width: 600px)");

    const margin = isSmallScreen
        ? { top: 20, bottom: 50, left: 40 }
        : { top: 20, right: 50, bottom: 50, left: 70 };

    const [assessedPainScale, setAssessedPainScale] = useState()
    const [showAssessScale, setShowAccessScale] = useState(false)

    const [muscleTone, setMuscleTone] = useState()
    const [showMuscleTone, setShowMuscleTone] = useState(false)

    const [muscleStrength, setMuscleStrength] = useState()
    const [showMuscleStrength, setShowMuscleStrength] = useState(false)


    const [relativeIsoMov, setRelativeIsoMov] = useState('Strong and Painfree')
    const [showRIM, setShowRIM] = useState(false)

    const [muscleLengthTest, setMscleLengthTest] = useState()
    const [showMLT, setShowMLT] = useState(false)

    const [limbGirthMeasure, setLimbGirthMeasure] = useState('Girth Equal (on both the sides)')
    const [showLGM, setShowLGM] = useState(false)

    const [specificGeriatric, setSpecificGeriatric] = useState(0)
    const [showSG, setShowSG] = useState(false)

    const [neuro, setNeuro] = useState(0)
    const [showNeuro, setShowNeuro] = useState(false)

    const [chestExBefore, setChestExBefore] = useState(0)
    const [showChest, setShowChest] = useState(false)

    const [chestExAfter, setChestExAfter] = useState(0)
    const [showChestAfter, setShowChestAfter] = useState(false)

    const [fa, setFA] = useState('Functionally Independent')
    const [showFA, setShowFA] = useState(false)

    const [touch, setTouch] = useState('Intact')
    const [showTouch, setShowTouch] = useState(false)

    const [pain, setPain] = useState('Intact')
    const [showPain, setShowPain] = useState(false)

    const [temparature, setTemp] = useState('Intact')
    const [showTemp, setShowTemp] = useState(false)

    const [pressure, setPressure] = useState('Intact')
    const [showPressure, setShowPressure] = useState(false)

    const [plan, setPlan] = useState();
    const [userFeedbacks, setUserFeedbacks] = useState([])
    const [selectedFeedback, setSelectedFeedback] = useState()
    const [phyisoTest, setPhysioTest] = useState([])
    const [updatePhysioSession] = useUpdateFeedbackMutation()
    const [comment, setComment] = useState()
    const [romDeg, setRomDeg] = useState();
    const LineData = [
        ['x', 'Difficulty']
    ]

    const medicalRecords = useGetMedicalRecordsQuery(patient)

    if (feedbacks.data) {
        feedbacks.data.map((d) => {
            const newData = [moment(d.createdAt).format("DD MMMM YYYY"), d.difficulty]
            LineData.push(newData)
        })
    }
    const LineChartOptions = {
        hAxis: {
            title: 'Date',
        },
        vAxis: {
            title: 'Difficulty',
        },
        series: {
            1: { curveType: 'function' },
        },
    }

    const PainPerData = [
        ['x', 'Pain Perception']
    ]

    //   const newData = [moment(d.createdAt).format("DD MMMM YYYY"), d.difficulty]
    //   const newPainPercData = [moment(d.createdAt).format("DD MMMM YYYY"), d.painPerception]
    //   LineData.push(newData)
    //   PainPerData.push(newPainPercData)


    // const RomChartOptions = {
    //     hAxis: {
    //         title: 'Date',
    //     },
    //     vAxis: {
    //         title: 'Range Of Movement(ROM)',
    //     },
    //     series: {
    //         1: { curveType: 'function' },
    //     },
    // }
    useEffect(() => {
        if (subscriptions.data) {
            // setSelectedFeedback(userFeedbacks[0])
            // setPhysioTest(userFeedbacks[0].physioTest)

            setPlan(subscriptions.data[0])
            if (feedbacks.data) {
                let tempUserFeedbacks = [];
                let element = subscriptions.data[0];
                if (feedbacks.data.filter((i) => i.subscription === element._id).length > 0) {
                    tempUserFeedbacks = feedbacks.data.filter((i) => i.subscription == element._id);
                }
                
                setUserFeedbacks(tempUserFeedbacks)

            }
        } else {
            if (subscriptions.error) {
                setTimeout(() => {
                    navigate('/patients', { replace: true })
                }, 2000)
            }
        }

    }, [subscriptions.data, feedbacks.data])

    useEffect(() => {
        if (userFeedbacks.length > 0) {
            setSelectedFeedback(userFeedbacks[0])


            // setPhysioTest(userFeedbacks[0].physioTest)
        }
    }, [userFeedbacks.length])
    useEffect(() => {
        if (selectedFeedback) {
           
            setAssessedPainScale(selectedFeedback.assessedPainScale)
            setMuscleTone(selectedFeedback.muscleTone)
            setMuscleStrength(selectedFeedback.muscleStrength)
            setRelativeIsoMov(selectedFeedback.relativeIsometricMovement)
            setMscleLengthTest(selectedFeedback.muscleLengthTest)
            setMuscleStrength(selectedFeedback.muscleStrengthTesting)
            setLimbGirthMeasure(selectedFeedback.limbGirthMeasurement)
            setRomDeg(selectedFeedback.rom)
            setSpecificGeriatric(selectedFeedback.specificGeriatric)
            setNeuro(selectedFeedback.neuro)
            setFA(selectedFeedback.functionalAssessment)
            setTouch(selectedFeedback.touch)
            setPain(selectedFeedback.pain)
            setTemp(selectedFeedback.temperature)
            setPressure(selectedFeedback.pressure)
            setChestExAfter(selectedFeedback.chestExpansion)
        }
    }, [selectedFeedback])

    const [rom, painPerception, difficulty, assessedPain, muscleT, muscleST, rimData, mltData, limbData, sgGraphData, neuroGraphData, chestGraph, functionalGraph, touchGraph, painGraph, tempGraph, pressureGraph] = useMemo(() => {


        //    let romData = []
        //    for (let index = 0; index < userFeedbacks.length; index++) {
        //     const element = userFeedbacks[index];
        //     const romArray =  element.rom.split('-')
        //     const newRomData = [{month: moment(element.createdAt).format("DD MMMM YYYY"), upper:Number.parseFloat(romArray[0]), lower:Number.parseFloat(romArray[0])}]
        //      romData.push(newRomData)
        //    } 
        const lowerRom = {
            id: "Physio Session ROM",
            color: theme.palette.secondary.main,
            data: [],
        };
        const painPerception1 = {
            id: "Pain Perception",
            color: theme.palette.secondary.main,
            data: [],
        };
        const difficulty1 = {
            id: "Difficulty",
            color: theme.palette.secondary.main,
            data: [],
        };

        const upperRom = {
            id: "Upper",
            color: theme.palette.primary.main,
            data: [],
        }
        const assessedPain1 = {
            id: "Assessed Pain Scale",
            color: theme.palette.primary.main,
            data: [],
        }

        const muscleT1 = {
            id: "Muscle Tone",
            color: theme.palette.primary.main,
            data: [],
        }
        const muscleST1 = {
            id: "Muscle Strength Testing",
            color: theme.palette.primary.main,
            data: [],
        }
        const rimData1 = {
            id: "Relative Isometric Movement",
            color: theme.palette.primary.main,
            data: [],
        }
        const mltData1 = {
            id: "Muscle Length Testing",
            color: theme.palette.primary.main,
            data: [],
        }
        const limbData1 = {
            id: "Limb Girth Measurement",
            color: theme.palette.primary.main,
            data: [],
        }

        const sgGraphData1 = {
            id: "Specific Geriatric",
            color: theme.palette.primary.main,
            data: [],
        }
        const neuroGraphData1 = {
            id: "Neuro",
            color: theme.palette.primary.main,
            data: [],
        }
        const chestGraph1 = {
            id: "Chest Expansion Before",
            color: theme.palette.primary.main,
            data: [],
        }
        const chestGraph2 = {
            id: "Chest Expansion After",
            color: theme.palette.primary.main,
            data: [],
        }
        const functionalGraph1 = {
            id: "Functional Assessment",
            color: theme.palette.primary.main,
            data: [],
        }
        const touchGraph1 = {
            id: "Touch",
            color: theme.palette.primary.main,
            data: [],
        }
        const painGraph1 = {
            id: "Pain",
            color: theme.palette.primary.main,
            data: [],
        }
        const tempGraph1 = {
            id: "Temperature",
            color: theme.palette.primary.main,
            data: [],
        }
        const pressureGraph1 = {
            id: "Pressure",
            color: theme.palette.primary.main,
            data: [],
        }
        const sessionRom = {
            id: "Device Feedback ROM",
            color: theme.palette.primary.main,
            data: [],
        }

        if (sessionFeedbacks.data) {
            Object.values(sessionFeedbacks.data).reduce((acc, { createdAt, difficulty, painPerception, rom }) => {
                painPerception1.data = [
                    ...painPerception1.data,
                    { x: moment(createdAt).format("DD MMMM YYYY"), y: Number.parseFloat(painPerception) },
                ];
                difficulty1.data = [
                    ...difficulty1.data,
                    { x: moment(createdAt).format("DD MMMM YYYY"), y: Number.parseFloat(difficulty) },
                ];
                sessionRom.data = [
                    ...sessionRom.data,
                    { x: moment(createdAt).format("DD MMMM YYYY"), y: Number.parseFloat(rom.split("-")[2]) },
                ];
            }, { createdAt: moment(new Date()).format("DD MMMM YYYY") })
        }
        Object.values(userFeedbacks).reduce(
            (acc, { createdAt, assessedPainScale, muscleTone, muscleStrengthTesting, muscleLengthTest, relativeIsometricMovement, limbGirthMeasurement, rom, specificGeriatric, neuro, chestExpansion, functionalAssessment, touch, pain, temperature, pressure }) => {

                const romArray = rom
                lowerRom.data = [
                    ...lowerRom.data,
                    { x: moment(createdAt).format("DD MMMM YYYY"), y: Number.parseFloat(rom) },
                ];
                if (assessedPainScale) {
                    assessedPain1.data = [
                        ...assessedPain1.data,
                        { x: moment(createdAt).format("DD MMMM YYYY"), y: assessedPainScale }
                    ]
                }

                if (muscleTone) {
                    muscleT1.data = [
                        ...muscleT1.data,
                        { x: moment(createdAt).format("DD MMMM YYYY"), y: muscleTone }
                    ]
                }

                if (muscleStrengthTesting) {
                    muscleST1.data = [
                        ...muscleST1.data,
                        { x: moment(createdAt).format("DD MMMM YYYY"), y: muscleStrengthTesting }
                    ]
                }

                if (relativeIsometricMovement) {
                    rimData1.data = [
                        ...rimData1.data,
                        { x: moment(createdAt).format("DD MMMM YYYY"), y: relativeIsometricMovement }
                    ]
                }

                if (muscleLengthTest) {
                    mltData1.data = [
                        ...mltData1.data,
                        { x: moment(createdAt).format("DD MMMM YYYY"), y: muscleLengthTest === "Yes" ? "T.P" : "N.P" }
                    ]
                }

                if (limbGirthMeasurement) {
                    limbData1.data = [
                        ...limbData1.data,
                        { x: moment(createdAt).format("DD MMMM YYYY"), y: limbGirthMeasurement }
                    ]
                }
                if (specificGeriatric) {
                    sgGraphData1.data = [
                        ...sgGraphData1.data,
                        { x: moment(createdAt).format("DD MMMM YYYY"), y: specificGeriatric }
                    ]
                }
                if (neuro) {
                    neuroGraphData1.data = [
                        ...neuroGraphData1.data,
                        { x: moment(createdAt).format("DD MMMM YYYY"), y: neuro }
                    ]
                }
                if (chestExpansion) {
                    chestGraph1.data = [
                        ...chestGraph1.data,
                        { x: moment(createdAt).format("DD MMMM YYYY"), y: chestExpansion }
                    ]
                }


                if (functionalAssessment) {
                    functionalGraph1.data = [
                        ...functionalGraph1.data,
                        { x: moment(createdAt).format("DD MMMM YYYY"), y: functionalAssessment }
                    ]
                }
                if (touch) {
                    touchGraph1.data = [
                        ...touchGraph1.data,
                        { x: moment(createdAt).format("DD MMMM YYYY"), y: touch }
                    ]
                }
                if (pain) {
                    painGraph1.data = [
                        ...painGraph1.data,
                        { x: moment(createdAt).format("DD MMMM YYYY"), y: pain }
                    ]
                }
                if (pressure) {
                    pressureGraph1.data = [
                        ...pressureGraph1.data,
                        { x: moment(createdAt).format("DD MMMM YYYY"), y: pressure }
                    ]
                }
                if (temperature) {
                    tempGraph1.data = [
                        ...tempGraph1.data,
                        { x: moment(createdAt).format("DD MMMM YYYY"), y: temperature }
                    ]
                }
                upperRom.data = [
                    ...upperRom.data,
                    { x: moment(createdAt).format("DD MMMM YYYY"), y: Number.parseFloat(romArray[0]) },
                ];
                // painPerception1.data = [
                //     ...painPerception1.data,
                //     { x: moment(createdAt).format("DD MMMM YYYY"), y: Number.parseFloat(painPerception) },
                // ];
                // difficulty1.data = [
                //     ...difficulty1.data,
                //     { x: moment(createdAt).format("DD MMMM YYYY"), y: Number.parseFloat(difficulty) },
                // ];


                return { createdAt: moment(createdAt).format("DD MMMM YYYY") };
            },
            { createdAt: moment(new Date()).format("DD MMMM YYYY") }
        )
        const rom = [lowerRom, sessionRom]
        const chestGraphFinal = [chestGraph1]
        return [rom, [painPerception1], [difficulty1], [assessedPain1], [muscleT1], [muscleST1], [rimData1], [mltData1], [limbData1], [sgGraphData1], [neuroGraphData1], chestGraphFinal, [functionalGraph1], [touchGraph1], [painGraph1], [tempGraph1], [pressureGraph1]];
    }, [userFeedbacks, sessionFeedbacks]);

    const [selectedPainScaled, setSelectedPainScaled] = useState(assessedPain);
    const [selectedStrengthTest, setSelectedStrengthTest] = useState();
    const [selectedBergBalance, setSelectedBergBalance] = useState()

    const [selectedSensory, setSelectedSensory] = useState()
    useEffect(() => {
        if (assessedPain) {
            setSelectedPainScaled(assessedPain)
        }
        if (rimData) {
            setSelectedStrengthTest(rimData)
        }
        if (sgGraphData) {
            setSelectedBergBalance(sgGraphData)
        }
        if (touchGraph) {
            setSelectedSensory(touchGraph)
        }
    }, [assessedPain, rimData])
    const [updateFeedback, response] = useUpdateFeedbackMutation()

    const handleSetAssessedPain = () => {
        setShowAccessScale(true)
    }
    const handleSetMuscleTone = () => {
        setShowMuscleTone(true)
    }
    const handleSetMuscleStrength = () => {
        setShowMuscleStrength(true)
    }

    const handleRIM = () => {
        setShowRIM(true)
    }
    const handleMLT = () => {
        setShowMLT(true)
    }
    const handleLGM = () => {
        setShowLGM(true)
    }

    const navigate = useNavigate()
    const addPainScale = () => {
        if (assessedPainScale) {

            let test = "Assessed Pain Scale"
            let score = assessedPainScale
            let tempPhysioTest = [];
            if (phyisoTest.filter((i) => i.test === test).length <= 0) {
                for (let index = 0; index < phyisoTest.length; index++) {
                    const element = phyisoTest[index];
                    tempPhysioTest.push(element)
                }
                tempPhysioTest.push({ test: test, score: score })
            } else {
                for (let index = 0; index < phyisoTest.filter((i) => i.test !== test).length; index++) {
                    const element = phyisoTest.filter((i) => i.test !== test)[index];

                    tempPhysioTest.push(element)
                }
                tempPhysioTest.push({ test: test, score: score })

            }


            updateFeedback({ physioTest: tempPhysioTest, id: selectedFeedback._id }).unwrap().then((data) => {
                setAssessedPainScale(assessedPainScale)
                setShowAccessScale(false)
                setSelectedFeedback(data)
                setPhysioTest(data.physioTest)
                console.log(data)
                const updatedArray = [...userFeedbacks];
                for (let index = 0; index < updatedArray.length; index++) {
                    const element = updatedArray[index];
                    if (updatedArray[index]._id === data._id) {
                        updatedArray[index] = data
                    }
                }
                setUserFeedbacks(updatedArray)
            }).catch((error) => {
                alert("Something went wrong")
            })
        } else {
            alert("Enter assessed pain scale.")
        }
    }
    const addMuscleTone = () => {
        if (muscleTone) {
            let test = "Muscle Tone"
            let score = muscleTone
            let tempPhysioTest = [];
            if (phyisoTest.filter((i) => i.test === test).length <= 0) {
                for (let index = 0; index < phyisoTest.length; index++) {
                    const element = phyisoTest[index];
                    tempPhysioTest.push(element)
                }
                tempPhysioTest.push({ test: test, score: score })
            } else {
                for (let index = 0; index < phyisoTest.filter((i) => i.test !== test).length; index++) {
                    const element = phyisoTest.filter((i) => i.test !== test)[index];

                    tempPhysioTest.push(element)
                }
                tempPhysioTest.push({ test: test, score: score })

            }
            updateFeedback({ physioTest: tempPhysioTest, id: selectedFeedback._id }).unwrap().then((data) => {
                setMuscleTone(muscleTone)
                setShowMuscleTone(false)
                setSelectedFeedback(data)
                setPhysioTest(data.physioTest)
                const updatedArray = [...userFeedbacks];
                for (let index = 0; index < updatedArray.length; index++) {
                    const element = updatedArray[index];
                    if (updatedArray[index]._id === data._id) {
                        updatedArray[index] = data
                    }
                }
                setUserFeedbacks(updatedArray)

            }).catch((error) => {
                alert("Something went wrong")
            })
        } else {
            alert("Enter muscle tone.")
        }
    }
    const addMuscleStrength = () => {
        if (muscleStrength >= 0) {
            let test = "Muscle Strength Testing"
            let score = muscleStrength
            let tempPhysioTest = [];
            if (phyisoTest.filter((i) => i.test === test).length <= 0) {
                for (let index = 0; index < phyisoTest.length; index++) {
                    const element = phyisoTest[index];
                    tempPhysioTest.push(element)
                }
                tempPhysioTest.push({ test: test, score: score })
            } else {
                for (let index = 0; index < phyisoTest.filter((i) => i.test !== test).length; index++) {
                    const element = phyisoTest.filter((i) => i.test !== test)[index];

                    tempPhysioTest.push(element)
                }
                tempPhysioTest.push({ test: test, score: score })

            }
            updateFeedback({ physioTest: tempPhysioTest, id: selectedFeedback._id }).unwrap().then((data) => {
                setMuscleStrength(muscleStrength)
                setShowMuscleStrength(false)
                setSelectedFeedback(data)
                setPhysioTest(data.physioTest)
                const updatedArray = [...userFeedbacks];
                for (let index = 0; index < updatedArray.length; index++) {
                    const element = updatedArray[index];
                    if (updatedArray[index]._id === data._id) {
                        updatedArray[index] = data
                    }
                }
                setUserFeedbacks(updatedArray)

            }).catch((error) => {
                alert("Something went wrong")
            })
        } else {
            alert("Enter muscle strength.")
        }
    }

    const addIsoMov = () => {
        if (relativeIsoMov) {
            let test = "Relative Isometric Movement"
            let score = relativeIsoMov
            let tempPhysioTest = [];
            if (phyisoTest.filter((i) => i.test === test).length <= 0) {
                for (let index = 0; index < phyisoTest.length; index++) {
                    const element = phyisoTest[index];
                    tempPhysioTest.push(element)
                }
                tempPhysioTest.push({ test: test, score: score })
            } else {
                for (let index = 0; index < phyisoTest.filter((i) => i.test !== test).length; index++) {
                    const element = phyisoTest.filter((i) => i.test !== test)[index];

                    tempPhysioTest.push(element)
                }
                tempPhysioTest.push({ test: test, score: score })

            }
            updateFeedback({ physioTest: tempPhysioTest, id: selectedFeedback._id }).unwrap().then((data) => {
                setRelativeIsoMov(score)
                setShowRIM(false)
                setSelectedFeedback(data)
                setPhysioTest(data.physioTest)
                const updatedArray = [...userFeedbacks];
                for (let index = 0; index < updatedArray.length; index++) {
                    const element = updatedArray[index];
                    if (updatedArray[index]._id === data._id) {
                        updatedArray[index] = data
                    }
                }
                setUserFeedbacks(updatedArray)

            }).catch((error) => {
                alert("Something went wrong")
            })
        } else {
            alert("Enter relative isometric movement.")
        }
    }
    const addMuscleLength = () => {
        if (muscleLengthTest) {
            let test = "Muscle Length Testing"
            let score = muscleLengthTest
            let tempPhysioTest = [];
            if (phyisoTest.filter((i) => i.test === test).length <= 0) {
                for (let index = 0; index < phyisoTest.length; index++) {
                    const element = phyisoTest[index];
                    tempPhysioTest.push(element)
                }
                tempPhysioTest.push({ test: test, score: score })
            } else {
                for (let index = 0; index < phyisoTest.filter((i) => i.test !== test).length; index++) {
                    const element = phyisoTest.filter((i) => i.test !== test)[index];

                    tempPhysioTest.push(element)
                }
                tempPhysioTest.push({ test: test, score: score })

            }
            updateFeedback({ physioTest: tempPhysioTest, id: selectedFeedback._id }).unwrap().then((data) => {
                setMscleLengthTest(score)
                setShowMLT(false)
                setSelectedFeedback(data)
                setPhysioTest(data.physioTest)
                const updatedArray = [...userFeedbacks];
                for (let index = 0; index < updatedArray.length; index++) {
                    const element = updatedArray[index];
                    if (updatedArray[index]._id === data._id) {
                        updatedArray[index] = data
                    }
                }
                setUserFeedbacks(updatedArray)

            }).catch((error) => {
                alert("Something went wrong")
            })
        } else {
            alert("Enter muscle length testing.")
        }
    }
    const addLimbMeasurement = () => {
        if (limbGirthMeasure) {
            let test = "Limb Girth Measurement"
            let score = limbGirthMeasure
            let tempPhysioTest = [];
            if (phyisoTest.filter((i) => i.test === test).length <= 0) {
                for (let index = 0; index < phyisoTest.length; index++) {
                    const element = phyisoTest[index];
                    tempPhysioTest.push(element)
                }
                tempPhysioTest.push({ test: test, score: score })
            } else {
                for (let index = 0; index < phyisoTest.filter((i) => i.test !== test).length; index++) {
                    const element = phyisoTest.filter((i) => i.test !== test)[index];

                    tempPhysioTest.push(element)
                }
                tempPhysioTest.push({ test: test, score: score })

            }
            updateFeedback({ physioTest: tempPhysioTest, id: selectedFeedback._id }).unwrap().then((data) => {
                setLimbGirthMeasure(score)
                setShowLGM(false)
                setSelectedFeedback(data)
                setPhysioTest(data.physioTest)
                const updatedArray = [...userFeedbacks];
                for (let index = 0; index < updatedArray.length; index++) {
                    const element = updatedArray[index];
                    if (updatedArray[index]._id === data._id) {
                        updatedArray[index] = data
                    }
                }
                setUserFeedbacks(updatedArray)

            }).catch((error) => {
                alert("Something went wrong")
            })
        } else {
            alert("Enter limb girth measurement.")
        }
    }

    const handlePlanChange = (e) => {
        const planData = JSON.parse(e.target.value)
        setPlan(planData)
        setUserFeedbacks(feedbacks.data.filter((i) => i.subscription === planData._id))
        setSelectedFeedback(feedbacks.data.filter((i) => i.subscription === planData._id)[0])

        // setPhysioTest(feedbacks.data.filter((i) => i.subscription === planData._id)[0].physioTest)
        // console.log(JSON.parse(e.target.value))
        // {phyisoTest.filter((i)=>i.test == "Assessed Pain Scale")[0].score}
    }
    const handleFeedbackChange = (e) => {
        const feedbackData = JSON.parse(e.target.value)
        setSelectedFeedback(feedbackData)

    }
    const handleCommentClick = () => {
        if (comment) {

            let test = "ROM Comment"
            let score = comment
            let tempPhysioTest = [];
            if (phyisoTest.filter((i) => i.test === test).length <= 0) {
                for (let index = 0; index < phyisoTest.length; index++) {
                    const element = phyisoTest[index];
                    tempPhysioTest.push(element)
                }
                tempPhysioTest.push({ test: test, score: score })
            } else {
                for (let index = 0; index < phyisoTest.filter((i) => i.test !== test).length; index++) {
                    const element = phyisoTest.filter((i) => i.test !== test)[index];

                    tempPhysioTest.push(element)
                }
                tempPhysioTest.push({ test: test, score: score })

            }


            updateFeedback({ physioTest: tempPhysioTest, id: selectedFeedback._id }).unwrap().then((data) => {
                setComment(score)

                setSelectedFeedback(data)
                setPhysioTest(data.physioTest)
                console.log(data)
                const updatedArray = [...userFeedbacks];
                for (let index = 0; index < updatedArray.length; index++) {
                    const element = updatedArray[index];
                    if (updatedArray[index]._id === data._id) {
                        updatedArray[index] = data
                    }
                }
                setUserFeedbacks(updatedArray)
                alert("Comment added successfully")
            }).catch((error) => {
                alert("Something went wrong")
            })
        } else {
            alert("Enter assessed pain scale.")
        }
    }



    const addSG = () => {
        if (specificGeriatric) {

            let test = "Specific Geriatric"
            let score = specificGeriatric
            let tempPhysioTest = [];
            if (phyisoTest.filter((i) => i.test === test).length <= 0) {
                for (let index = 0; index < phyisoTest.length; index++) {
                    const element = phyisoTest[index];
                    tempPhysioTest.push(element)
                }
                tempPhysioTest.push({ test: test, score: score })
            } else {
                for (let index = 0; index < phyisoTest.filter((i) => i.test !== test).length; index++) {
                    const element = phyisoTest.filter((i) => i.test !== test)[index];

                    tempPhysioTest.push(element)
                }
                tempPhysioTest.push({ test: test, score: score })

            }


            updateFeedback({ physioTest: tempPhysioTest, id: selectedFeedback._id }).unwrap().then((data) => {
                setSpecificGeriatric(score)
                setShowSG(false)
                setSelectedFeedback(data)
                setPhysioTest(data.physioTest)

                const updatedArray = [...userFeedbacks];
                for (let index = 0; index < updatedArray.length; index++) {
                    const element = updatedArray[index];
                    if (updatedArray[index]._id === data._id) {
                        updatedArray[index] = data
                    }
                }
                setUserFeedbacks(updatedArray)
            }).catch((error) => {
                alert("Something went wrong")
            })
        } else {
            alert("Enter assessed pain scale.")
        }
    }
    const addNeuro = () => {
        if (neuro) {
            let test = "Neuro"
            let score = neuro
            let tempPhysioTest = [];
            if (phyisoTest.filter((i) => i.test === test).length <= 0) {
                for (let index = 0; index < phyisoTest.length; index++) {
                    const element = phyisoTest[index];
                    tempPhysioTest.push(element)
                }
                tempPhysioTest.push({ test: test, score: score })
            } else {
                for (let index = 0; index < phyisoTest.filter((i) => i.test !== test).length; index++) {
                    const element = phyisoTest.filter((i) => i.test !== test)[index];

                    tempPhysioTest.push(element)
                }
                tempPhysioTest.push({ test: test, score: score })

            }
            updateFeedback({ physioTest: tempPhysioTest, id: selectedFeedback._id }).unwrap().then((data) => {
                setNeuro(score)
                setShowNeuro(false)
                setSelectedFeedback(data)
                setPhysioTest(data.physioTest)
                const updatedArray = [...userFeedbacks];
                for (let index = 0; index < updatedArray.length; index++) {
                    const element = updatedArray[index];
                    if (updatedArray[index]._id === data._id) {
                        updatedArray[index] = data
                    }
                }
                setUserFeedbacks(updatedArray)

            }).catch((error) => {
                alert("Something went wrong")
            })
        } else {
            alert("Enter muscle tone.")
        }
    }
    const addChestBefore = () => {
        if (chestExBefore) {
            let test = "Chest Expansion Before"
            let score = chestExBefore
            let tempPhysioTest = [];
            if (phyisoTest.filter((i) => i.test === test).length <= 0) {
                for (let index = 0; index < phyisoTest.length; index++) {
                    const element = phyisoTest[index];
                    tempPhysioTest.push(element)
                }
                tempPhysioTest.push({ test: test, score: score })
            } else {
                for (let index = 0; index < phyisoTest.filter((i) => i.test !== test).length; index++) {
                    const element = phyisoTest.filter((i) => i.test !== test)[index];

                    tempPhysioTest.push(element)
                }
                tempPhysioTest.push({ test: test, score: score })

            }
            updateFeedback({ physioTest: tempPhysioTest, id: selectedFeedback._id }).unwrap().then((data) => {
                setChestExBefore(score)
                setShowChest(false)
                setSelectedFeedback(data)
                setPhysioTest(data.physioTest)
                const updatedArray = [...userFeedbacks];
                for (let index = 0; index < updatedArray.length; index++) {
                    const element = updatedArray[index];
                    if (updatedArray[index]._id === data._id) {
                        updatedArray[index] = data
                    }
                }
                setUserFeedbacks(updatedArray)

            }).catch((error) => {
                alert("Something went wrong")
            })
        } else {
            alert("Enter muscle strength.")
        }
    }

    const addChestAfter = () => {
        if (chestExAfter) {
            let test = "Chest Expansion After"
            let score = chestExAfter
            let tempPhysioTest = [];
            if (phyisoTest.filter((i) => i.test === test).length <= 0) {
                for (let index = 0; index < phyisoTest.length; index++) {
                    const element = phyisoTest[index];
                    tempPhysioTest.push(element)
                }
                tempPhysioTest.push({ test: test, score: score })
            } else {
                for (let index = 0; index < phyisoTest.filter((i) => i.test !== test).length; index++) {
                    const element = phyisoTest.filter((i) => i.test !== test)[index];

                    tempPhysioTest.push(element)
                }
                tempPhysioTest.push({ test: test, score: score })

            }
            updateFeedback({ physioTest: tempPhysioTest, id: selectedFeedback._id }).unwrap().then((data) => {
                setChestExAfter(score)
                setShowChestAfter(false)
                setSelectedFeedback(data)
                setPhysioTest(data.physioTest)
                const updatedArray = [...userFeedbacks];
                for (let index = 0; index < updatedArray.length; index++) {
                    const element = updatedArray[index];
                    if (updatedArray[index]._id === data._id) {
                        updatedArray[index] = data
                    }
                }
                setUserFeedbacks(updatedArray)

            }).catch((error) => {
                alert("Something went wrong")
            })
        } else {
            alert("Enter relative isometric movement.")
        }
    }
    const addFunctional = () => {
        if (fa) {
            let test = "Functional Assessment"
            let score = fa
            let tempPhysioTest = [];
            if (phyisoTest.filter((i) => i.test === test).length <= 0) {
                for (let index = 0; index < phyisoTest.length; index++) {
                    const element = phyisoTest[index];
                    tempPhysioTest.push(element)
                }
                tempPhysioTest.push({ test: test, score: score })
            } else {
                for (let index = 0; index < phyisoTest.filter((i) => i.test !== test).length; index++) {
                    const element = phyisoTest.filter((i) => i.test !== test)[index];

                    tempPhysioTest.push(element)
                }
                tempPhysioTest.push({ test: test, score: score })

            }
            updateFeedback({ physioTest: tempPhysioTest, id: selectedFeedback._id }).unwrap().then((data) => {
                setFA(score)
                setShowFA(false)
                setSelectedFeedback(data)
                setPhysioTest(data.physioTest)
                const updatedArray = [...userFeedbacks];
                for (let index = 0; index < updatedArray.length; index++) {
                    const element = updatedArray[index];
                    if (updatedArray[index]._id === data._id) {
                        updatedArray[index] = data
                    }
                }
                setUserFeedbacks(updatedArray)

            }).catch((error) => {
                alert("Something went wrong")
            })
        } else {
            alert("Enter muscle length testing.")
        }
    }
    const addTouch = () => {
        if (touch) {
            let test = "Touch"
            let score = touch
            let tempPhysioTest = [];
            if (phyisoTest.filter((i) => i.test === test).length <= 0) {
                for (let index = 0; index < phyisoTest.length; index++) {
                    const element = phyisoTest[index];
                    tempPhysioTest.push(element)
                }
                tempPhysioTest.push({ test: test, score: score })
            } else {
                for (let index = 0; index < phyisoTest.filter((i) => i.test !== test).length; index++) {
                    const element = phyisoTest.filter((i) => i.test !== test)[index];

                    tempPhysioTest.push(element)
                }
                tempPhysioTest.push({ test: test, score: score })

            }
            updateFeedback({ physioTest: tempPhysioTest, id: selectedFeedback._id }).unwrap().then((data) => {
                setTouch(score)
                setShowTouch(false)
                setSelectedFeedback(data)
                setPhysioTest(data.physioTest)
                const updatedArray = [...userFeedbacks];
                for (let index = 0; index < updatedArray.length; index++) {
                    const element = updatedArray[index];
                    if (updatedArray[index]._id === data._id) {
                        updatedArray[index] = data
                    }
                }
                setUserFeedbacks(updatedArray)

            }).catch((error) => {
                alert("Something went wrong")
            })
        } else {
            alert("Enter limb girth measurement.")
        }
    }

    const addPain = () => {
        if (pain) {

            let test = "Pain"
            let score = pain
            let tempPhysioTest = [];
            if (phyisoTest.filter((i) => i.test === test).length <= 0) {
                for (let index = 0; index < phyisoTest.length; index++) {
                    const element = phyisoTest[index];
                    tempPhysioTest.push(element)
                }
                tempPhysioTest.push({ test: test, score: score })
            } else {
                for (let index = 0; index < phyisoTest.filter((i) => i.test !== test).length; index++) {
                    const element = phyisoTest.filter((i) => i.test !== test)[index];

                    tempPhysioTest.push(element)
                }
                tempPhysioTest.push({ test: test, score: score })

            }


            updateFeedback({ physioTest: tempPhysioTest, id: selectedFeedback._id }).unwrap().then((data) => {
                setPain(score)
                setShowPain(false)
                setSelectedFeedback(data)
                setPhysioTest(data.physioTest)
                console.log(data)
                const updatedArray = [...userFeedbacks];
                for (let index = 0; index < updatedArray.length; index++) {
                    const element = updatedArray[index];
                    if (updatedArray[index]._id === data._id) {
                        updatedArray[index] = data
                    }
                }
                setUserFeedbacks(updatedArray)
            }).catch((error) => {
                alert("Something went wrong")
            })
        } else {
            alert("Enter assessed pain scale.")
        }
    }
    const addTemperature = () => {
        if (temparature) {
            let test = "Temperature"
            let score = temparature
            let tempPhysioTest = [];
            if (phyisoTest.filter((i) => i.test === test).length <= 0) {
                for (let index = 0; index < phyisoTest.length; index++) {
                    const element = phyisoTest[index];
                    tempPhysioTest.push(element)
                }
                tempPhysioTest.push({ test: test, score: score })
            } else {
                for (let index = 0; index < phyisoTest.filter((i) => i.test !== test).length; index++) {
                    const element = phyisoTest.filter((i) => i.test !== test)[index];

                    tempPhysioTest.push(element)
                }
                tempPhysioTest.push({ test: test, score: score })

            }
            updateFeedback({ physioTest: tempPhysioTest, id: selectedFeedback._id }).unwrap().then((data) => {
                setTemp(score)
                setShowTemp(false)
                setSelectedFeedback(data)
                setPhysioTest(data.physioTest)
                const updatedArray = [...userFeedbacks];
                for (let index = 0; index < updatedArray.length; index++) {
                    const element = updatedArray[index];
                    if (updatedArray[index]._id === data._id) {
                        updatedArray[index] = data
                    }
                }
                setUserFeedbacks(updatedArray)

            }).catch((error) => {
                alert("Something went wrong")
            })
        } else {
            alert("Enter muscle tone.")
        }
    }
    const addPressure = () => {
        if (pressure) {
            let test = "Pressure"
            let score = pressure
            let tempPhysioTest = [];
            if (phyisoTest.filter((i) => i.test === test).length <= 0) {
                for (let index = 0; index < phyisoTest.length; index++) {
                    const element = phyisoTest[index];
                    tempPhysioTest.push(element)
                }
                tempPhysioTest.push({ test: test, score: score })
            } else {
                for (let index = 0; index < phyisoTest.filter((i) => i.test !== test).length; index++) {
                    const element = phyisoTest.filter((i) => i.test !== test)[index];

                    tempPhysioTest.push(element)
                }
                tempPhysioTest.push({ test: test, score: score })

            }
            updateFeedback({ physioTest: tempPhysioTest, id: selectedFeedback._id }).unwrap().then((data) => {
                setPressure(pressure)
                setShowPressure(false)
                setSelectedFeedback(data)
                setPhysioTest(data.physioTest)
                const updatedArray = [...userFeedbacks];
                for (let index = 0; index < updatedArray.length; index++) {
                    const element = updatedArray[index];
                    if (updatedArray[index]._id === data._id) {
                        updatedArray[index] = data
                    }
                }
                setUserFeedbacks(updatedArray)

            }).catch((error) => {
                alert("Something went wrong")
            })
        } else {
            alert("Enter muscle strength.")
        }
    }

    const handleUpdateFeedback = () => {

        const physioSessionData = {
            subscription: plan._id, plan: plan.plan._id, user: patient, partner: userData.userData[0]._id,
            muscleTone: muscleTone,
            assessedPainScale: assessedPainScale,
            muscleStrengthTesting: muscleStrength,
            relativeIsometricMovement: relativeIsoMov,
            muscleLengthTest: muscleLengthTest,
            limbGirthMeasurement: limbGirthMeasure,
            rom: romDeg,
            id: selectedFeedback._id,
            specificGeriatric: specificGeriatric,
            neuro: neuro,
            chestExpansion: chestExAfter,
            functionalAssessment: fa,
            touch: touch,
            pain: pain,
            temperature: temparature,
            pressure: pressure
        }
        updatePhysioSession(physioSessionData).unwrap().then((d) => {
            toast.success("Session data updated successfully")
            setSelectedFeedback(d)

            const updatedArray = [...userFeedbacks];
            for (let index = 0; index < updatedArray.length; index++) {
                const element = updatedArray[index];
                if (updatedArray[index]._id === d._id) {
                    updatedArray[index] = d
                }
            }
            setUserFeedbacks(updatedArray)

        }).catch((error) => {
            if (error) {
                toast.error(error.message)
            }
        })
    }

    const isNonMobile = useMediaQuery("(min-width: 600px)");
    const [isSidebarOpen, setIsSidebarOpen] = useState(false)


    const isDarkMode = theme.palette.mode === 'dark';

    const Boxtyle = {
        backgroundColor: isDarkMode ? "#2e258a" : tokensDark.secondary[300],
        color: isDarkMode ? "#ffe3a3" : tokensDark.secondary[700],

    };
 
    const graphbackstyle = { backgroundColor: isDarkMode ? "#21295c" : "#e8e8e8", };
    return (

        <div className="flex flex-col md:flex-row w-full text-lg sm:text-base">
        <div className='flex flex-col md:flex-row'>
                    <Fab
                        color="primary"
                        aria-label="back"
                        size="small"
                        sx={{
                            backgroundColor: isDarkMode ? theme.palette.primary.light : theme.palette.secondary.light,
                            marginLeft: "8px"
                        }}
                        onClick={() => { navigate(-1); }} // Navigate back to the previous screen
                    >
                        <ArrowBack />
                    </Fab>
                </div>
            <ToastContainer containerId="toast" autoClose={2000} position="top-center" hideProgressBar={true} />

            <div className="w-full md:min-w-3/4 p-4 overflow-y-auto">
               <div className='flex justify-between w-full items-center'>
               <h1 className='text-center text-2xl font-semibold py-2 '>Patient Dashboard</h1>
              {userFeedbacks && userFeedbacks.length > 0 && <h1 className='text-center text-lg font-semibold py-2 underline cursor-pointer' onClick={()=>{navigate("/report", {state: {subscription: plan,sessions:userFeedbacks, user: data, userPhone: mobile, refer: userData.userData[0].name}})}}> Generate Report<Download/></h1>}
               </div>
                <div className="grid text-black text-center grid-cols-1 md:grid-cols-3 grid-flow-row gap-4 ">
                    <div className="w-full p-4 rounded-lg align-center " style={Boxtyle}
                    >
                        <select onChange={handlePlanChange} className='bg-transparent text-lg sm:text-base font-medium w-full'>
                            {
                                subscriptions.data && subscriptions.data.map((s) => {
                                    return s && <option value={JSON.stringify(s)} key={s._id}>
                                        {s.plan ? s.plan.name : ''}
                                    </option>
                                })
                            }
                        </select>
                        <h4 className="text-md opacity-80 mt-2">Plan Name</h4>
                    </div>
                    {
                        subscriptions.data && <div className="p-4 rounded-lg" style={Boxtyle}>
                            <h2 className="text-lg font-medium mb-2">{plan && moment(plan.startsAt).utc().format("DD MMMM YYYY")}</h2>
                            <h4 className="text-md opacity-80">Plan Start Date</h4>
                        </div>
                    }
                    {
                        subscriptions.data && <div className="p-4 rounded-lg" style={Boxtyle}>
                            <h2 className="text-xl font-medium mb-2">{plan && plan.days} Days</h2>
                            <h4 className="text-lg opacity-80">Plan Duration</h4>
                        </div>
                    }


                </div>
                <div className="w-full flex align-middle mt-14 mb-4">
                    <h1 className='text-2xl'>Physio Session: </h1>
                    <div className="rounded-lg ml-5  w-1/4">

                        <select onChange={handleFeedbackChange} className='p-2 rounded-lg w-full' style={Boxtyle}>
                            {
                                userFeedbacks && userFeedbacks.map((feed, i) => {
                                    return <option value={JSON.stringify(feed)} key={i} className="text-lg sm:text-base">
                                        {i + 1}
                                    </option>
                                })
                            }
                        </select>
                    </div>

                </div>
                <Button onClick={() => { navigate(`/patient/update-stats?patient=${patient}&mobile=${mobile}`) }} variant="contained" sx={{ mt: "4px", mb: "8px" }}>Add Physio Session Record</Button>
               <div className='pdfDiv' id='pdf-div'> {
                    userFeedbacks.length > 0 && <>
                        <div className='flex flex-col md:flex-row w-full justify-between p-6 rounded-md' style={graphbackstyle}>
                            <div className='w-full px-4 md:px-0 md:w-2/3 mx-auto '>
                                {
                                    selectedPainScaled && <ResponsiveLine
                                        data={selectedPainScaled}
                                        theme={{
                                            axis: {
                                                domain: {
                                                    line: {
                                                        stroke: theme.palette.secondary[200],
                                                    },
                                                },
                                                legend: {
                                                    text: {
                                                        fill: theme.palette.secondary[200],
                                                    },
                                                },
                                                ticks: {
                                                    line: {
                                                        stroke: theme.palette.secondary[200],
                                                        strokeWidth: 1,
                                                    },
                                                    text: {
                                                        fill: theme.palette.secondary[200],
                                                    },
                                                },
                                                class: 'text-sm lg:text-base whitespace-nowrap md:whitespace-normal md:flex-wrap truncated',

                                            },
                                            legends: {
                                                text: {
                                                    fill: theme.palette.secondary[200],
                                                },
                                            },
                                            tooltip: {
                                                container: {
                                                    color: theme.palette.primary.main,
                                                },
                                            },
                                        }}

                                        margin={margin}
                                        xScale={{ type: "point" }}
                                        yScale={{
                                            type: "band",
                                            min: "auto",
                                            max: "auto",
                                            stacked: false,
                                            reverse: false,
                                        }}
                                        yFormat=" >-.2f"
                                        curve="catmullRom"
                                        enableArea={true}
                                        axisTop={null}
                                        axisRight={null}
                                        axisBottom={{
                                            format: (v) => {
                                                // abbreviate month names
                                                const monthAbbreviations = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                                                return monthAbbreviations[v - 1];
                                            },
                                            orient: "bottom",
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0, // rotate the labels by 45 degrees
                                            legend: "Month",
                                            legendOffset: 40,
                                            legendPosition: "middle",
                                            tickMargin: 5,


                                            // display only 5 tick values
                                        }}
                                        axisLeft={{
                                            orient: "left",
                                            tickValues: 5,
                                            tickSize: 3,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: `${selectedPainScaled === muscleT ? "Muscle Tone" : selectedPainScaled === muscleST ? "Muscle Strength Testing" : "Assessed Pain Scale"}`,
                                            legendOffset: -60,
                                            legendPosition: "middle",
                                        }}
                                        enableGridX={false}
                                        enableGridY={false}
                                        pointSize={10}
                                        pointColor={{ theme: "background" }}
                                        pointBorderWidth={2}
                                        pointBorderColor={{ from: "serieColor" }}
                                        pointLabelYOffset={-12}
                                        useMesh={true}

                                    />
                                }
                            </div>
                            <div className="grid text-black text-center grid-cols-2  gap-2 mt-4 ml-4">
                                <div onClick={() => { setSelectedPainScaled(assessedPain) }} className={`opacity-${selectedPainScaled === assessedPain ? 100 : 40} p-4 rounded-lg cursor-pointer`} style={Boxtyle}>
                                    <h2 className="text-xl font-medium mb-2">{assessedPainScale + "/10"}</h2>
                                    <h4 className="text-lg opacity-80">Assessed pain scale</h4>
                                    {showAssessScale && <div>
                                        <label for="number" class="block mb-2 text-sm font-medium">Enter score between 0-10</label>
                                        <select onChange={(e) => { setAssessedPainScale(e.target.value) }} type="number" max={10} min={0} name="number" id="email" class="bg-gray-500 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-gray-500 border-gray-500 placeholder-gray-400 " placeholder="7" required>
                                            <option>0</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                            <option>6</option>
                                            <option>7</option>
                                            <option>8</option>
                                            <option>9</option>
                                            <option>10</option>
                                        </select>
                                        <div className='flex gap-2 align-middle justify-center'>
                                            <Check mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { handleUpdateFeedback() }} />
                                            <Clear mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { setShowAccessScale(false) }} />
                                        </div>
                                    </div>
                                    }
                                    {!showAssessScale && <Edit sx={{ cursor: 'pointer' }} onClick={() => { handleSetAssessedPain() }} />}

                                </div>
                                <div onClick={() => { setSelectedPainScaled(muscleT) }} className={`opacity-${selectedPainScaled === muscleT ? 100 : 40} p-4 rounded-lg  cursor-pointer`} style={Boxtyle}>
                                    <h2 className="text-xl font-medium mb-2">{muscleTone}</h2>
                                    <h4 className="text-lg opacity-80">Muscle tone</h4>
                                    {!showMuscleTone && <Edit sx={{ cursor: 'pointer' }} onClick={() => { handleSetMuscleTone() }} />}
                                    {showMuscleTone && <div>
                                        <label for="number" class="block mb-2 text-sm font-medium ">Enter score between 0-4</label>
                                        <select onChange={(e) => { setMuscleTone(e.target.value) }} type="number" max={10} min={0} name="number" id="email" class="bg-gray-500 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-gray-500 border-gray-500 placeholder-gray-400 " placeholder="7" required>
                                            <option>0</option>
                                            <option>1</option>
                                            <option>1+</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>

                                        </select>
                                        <Check mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { handleUpdateFeedback() }} />
                                        <Clear mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { setShowMuscleTone(false) }} />
                                    </div>
                                    }

                                </div>

                                <div onClick={() => { setSelectedPainScaled(muscleST) }} style={Boxtyle} className={` opacity-${selectedPainScaled === muscleST ? 100 : 40}  col-span-2 p-4  cursor-pointer rounded-lg`}>
                                    <h2 className="text-xl font-medium mb-2">{muscleStrength + "/5"}</h2>
                                    <h4 className="text-lg opacity-80">Muscle strength testing</h4>
                                    {showMuscleStrength && <div>
                                        <label for="number" class="block mb-2 text-sm font-medium">Enter score between 0-5</label>
                                        <select onChange={(e) => { setMuscleStrength(e.target.value) }} type="number" max={10} min={0} name="number" id="email" class="bg-gray-500 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="7" required>
                                            <option>0</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>

                                        </select>
                                        <Check mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { handleUpdateFeedback() }} />
                                        <Clear mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { setShowMuscleStrength(false) }} />

                                    </div>
                                    }
                                    {!showMuscleStrength && <Edit sx={{ cursor: 'pointer' }} onClick={() => { handleSetMuscleStrength() }} />}

                                </div>
                            </div>


                        </div>
                        {/*Strength Testing*/}
                        <h2 className='mt-14 text-2xl'>Strength Testing</h2>
                        <div className='flex justify-between mt-4 p-6 rounded-md flex-col md:flex-row w-full' style={graphbackstyle}>
                            <div className='w-full md:w-2/4'>
                                {
                                    selectedStrengthTest && <ResponsiveLine
                                        data={selectedStrengthTest}
                                        theme={{
                                            axis: {
                                                domain: {
                                                    line: {
                                                        stroke: theme.palette.secondary[200],
                                                    },
                                                },
                                                legend: {
                                                    text: {
                                                        fill: theme.palette.secondary[200],
                                                        transform: "translateX(-20px)"
                                                    },
                                                },
                                                ticks: {
                                                    line: {
                                                        stroke: theme.palette.secondary[200],
                                                        strokeWidth: 1,

                                                    },
                                                    text: {
                                                        fill: theme.palette.secondary[200],


                                                    },
                                                },
                                            },
                                            legends: {
                                                text: {
                                                    fill: theme.palette.secondary[200],
                                                    transform: "translateX(-20px)"

                                                },
                                            },
                                            tooltip: {
                                                container: {
                                                    color: theme.palette.primary.main,
                                                    transform: "translateX(-30px)"
                                                },
                                            },
                                        }}
                                        margin={margin}
                                        xScale={{ type: "point" }}

                                        yScale={{
                                            type: "point",
                                            min: "auto",
                                            max: "auto",

                                            stacked: false,
                                            reverse: false,
                                            // domain:["Tightness Not Present", "Tightness Present"]
                                        }}
                                        yFormat={(value) => `${value}`}
                                        curve="catmullRom"
                                        enableArea={true}
                                        axisTop={null}
                                        axisRight={null}
                                        axisBottom={{
                                            format: (v) => {
                                                // abbreviate month names
                                                const monthAbbreviations = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                                                return monthAbbreviations[v - 1];
                                            },
                                            orient: "bottom",
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0, // rotate the labels by 45 degrees
                                            legend: "Month",
                                            legendOffset: 36,
                                            legendPosition: "middle",
                                            ticks: 5, // display only 5 tick values
                                        }}


                                        axisLeft={{
                                            orient: "left",
                                            format: (v) => {
                                                return v === "Strong and Painful" ? "S.Pl" : v === "Strong and Painfree" ? "S.Pr" : v === "Weak and Painful" ? "W.Pl" : v === "Weak and Painfree" ? "W.Pr" : v === "Girth Equal (on both the sides)" ? "G.E" : v === "Girth Unequal (on both the sides)" ? "G.U" : v;

                                            },
                                            tickSize: 0,
                                            tickPadding: 10,
                                            tickRotation: 0,
                                            ticksPosition: 10,
                                            tickValues: "point",
                                            legend: `${selectedStrengthTest === rimData ? "Relative Isometric Movement" : selectedStrengthTest === mltData ? "Muscle Length Testing" : "Limb Girth Measurement"}`,
                                            legendOffset: -60,
                                            legendPosition: "middle",
                                        }}
                                        enableGridX={false}
                                        enableGridY={false}
                                        pointSize={10}
                                        pointColor={{ theme: "background" }}
                                        pointBorderWidth={2}
                                        pointBorderColor={{ from: "serieColor" }}
                                        pointLabelYOffset={-12}
                                        useMesh={true}
                                        legends={

                                            [

                                                {
                                                    data: selectedStrengthTest === rimData ? [{
                                                        label: "S.Pr: Strong and Painfree"
                                                    }, {
                                                        label: "S.Pl: Strong and Painful"
                                                    }, {
                                                        label: "W.Pr: Weak and Painfree"
                                                    }, {
                                                        label: "W.Pl: Weak and Painful"
                                                    }] : selectedStrengthTest === mltData ? [
                                                        {
                                                            label: "T.P: Tightness Present"
                                                        }, {
                                                            label: "N.P: Tightness Not Present"
                                                        }
                                                    ] : [{
                                                        label: "G.E: Girth Equal"
                                                    }, {
                                                        label: "G.U: Girth Unequal"
                                                    }],
                                                    anchor: "top-right",
                                                    direction: "column",
                                                    justify: false,
                                                    translateX: -10,
                                                    translateY: -20,
                                                    itemsSpacing: 0,
                                                    itemDirection: "left-to-right",
                                                    itemWidth: 100,
                                                    itemHeight: 20,
                                                    itemOpacity: 0.75,
                                                    symbolSize: 12,
                                                    symbolShape: "circle",
                                                    symbolBorderColor: "rgba(0, 0, 0, .5)",
                                                    effects: [
                                                        {
                                                            on: "hover",
                                                            style: {
                                                                itemBackground: "rgba(0, 0, 0, .03)",
                                                                itemOpacity: 1,
                                                            },
                                                        },
                                                    ],
                                                },
                                            ]

                                        }
                                    />
                                }
                            </div>
                            <div className="grid w-full sm:w-2/4 text-black text-center grid-cols-2 gap-4 mt-4">
                                <div onClick={() => { setSelectedStrengthTest(rimData) }} className={`opacity-${selectedStrengthTest === rimData ? 100 : 40} p-4 rounded-lg align-center`} style={Boxtyle}>

                                    <h2 className="text-xl font-medium mb-2">{relativeIsoMov}</h2>
                                    <h4 className="text-lg opacity-80">Relative Isometric Movement</h4>

                                    {showRIM && <div>
                                        <label for="number" class="block mb-2 text-sm font-medium ">Enter your inputs</label>
                                        <select onChange={(e) => { setRelativeIsoMov(e.target.value) }} className='w-full p-2 rounded-lg bg-gray-500 '>
                                            <option>Strong and Painfree</option>
                                            <option>Weak and Painful</option>
                                            <option>Strong and Painful</option>
                                            <option>Weak and Painfree</option>
                                        </select>


                                        <Check mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { handleUpdateFeedback() }} />
                                        <Clear mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { setShowRIM(false) }} />

                                    </div>
                                    }
                                    {!showRIM && <Edit sx={{ cursor: 'pointer' }} onClick={() => { handleRIM() }} />}

                                </div>
                                <div onClick={() => { setSelectedStrengthTest(mltData) }} className={` opacity-${selectedStrengthTest === mltData ? 100 : 40} p-4 rounded-lg`} style={Boxtyle}>
                                    <h2 className="text-xl font-medium mb-2">{muscleLengthTest === "Yes" ? "Tightness Present" : muscleLengthTest === "No" ? "Tightness Not Present" : ""}</h2>
                                    <h4 className="text-lg opacity-80">Muscle Length Testing</h4>
                                    {showMLT && <div>
                                        <label for="number" class="block mb-2 text-sm font-medium">Tightness present?</label>
                                        <select onChange={(e) => { setMscleLengthTest(e.target.value) }} className='p-2 rounded-lg bg-gray-500'>
                                            <option>Yes</option>
                                            <option>No</option>

                                        </select>


                                        <Check mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { handleUpdateFeedback() }} />
                                        <Clear mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { setShowMLT(false) }} />

                                    </div>
                                    }
                                    {!showMLT && <Edit sx={{ cursor: 'pointer' }} onClick={() => { handleMLT() }} />}

                                </div>
                                <div onClick={() => { setSelectedStrengthTest(limbData) }} className={`opacity-${selectedStrengthTest === limbData ? 100 : 40}  p-4 col-span-2 rounded-lg`} style={Boxtyle}>
                                    <h2 className="text-xl font-medium mb-2">{limbGirthMeasure}</h2>
                                    <h4 className="text-lg opacity-80">Limb Girth Measurement</h4>
                                    {showLGM && <div>
                                        <label for="number" class="block mb-2 text-sm font-medium">Enter limb girth measurement</label>
                                        <select onChange={(e) => { setLimbGirthMeasure(e.target.value) }} className='p-2 rounded-lg bg-gray-500'>
                                            <option>Girth Equal (on both the sides)</option>
                                            <option>Girth Unequal (on both the sides)</option>

                                        </select>
                                        <Check mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { handleUpdateFeedback() }} />
                                        <Clear mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { setShowLGM(false) }} />

                                    </div>
                                    }
                                    {!showLGM && <Edit sx={{ cursor: 'pointer' }} onClick={() => { handleLGM() }} />}

                                </div>

                            </div>

                        </div>
                      
                        {/**Balance and coordination */}
                        <div className=' mb-8 p-4 bg-transparent mt-14'>
                            <h1 className='mt-4 text-2xl font-bold'>Balance And Coordination Scale</h1>

                            <div className='w-full'>
                                <div className="w-full text-[#ffe3a3]">
                                    <div className='p-4 mt-4 w-full'>
                                        <h4 className='text-xl font-medium text-[#ffe3a3]'>Berg Balance Scale</h4>
                                        <div className='flex rounded-md p-4 mt-4 flex-col md:flex-row' style={graphbackstyle}>
                                            <div className='w-full align-middle justify-center md:w-3/4'>
                                                {
                                                    selectedBergBalance && <ResponsiveLine
                                                        data={selectedBergBalance}

                                                        theme={{
                                                            axis: {
                                                                domain: {
                                                                    line: {
                                                                        stroke: theme.palette.secondary[200],
                                                                    },
                                                                },
                                                                legend: {
                                                                    text: {
                                                                        fill: theme.palette.secondary[200],
                                                                    },
                                                                },
                                                                ticks: {
                                                                    line: {
                                                                        stroke: theme.palette.secondary[200],
                                                                        strokeWidth: 1,
                                                                    },
                                                                    text: {
                                                                        fill: theme.palette.secondary[200],


                                                                    },
                                                                },
                                                            },
                                                            legends: {
                                                                text: {
                                                                    fill: theme.palette.secondary[200],
                                                                },
                                                            },
                                                            tooltip: {
                                                                container: {
                                                                    color: theme.palette.primary.main,
                                                                },
                                                            },
                                                        }}
                                                        margin={{ top: 20, right: 50, bottom: 50, left: 70 }}
                                                        xScale={{ type: "point" }}
                                                        yScale={{
                                                            type: "point",
                                                            min: "auto",
                                                            max: "auto",

                                                            stacked: false,
                                                            reverse: false,
                                                            // domain:["Tightness Not Present", "Tightness Present"]
                                                        }}
                                                        yFormat={(value) => `${value}`}
                                                        curve="catmullRom"
                                                        enableArea={true}
                                                        axisTop={null}
                                                        axisRight={null}
                                                        axisBottom={{
                                                            format: (v) => {
                                                                return v;

                                                            },
                                                            orient: "bottom",
                                                            tickSize: 5,
                                                            tickPadding: 5,
                                                            tickRotation: 0,
                                                            legend: "Month",
                                                            legendOffset: 36,
                                                            legendPosition: "middle",
                                                        }}
                                                        axisLeft={{
                                                            orient: "left",

                                                            tickSize: 0,
                                                            tickPadding: 5,
                                                            tickRotation: -10,
                                                            tickValues: "point",
                                                            legend: `${selectedBergBalance === sgGraphData ? "Specific Geriatric" : "Neuro"}`,
                                                            legendOffset: -60,
                                                            legendPosition: "middle",
                                                        }}
                                                        enableGridX={false}
                                                        enableGridY={false}
                                                        pointSize={10}
                                                        pointColor={{ theme: "background" }}
                                                        pointBorderWidth={2}
                                                        pointBorderColor={{ from: "serieColor" }}
                                                        pointLabelYOffset={-12}
                                                        useMesh={true}

                                                    />
                                                }
                                            </div>
                                            <div className='text-center w-full md:w-1/4'>
                                                <div onClick={() => { setSelectedBergBalance(sgGraphData) }} className={`opacity-${selectedBergBalance === sgGraphData ? 100 : 40} p-4 mt-2 rounded-lg`} style={Boxtyle}>
                                                    <h4 className='text-lg'>{specificGeriatric + "/56"}</h4>
                                                    <h4 className='text-md'>Specific Geriatric</h4>
                                                    {
                                                        showSG && <div>
                                                            <select onChange={(e) => { setSpecificGeriatric(e.target.value) }} className='p-2 rounded-lg bg-gray-500'>
                                                                {
                                                                    bergBalance.map((v, i) => {
                                                                        return (
                                                                            <option key={i}>{i}</option>
                                                                        )
                                                                    })
                                                                }

                                                            </select>
                                                            <Check mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={(e) => { handleUpdateFeedback() }} />
                                                            <Clear mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { setShowSG(false) }} />

                                                        </div>
                                                    }
                                                    {!showSG && <Edit sx={{ cursor: 'pointer' }} onClick={() => { setShowSG(true) }} />}
                                                </div>
                                                <div onClick={() => { setSelectedBergBalance(neuroGraphData) }} className={`opacity-${selectedBergBalance === neuroGraphData ? 100 : 40} p-4 mt-2 rounded-lg`} style={Boxtyle}>
                                                    <h4 className='text-lg'>{neuro + "/56"}</h4>
                                                    <h4 className='text-md'>Neuro</h4>
                                                    {
                                                        showNeuro && <div>
                                                            <select onChange={(e) => { setNeuro(e.target.value) }} className='p-2 rounded-lg bg-gray-500'>
                                                                {
                                                                    bergBalance.map((v, i) => {
                                                                        return (
                                                                            <option key={i}>{i}</option>
                                                                        )
                                                                    })
                                                                }

                                                            </select>
                                                            <Check mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={(e) => { handleUpdateFeedback() }} />
                                                            <Clear mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { setShowNeuro(false) }} />

                                                        </div>
                                                    }
                                                    {!showNeuro && <Edit sx={{ cursor: 'pointer' }} onClick={() => { setShowNeuro(true)() }} />}
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                    <div className='p-4 mt-4 '>
                                        <h4 className='text-xl font-medium text-[#ffe3a3]'>Chest Expansion Measurement</h4>
                                        <div className='flex flex-col md:flex-row text-center align-middle justify-between w-full rounded-md mt-4 p-4' style={graphbackstyle}>
                                            <div className='flex w-full md:w-2/4'>
                                                {
                                                    chestGraph && <ResponsiveLine
                                                        data={chestGraph}
                                                        theme={{
                                                            axis: {
                                                                domain: {
                                                                    line: {
                                                                        stroke: theme.palette.secondary[200],
                                                                    },
                                                                },
                                                                legend: {
                                                                    text: {
                                                                        fill: theme.palette.secondary[200],
                                                                    },
                                                                },
                                                                ticks: {
                                                                    line: {
                                                                        stroke: theme.palette.secondary[200],
                                                                        strokeWidth: 1,
                                                                    },
                                                                    text: {
                                                                        fill: theme.palette.secondary[200],
                                                                    },
                                                                },
                                                            },
                                                            legends: {
                                                                text: {
                                                                    fill: theme.palette.secondary[200],
                                                                },
                                                            },
                                                            tooltip: {
                                                                container: {
                                                                    color: theme.palette.primary.main,
                                                                },
                                                            },
                                                        }}
                                                        margin={{ top: 20, right: 50, bottom: 50, left: 70 }}
                                                        xScale={{ type: "point" }}
                                                        yScale={{
                                                            type: "band",
                                                            min: "auto",
                                                            max: "auto",
                                                            stacked: false,
                                                            reverse: false,
                                                        }}
                                                        yFormat=" >-.2f"
                                                        curve="catmullRom"
                                                        enableArea={true}
                                                        axisTop={null}
                                                        axisRight={null}
                                                        axisBottom={{
                                                            format: (v) => {
                                                                return v;

                                                            },
                                                            orient: "bottom",
                                                            tickSize: 5,
                                                            tickPadding: 5,
                                                            tickRotation: 0,
                                                            legend: "Month",
                                                            legendOffset: 36,
                                                            legendPosition: "middle",
                                                        }}
                                                        axisLeft={{
                                                            orient: "left",
                                                            tickValues: 5,
                                                            tickSize: 5,
                                                            tickPadding: 5,
                                                            tickRotation: 0,
                                                            legend: `Chest Expansion`,
                                                            legendOffset: -60,
                                                            legendPosition: "middle",
                                                        }}
                                                        enableGridX={false}
                                                        enableGridY={false}
                                                        pointSize={10}
                                                        pointColor={{ theme: "background" }}
                                                        pointBorderWidth={2}
                                                        pointBorderColor={{ from: "serieColor" }}
                                                        pointLabelYOffset={-12}
                                                        useMesh={true}
                                                        legends={

                                                            [
                                                                {
                                                                    anchor: "bottom-right",
                                                                    direction: "column",
                                                                    justify: false,
                                                                    translateX: -30,
                                                                    translateY: 75,
                                                                    itemsSpacing: 0,
                                                                    itemDirection: "left-to-right",
                                                                    itemWidth: 80,
                                                                    itemHeight: 60,
                                                                    itemOpacity: 0.75,
                                                                    symbolSize: 12,
                                                                    symbolShape: "circle",
                                                                    symbolBorderColor: "rgba(0, 0, 0, .5)",
                                                                    effects: [
                                                                        {
                                                                            on: "hover",
                                                                            style: {
                                                                                itemBackground: "rgba(0, 0, 0, .03)",
                                                                                itemOpacity: 1,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            ]

                                                        }
                                                    />
                                                }
                                            </div>
                                            <div className='flex w-full md:w-2/4 align-end justify-end p-2 mt-2 text-center '>
                                                <div className={` mr-2 p-4 mt-2 rounded-lg`} style={Boxtyle}>
                                                    {userFeedbacks.length > 0 && <h4 className='text-lg m-4'>{userFeedbacks.length > 1 ? userFeedbacks.at(userFeedbacks.length - 2).chestExpansion : ""}cm<br />Before</h4>}

                                                    {
                                                        showChest &&
                                                        <div>
                                                            {/* <label for="number" class="block mb-2 text-sm font-medium  ">Enter in centimeters</label>
                                                            <input onChange={(e) => { setChestExBefore(e.target.value) }} type="number" min={0} name="number" id="email" class="bg-gray-500 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-gray-500 border-gray-500 placeholder-gray-400 " placeholder="7 (cm)" required />
                                                            <Check mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={(e) => { addChestBefore() }} />
                                                            <Clear mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { setShowChest(false) }} /> */}

                                                        </div>
                                                    }
                                                    {/* {!showChest && <Edit sx={{ cursor: 'pointer' }} onClick={() => { setShowChest(true) }} />} */}
                                                </div>
                                                <div className={`ml-2  p-4 mt-2 rounded-lg`} style={Boxtyle}>
                                                    <h4 className='text-lg  m-4'>{chestExAfter}cm<br />After</h4>
                                                    {
                                                        showChestAfter &&
                                                        <div>
                                                            <label for="number" class="block mb-2 text-sm font-medium  ">Enter in centimeters</label>
                                                            <input onChange={(e) => { setChestExAfter(e.target.value) }} type="number" min={0} name="number" id="email" class="bg-gray-500 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-gray-500 border-gray-500 placeholder-gray-400 " placeholder="7 (cm)" required />
                                                            <Check mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={(e) => { handleUpdateFeedback() }} />
                                                            <Clear mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { setShowChestAfter(false) }} />

                                                        </div>
                                                    }
                                                    {!showChestAfter && <Edit sx={{ cursor: 'pointer' }} onClick={() => { setShowChestAfter(true) }} />}

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div className='p-2 mt-4'>
                                        <h4 className='text-xl font-medium text-[#ffe3a3]'>Functional Assessment<p className='text-lg'>{fa}</p></h4>
                                        <div className=' p-2 mt-2'>
                                            {showFA && <div className='w-full md:w-1/4'>

                                                <select onChange={(e) => { setFA(e.target.value) }} type="number" max={10} min={0} name="number" id="email" class="bg-gray-500 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-gray-500 border-gray-500 placeholder-gray-400 " placeholder="7" required>
                                                    <option>Functionally Independent</option>
                                                    <option>Functionally Dependent</option>
                                                </select>
                                                <div className='flex gap-2 align-middle justify-center '>
                                                    <Check mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { handleUpdateFeedback() }} />
                                                    <Clear mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { setShowFA(false) }} />
                                                </div>
                                            </div>
                                            }
                                            {!showFA && <Edit sx={{ cursor: 'pointer' }} onClick={() => { setShowFA(true) }} />}

                                        </div>
                                        <div className='w-full align-middle justify-center h-56'>
                                            {
                                                functionalGraph && <ResponsiveLine
                                                    data={functionalGraph}

                                                    theme={{
                                                        axis: {
                                                            domain: {
                                                                line: {
                                                                    stroke: theme.palette.secondary[200],
                                                                },
                                                            },
                                                            legend: {
                                                                text: {
                                                                    fill: theme.palette.secondary[200],
                                                                },
                                                            },
                                                            ticks: {
                                                                line: {
                                                                    stroke: theme.palette.secondary[200],
                                                                    strokeWidth: 1,
                                                                },
                                                                text: {
                                                                    fill: theme.palette.secondary[200],


                                                                },
                                                            },
                                                        },
                                                        legends: {
                                                            text: {
                                                                fill: theme.palette.secondary[200],
                                                            },
                                                        },
                                                        tooltip: {
                                                            container: {
                                                                color: theme.palette.primary.main,
                                                            },
                                                        },
                                                    }}
                                                    margin={{ top: 20, right: 50, bottom: 50, left: 70 }}
                                                    xScale={{ type: "point" }}
                                                    yScale={{
                                                        type: "point",
                                                        min: "auto",
                                                        max: "auto",

                                                        stacked: false,
                                                        reverse: false,
                                                        // domain:["Tightness Not Present", "Tightness Present"]
                                                    }}
                                                    yFormat={(value) => `${value}`}
                                                    curve="catmullRom"
                                                    enableArea={true}
                                                    axisTop={null}
                                                    axisRight={null}
                                                    axisBottom={{
                                                        format: (v) => {
                                                            return v;

                                                        },
                                                        orient: "bottom",
                                                        tickSize: 5,
                                                        tickPadding: 5,
                                                        tickRotation: 0,
                                                        legend: "Month",
                                                        legendOffset: 36,
                                                        legendPosition: "middle",
                                                    }}
                                                    axisLeft={{
                                                        orient: "left",

                                                        tickSize: 0,
                                                        tickPadding: 10,
                                                        tickRotation: 0,
                                                        ticksPosition: 10,
                                                        tickValues: "point",
                                                        legend: "Functional Assessment",
                                                        legendOffset: -60,
                                                        legendPosition: "middle",
                                                        format: (v) => {
                                                            return v === "Functionally Dependent" ? "F.D" : "F.I";

                                                        },
                                                    }}
                                                    enableGridX={false}
                                                    enableGridY={false}
                                                    pointSize={10}
                                                    pointColor={{ theme: "background" }}
                                                    pointBorderWidth={2}
                                                    pointBorderColor={{ from: "serieColor" }}
                                                    pointLabelYOffset={-12}
                                                    useMesh={true}
                                                    legends={

                                                        [
                                                            {
                                                                data: [{
                                                                    label: "F.D: Functionally Dependent"
                                                                }, {
                                                                    label: "F.I: Functionally Independent"
                                                                }],
                                                                anchor: "bottom-right",
                                                                direction: "column",
                                                                justify: false,
                                                                translateX: -30,
                                                                // padding:16,
                                                                translateY: 50,
                                                                itemsSpacing: 0,
                                                                itemDirection: "left-to-right",
                                                                itemWidth: 80,
                                                                itemHeight: 13,

                                                                itemOpacity: 1,
                                                                symbolSize: 12,
                                                                symbolShape: "circle",
                                                                symbolBorderColor: "rgba(0, 0, 0, .5)",
                                                                effects: [
                                                                    {
                                                                        on: "hover",
                                                                        style: {
                                                                            itemBackground: "rgba(0, 0, 0, .03)",
                                                                            itemOpacity: 1,
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        ]

                                                    }

                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full text-[#ffe3a3]">
                                    <div className='p-6'>
                                        <h4 className='text-xl font-medium text-[#ffe3a3]'>Sensory Examination:</h4>
                                        <div className='flex flex-col md:flex-row p-4 rounded-md  mt-4' style={graphbackstyle}>
                                            <div className='w-full md:w-2/4 h-56'>
                                                {
                                                    selectedSensory && <ResponsiveLine
                                                        data={selectedSensory}

                                                        theme={{
                                                            axis: {
                                                                domain: {
                                                                    line: {
                                                                        stroke: theme.palette.secondary[200],
                                                                    },
                                                                },
                                                                legend: {
                                                                    text: {
                                                                        fill: theme.palette.secondary[200],
                                                                    },
                                                                },
                                                                ticks: {
                                                                    line: {
                                                                        stroke: theme.palette.secondary[200],
                                                                        strokeWidth: 1,
                                                                    },
                                                                    text: {
                                                                        fill: theme.palette.secondary[200],


                                                                    },
                                                                },
                                                            },
                                                            legends: {
                                                                text: {
                                                                    fill: theme.palette.secondary[200],
                                                                },
                                                            },
                                                            tooltip: {
                                                                container: {
                                                                    color: theme.palette.primary.main,
                                                                },
                                                            },
                                                        }}
                                                        margin={{ top: 20, right: 50, bottom: 50, left: 70 }}
                                                        xScale={{ type: "point" }}
                                                        yScale={{
                                                            type: "point",
                                                            min: "auto",
                                                            max: "auto",

                                                            stacked: false,
                                                            reverse: false,
                                                            // domain:["Tightness Not Present", "Tightness Present"]
                                                        }}
                                                        yFormat={(value) => `${value}`}
                                                        curve="catmullRom"
                                                        enableArea={true}
                                                        axisTop={null}
                                                        axisRight={null}
                                                        axisBottom={{
                                                            format: (v) => {
                                                                return v;

                                                            },
                                                            orient: "bottom",
                                                            tickSize: 5,
                                                            tickPadding: 5,
                                                            tickRotation: 0,
                                                            legend: "Month",
                                                            legendOffset: 36,
                                                            legendPosition: "middle",
                                                        }}
                                                        axisLeft={{
                                                            orient: "left",

                                                            tickSize: 0,
                                                            tickPadding: 5,
                                                            tickRotation: -10,
                                                            tickValues: "point",
                                                            legend: `${selectedSensory === touchGraph ? "Touch" : selectedSensory === painGraph ? "Pain" : selectedSensory === tempGraph ? "Temperature" : "Pressure"}`,
                                                            legendOffset: -60,
                                                            legendPosition: "middle",
                                                        }}
                                                        enableGridX={false}
                                                        enableGridY={false}
                                                        pointSize={10}
                                                        pointColor={{ theme: "background" }}
                                                        pointBorderWidth={2}
                                                        pointBorderColor={{ from: "serieColor" }}
                                                        pointLabelYOffset={-12}
                                                        useMesh={true}

                                                    />
                                                }
                                            </div>
                                            <div className='grid grid-cols-1 md:grid-cols-2 gap-2 w-full md:w-2/4'>
                                                <div onClick={() => { setSelectedSensory(touchGraph) }} className={`ml-2 opacity-${selectedSensory === touchGraph ? 100 : 40} p-4 mt-2 rounded-lg`} style={Boxtyle}>
                                                    <h4 className='text-lg'>{touch}</h4>
                                                    <h4 className='text-md'>Touch</h4>
                                                    {showTouch && <div>

                                                        <select onChange={(e) => { setTouch(e.target.value) }} type="number" max={10} min={0} name="number" id="email" class="bg-gray-500 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-gray-500 border-gray-500 placeholder-gray-400 " placeholder="7" required>
                                                            <option>Intact</option>
                                                            <option>Affected</option>
                                                        </select>
                                                        <div className='flex gap-2 align-middle justify-center'>
                                                            <Check mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { handleUpdateFeedback() }} />
                                                            <Clear mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { setShowTouch(false) }} />
                                                        </div>
                                                    </div>
                                                    }
                                                    {!showTouch && <Edit sx={{ cursor: 'pointer' }} onClick={() => { setShowTouch(true) }} />}

                                                </div>
                                                <div onClick={() => { setSelectedSensory(painGraph) }} className={` ml-2 opacity-${selectedSensory === painGraph ? 100 : 40} p-4 mt-2 rounded-lg`} style={Boxtyle}>
                                                    <h4 className='text-lg'>{pain}</h4>
                                                    <h4 className='text-md'>Pain</h4>
                                                    {showPain && <div>

                                                        <select onChange={(e) => { setPain(e.target.value) }} type="number" max={10} min={0} name="number" id="email" class="bg-gray-500 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-gray-500 border-gray-500 placeholder-gray-400 " placeholder="7" required>
                                                            <option>Intact</option>
                                                            <option>Affected</option>
                                                        </select>
                                                        <div className='flex gap-2 align-middle justify-center'>
                                                            <Check mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { handleUpdateFeedback() }} />
                                                            <Clear mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { setShowPain(false) }} />
                                                        </div>
                                                    </div>
                                                    }
                                                    {!showPain && <Edit sx={{ cursor: 'pointer' }} onClick={() => { setShowPain(true) }} />}

                                                </div>

                                                <div onClick={() => { setSelectedSensory(tempGraph) }} className={` ml-2 opacity-${selectedSensory === tempGraph ? 100 : 40} p-4 mt-2 rounded-lg`} style={Boxtyle}>
                                                    <h4 className='text-lg'>{temparature}</h4>
                                                    <h4 className='text-md'>Temperature</h4>
                                                    {showTemp && <div>

                                                        <select onChange={(e) => { setTemp(e.target.value) }} type="number" max={10} min={0} name="number" id="email" class="bg-gray-500 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-gray-500 border-gray-500 placeholder-gray-400 " placeholder="7" required>
                                                            <option>Intact</option>
                                                            <option>Affected</option>
                                                        </select>
                                                        <div className='flex gap-2 align-middle justify-center'>
                                                            <Check mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { handleUpdateFeedback() }} />
                                                            <Clear mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { setShowTemp(false) }} />
                                                        </div>
                                                    </div>
                                                    }
                                                    {!showTemp && <Edit sx={{ cursor: 'pointer' }} onClick={() => { setShowTemp(true) }} />}

                                                </div>

                                                <div onClick={() => { setSelectedSensory(pressureGraph) }} className={` ml-2 opacity-${selectedSensory === pressureGraph ? 100 : 40} p-4 mt-2 rounded-lg`} style={Boxtyle}>
                                                    <h4 className='text-lg'>{pressure}</h4>
                                                    <h4 className='text-md'>Pressure</h4>
                                                    {showPressure && <div>

                                                        <select onChange={(e) => { setPressure(e.target.value) }} type="number" max={10} min={0} name="number" id="email" class="bg-gray-500 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-gray-500 border-gray-500 placeholder-gray-400 " placeholder="7" required>
                                                            <option>Intact</option>
                                                            <option>Affected</option>
                                                        </select>
                                                        <div className='flex gap-2 align-middle justify-center'>
                                                            <Check mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { handleUpdateFeedback() }} />
                                                            <Clear mt={2} sx={{ cursor: 'pointer', margin: '4px' }} onClick={() => { setShowPressure(false) }} />
                                                        </div>
                                                    </div>
                                                    }
                                                    {!showPressure && <Edit sx={{ cursor: 'pointer' }} onClick={() => { setShowPressure(true) }} />}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
                {
                    sessionFeedbacks.data && sessionFeedbacks.data.length > 0 && 
                    <div className=' text-center mb-8 p-8 rounded-lg mt-14' style={graphbackstyle}>
                    <h4 className='text-xl font-semibold'>Range Of Movement (ROM) </h4>

                    <div className=' flex flex-col-reverse align-center md:flex-row justify-center '>
                        {userFeedbacks.length > 0 && <div className='w-full md:w-1/4 align-center text-center'>
                            <h4 className="text-lg mt-4 font-medium mb-4">{selectedFeedback && selectedFeedback.rom} Degrees</h4>
                            {/* <h4 className="text-md mt-4 font-medium mb-4">Upper</h4>
                            <br />
                            <h4 className="text-lg mt-4 font-medium mb-4">130</h4>
                            <h4 className="text-md mt-4 font-medium mb-4">Lower</h4> */}
                            <input onChange={(e) => { setRomDeg(Number.parseInt(e.target.value)) }} type="number" min={0} className='w-full px-6 py-3 text-gray-600 text-md rounded-lg' placeholder='ROM in degrees' />
                            <button onClick={() => { handleUpdateFeedback() }} class="bg-blue-500 mt-4 hover:bg-blue-700  font-bold py-2 px-4 rounded text-white">
                                Update ROM
                            </button>
                            {/* <button onClick={handleCommentClick} class="bg-blue-500 hover:bg-blue-700  font-bold py-2 px-4 rounded">
                                Add Comment
                            </button> */}
                        </div>}
                        <div className='w-full md:w-3/4 h-56 bordered p-4'>
                            {/* <textarea key={phyisoTest.length > 0 && phyisoTest.filter((i) => i.test === "ROM Comment")[0] && phyisoTest.filter((i) => i.test == "ROM Comment")[0].score} onChange={(e) => { setComment(e.target.value) }} defaultValue={phyisoTest.length > 0 && phyisoTest.filter((i) => i.test === "ROM Comment")[0] && phyisoTest.filter((i) => i.test == "ROM Comment")[0].score} className='w-full h-full px-6 py-3 text-gray-600 text-md rounded-lg' placeholder='ROM Comments'>

                            </textarea> */}
                            {rom && <ResponsiveLine
                                data={rom}
                                theme={{
                                    axis: {
                                        domain: {
                                            line: {
                                                stroke: theme.palette.secondary[200],
                                            },
                                        },
                                        legend: {
                                            text: {
                                                fill: theme.palette.secondary[200],
                                            },
                                        },
                                        ticks: {
                                            line: {
                                                stroke: theme.palette.secondary[200],
                                                strokeWidth: 1,
                                            },
                                            text: {
                                                fill: theme.palette.secondary[200],
                                            },
                                        },
                                    },
                                    legends: {
                                        text: {
                                            fill: theme.palette.secondary[200],
                                            transform: "translateX(-10px)",

                                        },
                                    },
                                    tooltip: {
                                        container: {
                                            color: theme.palette.primary.main,
                                        },
                                    },
                                }}
                                margin={margin}
                                xScale={{ type: "point" }}
                                yScale={{
                                    type: "band",
                                    min: "auto",
                                    max: "auto",
                                    stacked: false,
                                    reverse: false,
                                }}
                                yFormat=" >-.2f"
                                curve="catmullRom"
                                enableArea={true}
                                axisTop={null}
                                axisRight={null}
                                axisBottom={{
                                    format: (v) => {
                                        return v;

                                    },
                                    orient: "bottom",
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: "Month",
                                    legendOffset: 36,
                                    legendPosition: "middle",
                                }}
                                axisLeft={{
                                    orient: "left",
                                    tickValues: 5,
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: `Range Of Movement (ROM)`,
                                    legendOffset: -60,
                                    legendPosition: "middle",
                                }}
                                enableGridX={false}
                                enableGridY={false}
                                pointSize={10}
                                pointColor={{ theme: "background" }}
                                pointBorderWidth={2}
                                pointBorderColor={{ from: "serieColor" }}
                                pointLabelYOffset={-12}
                                useMesh={true}
                                legends={

                                    [
                                        {
                                            anchor: "top-right",
                                            direction: "column",
                                            justify: false,
                                            translateX: 30,
                                            translateY: 0,
                                            itemsSpacing: 10,
                                            itemDirection: "left-to-right",
                                            itemWidth: 120,
                                            itemHeight: 15,
                                            itemOpacity: 0.75,
                                            symbolSpacing:15,
                                            symbolSize: 12,
                                            symbolShape: "circle",
                                            symbolBorderColor: "rgba(0, 0, 0, .5)",
                                            effects: [
                                                {
                                                    on: "hover",
                                                    style: {
                                                        itemBackground: "rgba(0, 0, 0, .03)",
                                                        itemOpacity: 1,
                                                    },
                                                },
                                            ],
                                        },
                                    ]

                                }
                            />}
                        </div>
                    </div>
                </div>
                }
                </div>
                {
                    userFeedbacks.length === 0 && <div >
                        <h4 className='text-md mt-12'>No Session Attended</h4>

                    </div>
                }
                <div className='w-full mb-8 mt-14'>

                </div>
                <div className='w-full mb-8 mt-14'>
                    <h1 className='mt-4 text-3xl font-bold'>Feedback</h1>
                    {
                        sessionFeedbacks.data && sessionFeedbacks.data.length > 0 && <div className='w-full flex flex-col md:flex-row'>

                            <Box mt={3} className="w-full m-4 md:w-2/4 h-56" >
                                <h1 className='mt-4 text-2xl font-bold'>Difficulty</h1>
                                {
                                    difficulty && <ResponsiveLine
                                        data={difficulty}
                                        theme={{
                                            axis: {
                                                domain: {
                                                    line: {
                                                        stroke: theme.palette.secondary[200],
                                                    },
                                                },
                                                legend: {
                                                    text: {
                                                        fill: theme.palette.secondary[200],
                                                    },
                                                },
                                                ticks: {
                                                    line: {
                                                        stroke: theme.palette.secondary[200],
                                                        strokeWidth: 1,
                                                    },
                                                    text: {
                                                        fill: theme.palette.secondary[200],
                                                    },
                                                },
                                            },
                                            legends: {
                                                text: {
                                                    fill: theme.palette.secondary[200],
                                                },
                                            },
                                            tooltip: {
                                                container: {
                                                    color: theme.palette.primary.main,
                                                },
                                            },
                                        }}
                                        margin={{ top: 20, right: 50, bottom: 50, left: 70 }}
                                        xScale={{ type: "point" }}
                                        yScale={{
                                            type: "band",
                                            min: "auto",
                                            max: "auto",
                                            stacked: false,
                                            reverse: false,
                                        }}
                                        yFormat=" >-.2f"
                                        curve="catmullRom"
                                        enableArea={true}
                                        axisTop={null}
                                        axisRight={null}
                                        axisBottom={{
                                            format: (v) => {
                                                return v;

                                            },
                                            orient: "bottom",
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: "Month",
                                            legendOffset: 36,
                                            legendPosition: "middle",
                                        }}
                                        axisLeft={{
                                            orient: "left",
                                            tickValues: 5,
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: `Difficulty`,
                                            legendOffset: -60,
                                            legendPosition: "middle",
                                        }}
                                        enableGridX={false}
                                        enableGridY={false}
                                        pointSize={10}
                                        pointColor={{ theme: "background" }}
                                        pointBorderWidth={2}
                                        pointBorderColor={{ from: "serieColor" }}
                                        pointLabelYOffset={-12}
                                        useMesh={true}
                                        legends={

                                            [
                                                {
                                                    anchor: "bottom-right",
                                                    direction: "column",
                                                    justify: false,
                                                    translateX: 30,
                                                    translateY: -40,
                                                    itemsSpacing: 0,
                                                    itemDirection: "left-to-right",
                                                    itemWidth: 80,
                                                    itemHeight: 20,
                                                    itemOpacity: 0.75,
                                                    symbolSize: 12,
                                                    symbolShape: "circle",
                                                    symbolBorderColor: "rgba(0, 0, 0, .5)",
                                                    effects: [
                                                        {
                                                            on: "hover",
                                                            style: {
                                                                itemBackground: "rgba(0, 0, 0, .03)",
                                                                itemOpacity: 1,
                                                            },
                                                        },
                                                    ],
                                                },
                                            ]

                                        }
                                    />
                                }
                            </Box>

                            <Box className="w-full m-4 md:w-2/4 h-56" mt={3} >
                                <h1 className='mt-4 text-2xl font-bold'>Pain Perception</h1>
                                {
                                    painPerception && <ResponsiveLine
                                        data={painPerception}
                                        theme={{
                                            axis: {
                                                domain: {
                                                    line: {
                                                        stroke: theme.palette.secondary[200],
                                                    },
                                                },
                                                legend: {
                                                    text: {
                                                        fill: theme.palette.secondary[200],
                                                    },
                                                },
                                                ticks: {
                                                    line: {
                                                        stroke: theme.palette.secondary[200],
                                                        strokeWidth: 1,
                                                    },
                                                    text: {
                                                        fill: theme.palette.secondary[200],
                                                    },
                                                },
                                            },
                                            legends: {
                                                text: {
                                                    fill: theme.palette.secondary[200],
                                                },
                                            },
                                            tooltip: {
                                                container: {
                                                    color: theme.palette.primary.main,
                                                },
                                            },
                                        }}
                                        margin={{ top: 20, right: 50, bottom: 50, left: 70 }}
                                        xScale={{ type: "point" }}
                                        yScale={{
                                            type: "band",
                                            min: "auto",
                                            max: "auto",
                                            stacked: false,
                                            reverse: false,
                                        }}
                                        yFormat=" >-.2f"
                                        curve="catmullRom"
                                        enableArea={true}
                                        axisTop={null}
                                        axisRight={null}
                                        axisBottom={{
                                            format: (v) => {
                                                return v;

                                            },
                                            orient: "bottom",
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: "Month",
                                            legendOffset: 36,
                                            legendPosition: "middle",
                                        }}
                                        axisLeft={{
                                            orient: "left",
                                            tickValues: 5,
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: `Pain Perception`,
                                            legendOffset: -60,
                                            legendPosition: "middle",
                                        }}
                                        enableGridX={false}
                                        enableGridY={false}
                                        pointSize={10}
                                        pointColor={{ theme: "background" }}
                                        pointBorderWidth={2}
                                        pointBorderColor={{ from: "serieColor" }}
                                        pointLabelYOffset={-12}
                                        useMesh={true}
                                        legends={

                                            [
                                                {
                                                    anchor: "bottom-right",
                                                    direction: "column",
                                                    justify: false,
                                                    translateX: 30,
                                                    translateY: -40,
                                                    itemsSpacing: 0,
                                                    itemDirection: "left-to-right",
                                                    itemWidth: 80,
                                                    itemHeight: 20,
                                                    itemOpacity: 0.75,
                                                    symbolSize: 12,
                                                    symbolShape: "circle",
                                                    symbolBorderColor: "rgba(0, 0, 0, .5)",
                                                    effects: [
                                                        {
                                                            on: "hover",
                                                            style: {
                                                                itemBackground: "rgba(0, 0, 0, .03)",
                                                                itemOpacity: 1,
                                                            },
                                                        },
                                                    ],
                                                },
                                            ]

                                        }
                                    />
                                }

                            </Box>
                            {/* <h1 className='mt-4 text-2xl font-bold'>Range Of Movement</h1>
                    <Box mt={3} height="75vh" padding={4}>
                        {
                            rom && <ResponsiveLine
                                data={rom}
                                theme={{
                                    axis: {
                                        domain: {
                                            line: {
                                                stroke: theme.palette.secondary[200],
                                            },
                                        },
                                        legend: {
                                            text: {
                                                fill: theme.palette.secondary[200],
                                            },
                                        },
                                        ticks: {
                                            line: {
                                                stroke: theme.palette.secondary[200],
                                                strokeWidth: 1,
                                            },
                                            text: {
                                                fill: theme.palette.secondary[200],
                                            },
                                        },
                                    },
                                    legends: {
                                        text: {
                                            fill: theme.palette.secondary[200],
                                        },
                                    },
                                    tooltip: {
                                        container: {
                                            color: theme.palette.primary.main,
                                        },
                                    },
                                }}
                                margin={{ top: 20, right: 50, bottom: 50, left: 70 }}
                                xScale={{ type: "point" }}
                                yScale={{
                                    type: "band",
                                    min: "auto",
                                    max: "auto",
                                    stacked: false,
                                    reverse: false,
                                }}
                                yFormat=" >-.2f"
                                curve="catmullRom"
                                enableArea={true}
                                axisTop={null}
                                axisRight={null}
                                axisBottom={{
                                    format: (v) => {
                                        return v;

                                    },
                                    orient: "bottom",
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: "Month",
                                    legendOffset: 36,
                                    legendPosition: "middle",
                                }}
                                axisLeft={{
                                    orient: "left",
                                    tickValues: 5,
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: `Range Of Movement (ROM)`,
                                    legendOffset: -60,
                                    legendPosition: "middle",
                                }}
                                enableGridX={false}
                                enableGridY={false}
                                pointSize={10}
                                pointColor={{ theme: "background" }}
                                pointBorderWidth={2}
                                pointBorderColor={{ from: "serieColor" }}
                                pointLabelYOffset={-12}
                                useMesh={true}
                                legends={

                                    [
                                        {
                                            anchor: "bottom-right",
                                            direction: "column",
                                            justify: false,
                                            translateX: 30,
                                            translateY: -40,
                                            itemsSpacing: 0,
                                            itemDirection: "left-to-right",
                                            itemWidth: 80,
                                            itemHeight: 20,
                                            itemOpacity: 0.75,
                                            symbolSize: 12,
                                            symbolShape: "circle",
                                            symbolBorderColor: "rgba(0, 0, 0, .5)",
                                            effects: [
                                                {
                                                    on: "hover",
                                                    style: {
                                                        itemBackground: "rgba(0, 0, 0, .03)",
                                                        itemOpacity: 1,
                                                    },
                                                },
                                            ],
                                        },
                                    ]

                                }
                            />
                        }
                    </Box> */}
                        </div>
                    }
                    {
                        sessionFeedbacks.data && sessionFeedbacks.length === 0 && <div >
                            <h4 className='text-md mt-12'>No Feedback</h4>
                            {/* <Button variant="contained" sx={{mt:"4px"}} className='mt-4'>Add Physio Session Record</Button> */}
                        </div>
                    }
                </div>
            </div>
            <div className="w-full md:w-1/4 sticky r-0" style={graphbackstyle}>

                {
                    data && <div className="p-4 flex justify-center flex-col mx-4">
                        <div className="bg-blue-600 shadow rounded-lg p-2 w-full mb-2 text-center flex items-center">
                            {/* <h2 className="text-xl font-bold mb-4">Last Complet Session</h2> */}
                            <Info/>
                            {sessionFeedbacks.data ? (
                                <p className="text-white text-xs w-full font-bold ">
                                   Completed last session on {moment(sessionFeedbacks.data[sessionFeedbacks.data.length-1].createdAt).format("DD MMMM YYYY")}.
                                </p>
                            ) : (
                                <p className="text-white text-xs w-full font-bold">No sessions completed yet.</p>
                            )}
                        </div>
                        <div className=''><img className='w-full rounded-md' src={data.photo ? data.photo : ''}></img></div>
                        <div className='block mt-4'>
                            <h1 className="text-xl font-bold mb-4">{data.name}</h1>
                            <a href={`tel:${mobile}`}><h4 className="text-sm font-medium mb-4 cursor-pointer underline">{mobile}</h4></a>
                        </div>
                        <p className="mb-2 text-[18px] font-medium pt-6 opacity-50">Details</p>
                        <hr />
                        <div className="grid grid-cols-2 gap-4 center mt-4">
                            <h5 className="mb-2 text-md">Age</h5>
                            <h5 className="mb-2 text-md font-bold text-right">{data.age}</h5>
                            <h5 className="mb-2 text-md">Height({data.heightMeasure})</h5>
                            <h5 className="mb-2 text-md font-bold text-right">{data.height}</h5>
                            <h5 className="mb-2 text-md">Weight({data.weightMeasure})</h5>
                            <h5 className="mb-2 text-md font-bold text-right">{data.weight}</h5>
                            <h5 className="mb-2 text-md">BMI</h5>
                            <h5 className="mb-2 text-md font-bold text-right">{data.weightMeasure === "kg" ? data.heightMeasure === "cm" ? (data.weight / ((data.height / 100) * (data.height / 100))).toFixed(2) : (data.weight / (data.height * 0.3048)).toFixed(2) : data.heightMeasure === "cm" ? ((data.weight * 0.45359237) / ((data.height / 100) * (data.height / 100))).toFixed(2) : ((data.weight * 0.45359237) / (data.height * 0.3048)).toFixed(2)}</h5>
                            <h5 className="mb-2 text-md">Lifestyle</h5>
                            <h5 className="mb-2 text-md font-bold text-right">{data.natureOfWork.replace(/_/g, " ").replace(/\b\w/g, function (txt) {
                                return txt.toUpperCase()
                            })}</h5>
                            <h5 className="mb-2 text-md capitalize">Gender</h5>
                            <h5 className="mb-2 text-md capitalize font-bold text-right">{data.gender}</h5>
                        </div>

                        <h4 className="text-lg mt-8 font-medium opacity-40">Medical History</h4>
                        <hr />
                        <div className="grid grid-cols-2 gap-4 center mt-8">
                            <h5 className="mb-2 text-md col-span">Comorbidities</h5>
                            <div className=' col-span-1'>    <h5 className="mb-2 capitalize text-md font-bold text-right">{data.comorbidities && data.comorbidities.join(",")}</h5></div>
                            <h5 className="mb-2 text-md">SPO2</h5>
                            <h5 className="mb-2 text-md font-bold text-right">{data.spo2}</h5>
                            <h5 className="mb-2 text-md">B.P</h5>
                            <h5 className="mb-2 text-md font-bold text-right">{data.bp}</h5>
                            <h5 className="mb-2 text-md">Heart Rate</h5>
                            <h5 className="mb-2 text-md font-bold text-right">{data.heartRate}</h5>
                            <h5 className="mb-2 text-md">Pulse</h5>
                            <h5 className="mb-2 text-md font-bold text-right">{data.pulse}</h5>

                        </div>
                        <h4 className="text-lg mt-4 font-medium opacity-40">Medical Records</h4>
                        <hr />
                        {
                            medicalRecords.data && medicalRecords.data.length > 0 && medicalRecords.data.map((m) => {
                                return <div className='p-2 mt-2' onClick={() => { navigate(`/patient/medical-record?record=${m._id}`) }}>
                                    <img src={m.document}></img>
                                    <h6 className='mt-2'>{m.name}</h6>
                                </div>
                            })
                        }
                        <button onClick={() => { navigate(`/patient/add-record?patient=${patient}`) }} className="bg-blue-500 text-white py-0.5 px-0.5 mt-5  rounded-lg hover:bg-blue-600 transition-colors duration-300 ease-in-out">Add Medical Record</button>
                        <h4 className="text-lg mt-8 font-medium opacity-40">Referred Partner Details</h4>
                        <hr />
                        {
                            userData && <ul className='mt-4'>
                                <li><h4 className="text-md font-medium mb-2"><strong>{userData.userData[0].name}</strong></h4></li>
                                <li><h4 className="text-md font-medium mb-4">{userData.userData[0].mobile}</h4></li>
                            </ul>
                        }
                        <button onClick={() => { navigate(`/patient/profile?patient=${patient}&mobile=${mobile}&name=${data.name}`) }} className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition-colors duration-300 ease-in-out">Edit Profile</button>
                    </div>
                }
            </div>
        </div>

    )
}