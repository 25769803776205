import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Button,
  Typography,
  Rating,
  useTheme,
  useMediaQuery,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useGetProductsQuery } from "state/api";
import Header from "components/Header";
import FlexBetween from "components/FlexBetween";

const Product = ({
  _id,
  name,
  painPoint,
  multiWeeksPlan,
  exercisesCount,
  thumbnail,
  numberOfWeeks,
  subscriptions,
}) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Card
      sx={{
        backgroundImage: "none",
        backgroundColor: theme.palette.background.alt,
        borderRadius: "0.55rem",
      }}
    >
      <CardContent>
        <div className="flex justify-center items-center">
          <img
            alt="profile"
            src={thumbnail}
            className="object-cover rounded-lg h-[250px] mb-2"
          />
        </div>
        <Typography
          sx={{
            fontSize: 14,
            textTransform: "capitalize",
            color:
              theme.palette.mode === "light"
                ? theme.palette.secondary.main
                : theme.palette.secondary[700],
          }}
          gutterBottom
        >
          Pain Point: <strong>{painPoint.join(", ")}</strong>
        </Typography>
        <Typography variant="h5" component="div">
          {name}
        </Typography>
        <Typography
          sx={{
            color:
              theme.palette.mode === "light"
                ? theme.palette.secondary[200]
                : theme.palette.secondary[400],
          }}
        >
          Exercises in Plan : {exercisesCount}
        </Typography>
        <Typography sx={{ mb: "1.5rem" }} color={theme.palette.secondary[400]}>
          Active Subscriptions :{subscriptions}
        </Typography>
        {/* <Rating value={4} readOnly /> */}
        <Typography></Typography>
        <Typography variant="body">Number of Weeks: {numberOfWeeks}</Typography>
      </CardContent>
    </Card>
  );
};

const Products = () => {
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const theme = useTheme();
  const [view, setView] = useState(30);
  const { data, isLoading } = useGetProductsQuery(view);
  const [department, setDepartment] = useState("orthopedic");
  const [painPoint, setPainPoint] = useState("lowerBack");
  return (
    <Box m="1.5rem 2.5rem">
      <Header title="PRODUCTS" subtitle="See all your product plans here!" />
      <div className="flex flex-col md:flex-row items-center">
        {" "}
        {/* Modified className */}
        <FormControl
          sx={{
            mt: "1rem",
            minWidth: 120,
            marginRight: isNonMobile ? "2rem" : 0,
          }}
        >
          {" "}
          {/* Modified sx */}
          <InputLabel>Days</InputLabel>
          <Select
            value={view}
            label="Days"
            onChange={(e) => setView(e.target.value)}
          >
            <MenuItem value={30}>30</MenuItem>
            <MenuItem value={90}>90</MenuItem>
            <MenuItem value={365}>365</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          sx={{
            minWidth: 120,
            marginRight: isNonMobile ? "2rem" : 0,
            marginTop: isNonMobile ? "18px" : "1rem",
          }}
        >
          {" "}
          {/* Modified sx */}
          <InputLabel>Department</InputLabel>
          <Select
            value={department}
            label="Department"
            onChange={(e) => setDepartment(e.target.value)}
          >
            <MenuItem value={"orthopedic"}>Ortho</MenuItem>
            <MenuItem value={"sports_injury"}>Sports</MenuItem>
            <MenuItem value={"gynaecology"}>Gynae</MenuItem>
            <MenuItem value={"pcod"}>PCOD</MenuItem>
          </Select>
        </FormControl>
        {(department === "orthopedic" || department === "sports_injury") && (
          <div className="flex items-center flex-col md:flex-row">
            <InputLabel
              sx={{
                marginLeft: isNonMobile ? "8px" : "0px",
                marginTop: isNonMobile ? "0px" : "1rem",
              }}
            >
              Pain Point
            </InputLabel>
            <Select
              sx={{ marginLeft: isNonMobile ? "8px" : "0px" }}
              value={painPoint}
              label="Pain Point"
              onChange={(e) => setPainPoint(e.target.value)}
            >
              <MenuItem value={"lowerBack"}>Lowerback</MenuItem>
              <MenuItem value={"upperBack"}>Upperback</MenuItem>
              <MenuItem value={"shoulder"}>Shoulder</MenuItem>
              <MenuItem value={"knee"}>Knee</MenuItem>
              <MenuItem value={"hip"}>Hip</MenuItem>
              <MenuItem value={"neck"}>Neck</MenuItem>
              <MenuItem value={"ankle"}>Ankle</MenuItem>
              <MenuItem value={"elbow"}>Elbow</MenuItem>
              <MenuItem value={"wristAndHand"}>Wrist and Hand</MenuItem>
            </Select>
          </div>
        )}
      </div>
      {data || !isLoading ? (
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          justifyContent="space-between"
          rowGap="20px"
          columnGap="1.33%"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          {data
            .filter((p, i) =>
              department === "orthopedic" || department === "sports_injury"
                ? p.product.department === department &&
                  p.product.painPoint.includes(painPoint)
                : p.product.department === department
            )
            .map(({ product, exerciseCount, subscriptionCount }) => (
              <Product
                _id={product._id}
                name={product.name}
                painPoint={product.painPoint}
                multiWeeksPlan={[]}
                numberOfWeeks={
                  product.days < 31 ? 4 : product.days === 90 ? 12 : 52
                }
                exercisesCount={exerciseCount}
                thumbnail={product.thumbnail}
                subscriptions={subscriptionCount}
              />
            ))}
        </Box>
      ) : (
        <>Loading...</>
      )}
    </Box>
  );
};

export default Products;
