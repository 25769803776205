import * as React from 'react';
import { useState } from 'react';
import Container from '@mui/material/Container';
import { Box, Button, CircularProgress, MenuItem, Select, TextField, Typography,Fab,useTheme } from '@mui/material';
import PhoneInput from 'react-phone-number-input';
import logofulldark from "assets/logofull.svg"
import { useCreateAccountMutation, useCreateTeamMemberMutation } from 'state/api';
import { useOutletContext } from 'react-router-dom';
import SelectInput from '@mui/material/Select/SelectInput';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export default function AddMember_admin() {

    const [userData] = useOutletContext()
    const [progress, setProgress] = useState(false)
    const [error, setError] = useState(false)
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const navigate = useNavigate();
    const specialisation = [
        {
          "A": "1",
          "B": "General Medicine",
          "C": "Specialization in General Medicine (more than covers the\n entire body excluding maxo-facial and eye)"
        },
        {
          "A": "2",
          "B": "General Surgery",
          "C": "Specialization in General Surgery"
        },
        {
          "A": "3",
          "B": "Otorhinolaryngology",
          "C": "Specialization dealing with the surgical and medical\n management of conditions of the head and neck"
        },
        {
          "A": "4",
          "B": "Internal Medicine",
          "C": "Similar to general medicine, with the difference that this only caters to adult patients only. Specialization in\n prevention, diagnosis, and treatment of internal diseases"
        },
        {
          "A": "5",
          "B": "Immunology",
          "C": "Specialization in immune systems in the body and antigen-\n antibody allergic reactions"
        },
        {
          "A": "6",
          "B": "Critical Care Medicine",
          "C": "Specialization in critically ill patients with life-threatening\n conditions"
        },
        {
          "A": "7",
          "B": "Infectious Diseases",
          "C": "Specialization in diagnosis and treatment of complex\n infections."
        },
        {
          "A": "8",
          "B": "Clinical Pharmocology",
          "C": "Specialization in science of drugs and therapeutics (their\n composition and uses and effects)"
        },
        {
          "A": "9",
          "B": "Anaesthesiology",
          "C": "Specialization in applying anaesthetics"
        },
        {
          "A": "10",
          "B": "Radiology",
          "C": "Specialization in usage medical imaging to diagnose and\n treat diseases within the bodies"
        },
        {
          "A": "11",
          "B": "Sonology",
          "C": "Specialization in practice of medical ultrasonography to diagnose diseases and conditions."
        },
        {
          "A": "12",
          "B": "Biochemistry",
          "C": "Specialization in the structure, composition, and chemical reactions of substances in living systems."
        },
        {
          "A": "13",
          "B": "Virology",
          "C": "Specialization in viruses and viral diseases"
        },
        {
          "A": "14",
          "B": "Bacteriology",
          "C": "Study of bacteria"
        },
        {
          "A": "15",
          "B": "Oncology",
          "C": "Specialization in treatment of tumours and cancer"
        },
        {
          "A": "16",
          "B": "Teratology",
          "C": "Specialization in human congenital abnormalities"
        },
        {
          "A": "17",
          "B": "Geriatric medicine",
          "C": "Specialization in health care of elderly people.["
        },
        {
          "A": "18",
          "B": "Epidemiology",
          "C": "Specialization in transmission and control of epidemic\n disease"
        },
        {
          "A": "19",
          "B": "Communicative Diseases",
          "C": "Specialization in communicative and contagious diseases"
        },
        {
          "A": "20",
          "B": "Dermatology",
          "C": "Specialization in the skin and its diseases"
        },
        {
          "A": "21",
          "B": "Trichology",
          "C": "Specialization in the diseases of hair and scalp"
        },
        {
          "A": "22",
          "B": "Psychiatry",
          "C": "Specialization in the diagnosis and treatment of\n mental disorders"
        },
        {
          "A": "23",
          "B": "Neurology",
          "C": "Specialization in the nervous system - its diseases and\n treatment"
        },
        {
          "A": "24",
          "B": "Otolaryngology (ENT)",
          "C": "Specialization in ear, nose and throat"
        },
        {
          "A": "25",
          "B": "Otology",
          "C": "Specialization in ear and hearing"
        },
        {
          "A": "26",
          "B": "Rhinology",
          "C": "Specialization in anatomy, physiology and diseases of the nose and the para-nasal sinuses"
        },
        {
          "A": "27",
          "B": "Ophthalmology",
          "C": "Specialization in the eye and its diseases"
        },
        {
          "A": "28",
          "B": "Cardiology",
          "C": "Specialization in the heart and its diseases"
        },
        {
          "A": "29",
          "B": "Pulmonology",
          "C": "Specialization in diseases of the lungs and respiratory tract"
        },
        {
          "A": "30",
          "B": "Angiology",
          "C": "Specialization in diseases of the circulatory system and of\n the lymphatic system"
        },
        {
          "A": "31",
          "B": "Haematology",
          "C": "Specialization in diseases of the blood and blood-forming\n organs"
        },
        {
          "A": "32",
          "B": "Endocrinology",
          "C": "Specialization in dealing with the endocrine system, its\n diseases, and its specific secretions known as hormones."
        },
        {
          "A": "33",
          "B": "Gastroenterology",
          "C": "Specialization in the gastrointestinal tract and its diseases, oesophagus, stomach, small intestine, colon and rectum,\n pancreas, gallbladder, bile ducts and liver."
        },
        {
          "A": "34",
          "B": "Hepatology",
          "C": "Specialization in liver, gallbladder, biliary tree,\n and pancreas as well as management of their disorders."
        },
        {
          "A": "35",
          "B": "Diabetology",
          "C": "Specialization in diagnosis and treatment of diabetes."
        },
        {
          "A": "36",
          "B": "Pediatrics",
          "C": "Specialization in the treatment of infants and children"
        },
        {
          "A": "37",
          "B": "Obstetrics",
          "C": "Specialization in childbirth and care of the mother"
        },
        {
          "A": "38",
          "B": "Sexology/Venereology",
          "C": "Specialization in human sexuality - its disorders and\n treatment and treatment of sexually transmitted diseases."
        },
        {
          "A": "39",
          "B": "Gynecology",
          "C": "Specialization in the diseases and hygiene and\n reproductive system of women"
        },
        {
          "A": "40",
          "B": "Andrology",
          "C": "Specialization in male reproductive organs"
        },
        {
          "A": "41",
          "B": "Nephrology",
          "C": "Specialization in kidney - its development and anatomy\n and physiology and disorders"
        },
        {
          "A": "42",
          "B": "Urology",
          "C": "Specialization in urinary-tract system"
        },
        {
          "A": "43",
          "B": "Osteopathy/ Orthopedy",
          "C": "Specialization in disorders or deformities of the spine and\n joints and bones"
        },
        {
          "A": "44",
          "B": "Rheumatology",
          "C": "Specialiation in diagnosis (detection) and treatment of\n musculoskeletal disease"
        },
        {
          "A": "45",
          "B": "Arthrology",
          "C": "Specialization in joints"
        },
        {
          "A": "46",
          "B": "Syndesmology",
          "C": "Specialization in joints and ligaments"
        },
        {
          "A": "47",
          "B": "Myology/ Sarcology",
          "C": "Specialization in muscles' related ailments"
        },
        {
          "A": "47",
          "B": "Sports Medicine",
          "C": "Specialization in Sports related injuries and treatment"
        }
      ]
    const [success, setSuccess] = useState(false)
    const [message, setMessage] = useState()
    const [phoneNumber, setPhoneNumber] = useState('');
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [role, setRole] = useState('editor')
    const [createAccount, response] = useCreateAccountMutation();
    const [createTeamMember] = useCreateTeamMemberMutation()
    const [selectedSpec, setSelectedSpec] = useState('General Medicine')
    const handleAddPatient = () =>{
        setProgress(true)
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;
       
        if (name.trim().length === 0) {
            setError(true)
            setProgress(false)
            setMessage('Name is required')
            setTimeOut()
            return
        } else if (email.trim().length > 0 && !email.match(validRegex)) {
            setError(true)
            setProgress(false)
            setMessage('Invalid email address')
            setTimeOut()
            return
        }else if (phoneNumber.trim().length === 0) {
            setError(true)
            setProgress(false)
            setMessage('Invalid phone number')
            setTimeOut()
            return
        } else {

           
            const account = { name: name, mobile: phoneNumber, role: 'Team_Member', email: email, referralCode: userData.userData[0].userReferralCode.code }
            createAccount(account).unwrap().then((d)=>{
              const team = {account: d._id,specialisation: selectedSpec, name: name, partner: userData.userData[0]._id, mobile: phoneNumber, role: role, status: "active" }
                createTeamMember(team).unwrap().then((d)=>{
                    setSuccess(true)
                    setProgress(false)
                    setTimeOut()
                    window.location.replace('/team')
                }).catch(e=>{
                    setError(true)
                    setProgress(false)
                    setMessage(e.data.message)
                    setTimeOut()
                })
            }).catch(e=>{
                console.log(e)
                setProgress(false)
                setError(true)
                setMessage(e.data.message)
                setTimeOut()
                
            })
        }
    }
    const setTimeOut = () => {
        setTimeout(()=>{
            setError(false)
            setSuccess(false)
        },2000)
    }
    return (
        <Box sx={{
            display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start', // Change justifyContent to flex-start
    flexDirection: 'column', // Center items vertically
    minHeight: '100vh',
    padding: '16px',
    position: 'relative', // Add relative positioning to the parent container


        }}>
        <div className='flex flex-col md:flex-row'>
                    <Fab
                        color="primary"
                        aria-label="back"
                        size="small"
                        sx={{
                            backgroundColor: isDarkMode ? theme.palette.primary.light : theme.palette.secondary.light,
                            marginLeft: "8px",
                            position: 'absolute', // Position the button absolutely
            top: '16px', // Distance from top
            left: '16px' // Distance from left
                        }}
                        onClick={() => { navigate(-1); }} // Navigate back to the previous screen
                    >
                        <ArrowBack />
                    </Fab>
                </div>
            <Container sx={{
                color: 'black',
                boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                borderRadius: '16px',
                padding: '16px',
                bgcolor: 'white',
                width: '500px',
                margin: '12px'
            }}>
                {/* <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <img src={logofulldark} alt="App Logo" style={{ height: '64px' }} />

                </Box> */}

                <Typography align='center' fontWeight={700} variant="h5" sx={{ mt: 2 }}>
                   Add New Patient
                </Typography>
                {
                success && <Box display="flex" borderRadius={2} mt={2} padding="8px" bgcolor="#d7ffe8" justifyContent="center" alignItems="center"><Typography align='center' variant='h6' color="#01b850">Added new member successfully!</Typography></Box>
            }
                {
                     <Box display='flex' flexDirection='column' sx={{ mt: 6 }}>
                        <Typography variant="h6" fontWeight={600}>
                            Name
                        </Typography>
                        <TextField
                            onChange={(e)=>{setName(e.target.value)}}
                            variant="standard"
                            fullWidth
                            placeholder='Enter member name'
                            focused={false}
                            required
                            inputProps={{

                                style: { color: 'black', fontSize: '16px', border: 'solid black', padding: '12px' }
                            }}
                        />

                        <Typography mt={2} variant="h6" fontWeight={600}>
                            Email
                        </Typography>
                        <TextField
                            variant="standard"
                            placeholder='Enter member email (optional)'
                            fullWidth
                            onChange={(e)=>{setEmail(e.target.value)}}
                            focused={false}
                            type="email"
                            inputProps={{

                                style: { color: 'black', fontSize: '16px', border: 'solid black', padding: '12px' }
                            }}
                        />

                        <Typography mt={2} variant="h6" fontWeight={600}>
                            Phone Number
                        </Typography>

                        <PhoneInput

                            style={{ padding: '12px', border: 'solid', marginTop: '8px' }}
                            className={"input-phone-number"}
                            placeholder="Enter member phone number"
                            value={phoneNumber}
                            onChange={setPhoneNumber}
                            defaultCountry="IN"
                            international
                            countryCallingCodeEditable={false}
                            inputProps={{

                                required: true,
                                autoFocus: true,


                            }}
                        />
                    <Typography mt={2} variant="h6" fontWeight={600}>
                            Role
                        </Typography>
                        <Select
                                value={role}
                                label="Role"
                                 
                                 sx={{color:"black"}}
                                onChange={(e) => setRole(e.target.value)}
                            >
                                <MenuItem  value={'editor'}>Editor</MenuItem>
                                {/* <MenuItem value={'viewer'}>Viewer</MenuItem> */}
                                <MenuItem value={'admin'}>Admin</MenuItem>
                            </Select>

                            <Typography mt={2} variant="h6" fontWeight={600}>
                            Specialization
                        </Typography>
                        <Select
                                value={selectedSpec}
                                label="Specialization"
                                 
                                 sx={{color:"black"}}
                                onChange={(e) => setSelectedSpec(e.target.value)}
                            >
                               {
                                specialisation.map((s,i)=>{
                                    return  <MenuItem key={i} value={s.B}>{s.B}</MenuItem>
                                })
                               }
                                {/* <MenuItem value={'viewer'}>Viewer</MenuItem> */}
                                
                            </Select>

                    </Box>
                    

                }
                
                {
                   error &&  <Box display="flex" borderRadius={2} mt={2} padding="8px" bgcolor="#ff9999" justifyContent="center" alignItems="center"><Typography align='center' variant='h6' color="#eb0a0a">{message}</Typography></Box>
                }
                { (

                    <Box sx={{ mt: 3 }}>
                        <div style={{ margin: '8px' }} id='recaptcha-container'></div>
                        { !progress && <Button id="signup-btn"  variant="contained" onClick={handleAddPatient} sx={{ width: '100%', padding: '12px', backgroundColor: "#006fa4" }}>
                            Add Member
                        </Button>}

                    </Box>
                )}

                {
                   progress &&  <Box display="flex" justifyContent="center" alignItems="center"><CircularProgress sx={{ width: '100%', alignSelf: 'center' }} /></Box>
                }

                {/* <Box sx={{ mt: 3 }}>
                    <Typography align='center' variant="h6">
                        Already have an account? <a href='/signin'><Typography color="#006fa4" variant='h5' style={{ cursor: 'pointer' }} fontWeight={600}>Sign In</Typography></a>
                    </Typography>
                </Box> */}
            </Container>
        </Box>
    )
}