import React from "react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import {
  SettingsOutlined,
  ChevronLeft,
  ChevronRightOutlined,
  HomeOutlined,
  ShoppingCartOutlined,
  Groups2Outlined,
  ReceiptLongOutlined,
  PublicOutlined,
  PointOfSaleOutlined,
  TodayOutlined,
  CalendarMonthOutlined,
  AdminPanelSettingsOutlined,
  TrendingUpOutlined,
  PieChartOutlined,
  Event,
  Subscriptions,
  Groups,
  AccountCircle,
  Receipt,
  TransferWithinAStation,
  Transcribe,
  NoteOutlined,
  DirectionsWalk,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import FlexBetween from "./FlexBetween";
import profileImage from "assets/profileImage.png";
import { useGetActiveSubscriptionQuery } from "state/api";


const navItems = [
  {
    text: "Dashboard",
    icon: <HomeOutlined />,
  },
  {
    text: "Client Facing",
    icon: null,
  },
  {
    text: "Products",
    icon: <ShoppingCartOutlined />,
  },
  {
    text: "Patients",
    icon: <Groups2Outlined />,
  },
  {
    text: "Patients-Tranfered",
    icon: <TransferWithinAStation />,
  },
  {
    text: "Patients-Received",
    icon: <Transcribe />,
  },
  {
    text: "Appointments",
    icon: <Event />,
  },
  {
    text: "Subscriptions",
    icon: <Subscriptions />,
    
  },
  {
    text:"Prescription",
    icon:<NoteOutlined/>
  },
  // {
  //   text: "Transactions",
  //   icon: <ReceiptLongOutlined />,
  // },
  // {
  //   text: "Geography",
  //   icon: <PublicOutlined />,
  // },
  // {
  //   text: "Report",
  //   icon: <PublicOutlined />,
  // },
  {
    text: "Growth",
    icon: null,
  },
  // {
  //   text: "Overview",
  //   icon: <PointOfSaleOutlined />,
  // },
 
  {
    text: "Monthly",
    icon: <CalendarMonthOutlined />,
  },
  {
    text: "Breakdown",
    icon: <PieChartOutlined />,
  },
 
  {
    text: "Management",
    icon: null,
  },
  {
    text: "Account",
    icon: <AccountCircle />,
  },
  
  {
    text:"Team",
    icon:<Groups/>
  }
  // {
  //   text: "Performance",
  //   icon: <TrendingUpOutlined />,
  // },
];

const Sidebar = ({
  user,
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
  team,
  userData
}) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  const [shouldSkip, setShouldSkip] = useState(false)
  const tierSubscription = useGetActiveSubscriptionQuery(userData && userData[0]._id, {skip: !shouldSkip});
  useEffect(()=>{
    if (tierSubscription.data && tierSubscription.data.data && tierSubscription.data.data.length > 0 && tierSubscription.data.data[0]){
      const tier = tierSubscription.data.data[0].tier
      console.log(tier)
      if (navItems.filter((i)=>i.text === "Exercises").length === 0) {
        navItems.push({
          text:"Exercises",
          icon:<DirectionsWalk/>
        })
      }
    }
  },[tierSubscription.data])
  useEffect(() => {
   
    if(!team){
      if(userData && userData[0].role === "Doctor" || userData && userData[0].role === "Physio") {
        if(navItems.filter((i)=>i.text === "Billing").length === 0){
          navItems.push({
            text:"Billing",
            icon:<Receipt/>
          })
        }
      }
    } else {
      if(team.role === "admin") {
        if(navItems.filter((i)=>i.text === "Billing").length === 0){
          navItems.push({
            text:"Billing",
            icon:<Receipt/>
          })
        }
      }
    }
    setActive(pathname.substring(1));
    setShouldSkip(true)
  }, [pathname, userData]);

  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: theme.palette.secondary[200],
              backgroundColor: theme.palette.background.alt,
              boxSixing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
              width: drawerWidth,
            },
          }}
        >
          <Box width="100%">
            <Box m="1.5rem 2.5rem 1rem 3rem"  >
              <Box color={theme.palette.secondary.main} className="flex xxs:flex-col sm:flex-row justify-center items-center"  >
                <Box className="flex flex-col items-center justify-center" alignItems="center" gap="0.5rem">
                  {/* <img style={{scale: "50%"}} src={profileImage} /> */}
                  <Typography variant="h4" fontWeight="bold" sx={{color: theme.palette.secondary[200] }} className="text-center">
                    {user.name}
                <div className="flex justify-center items-center py-2">
               {user.photoUrl && <Box 
                  component="img"
                  alt="profile"
                  src={user.photoUrl }
                  borderRadius='100%'
                  sx={{ objectFit: "cover", width: '80px', height: '80px',  }}
                />}
                 {team && team.photo && <Box 
                  component="img"
                  alt="profile"
                  src={team.photo }
                  borderRadius='100%'
                  sx={{ objectFit: "cover", width: '80px', height: '80px',  }}
                />}
                </div>
                  </Typography>
                  <Typography variant="h8" fontWeight="bold" sx={{color: theme.palette.secondary[100] }}>
                    {/* {userData.address} */}
                  </Typography>
                </Box>

                
                {!isNonMobile ?
                  <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    <ChevronLeft className="border border-2-bluegray rounded-full" />
                  </IconButton>
                  :<></>
                }
              </Box>
            </Box>
            <List>
              {navItems.map(({ text, icon }) => {
                if (!icon) {
                  return (
                    <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
                      {text}
                    </Typography>
                  );
                }
                const lcText = text.toLowerCase();

                return (
                  <ListItem key={text} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        if (!isNonMobile && isSidebarOpen) {
                          setIsSidebarOpen(false)
                        }
                        navigate(`/${lcText}`);
                        setActive(lcText);
                        
                      }}
                      sx={{
                        backgroundColor:
                          active === lcText
                            ? theme.palette.secondary[300]
                            : "transparent",
                        color:
                          active === lcText
                            ? theme.palette.primary[600]
                            : theme.palette.secondary[100],
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          ml: "2rem",
                          color:
                            active === lcText
                              ? theme.palette.primary[600]
                              : theme.palette.secondary[200],
                        }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText primary={text} />
                      {active === lcText && (
                        <ChevronRightOutlined sx={{ ml: "auto" }} />
                      )}
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
            
          </Box>

          <Box position="absolute" bottom="1.5rem" className={`${!isNonMobile ? 'hidden' : 'visible'}`} >
            
            <Divider/>
            {/* <FlexBetween textTransform="none" gap="1rem" m="2.5rem 2rem 0 3rem">
              <Box
                component="img"
                alt="profile"
                src={user.photoUrl}
                height="40px"
                width="40px"
                borderRadius="50%"
                sx={{ objectFit: "cover" }}
              />
              <Box textAlign="left">
                <Typography
                  fontWeight="bold"
                  fontSize="0.9rem"
                  sx={{ color: theme.palette.secondary[100] }}
                >
                  {user.name}
                </Typography>
                <Typography
                  fontSize="0.8rem"
                  sx={{ color: theme.palette.secondary[200] }}
                >
                  {user.rolekey || ''}
                </Typography>
              </Box>
              <SettingsOutlined
                sx={{
                  color: theme.palette.secondary[300],
                  fontSize: "25px ",
                }}
              />
            </FlexBetween> */}
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;
